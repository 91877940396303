import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import {
  setActiveSCProfileNavLink,
  fetchShifts,
} from "../../../redux/actions/schedulingActions";

import "../../../assets/css/Shifts.scss";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import constants from "../../../helpers/en";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";

function Shifts() {
  const dispatch = useDispatch();

  // Redux state selectors
  const shifts = useSelector((state) => state.shifts.shifts);
  const isDataLoading = useSelector((state) => state.shifts.loading);
  const selectedClient = useSelector((state) => state.selection.selectedClient);
  const facilities = useSelector((state) => state.facilityList.data);
  // Local state
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);

  // Fetch initial data
  useEffect(() => {
    dispatch(setActiveSCProfileNavLink("shifts"));
    const confirmationNumber = sessionStorage.getItem("confirmationNumber");
    if (confirmationNumber) {
      dispatch(fetchShifts(constants.LIMIT, 0, confirmationNumber));
    }
  }, [dispatch, selectedClient]);

  // Initialize filters
  const initializeFilters = useCallback(() => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      bookingStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      practitionerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
      shiftStartDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      shiftEndDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      shiftTimes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      shiftType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityCity: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityState: { value: null, matchMode: FilterMatchMode.CONTAINS },
      consultantName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters, facilities]);

  // Handlers
  const handleSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const handleFilterChange = (e) => {
    setFilters(e.filters);
  };

  const handlePageChange = (e) => {
    setFirst(e.first);
  };
  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };
  // Render header (if required)
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings">Shifts</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="sc-shifts-data flex-grow-1 overflow-auto ">
      <DataTable
        className="table-responsive px-3"
        value={shifts}
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
        onPage={handlePageChange}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-shifts"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="ConfirmationNumber_Class"
          header="Confirmation Number"
          field="confirmationNumber"
          filter
          body={(rowData) => (
            <span className="confirmationNumber-text">
              {rowData.confirmationNumber ? rowData.confirmationNumber : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Booking_Class"
          header="Booking Status"
          field="bookingStatus"
          filter
          body={(rowData) => (
            <span className="bookingStatus-text">
              {rowData.bookingStatus ? rowData.bookingStatus : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Firstname_Class"
          header="Provider"
          field="firstName"
          filter
          body={(rowData) => (
            <span className="firstName-text">
              {rowData.firstName ? rowData.firstName : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Facilityname_Class"
          header="Client Name"
          field="clientName"
          filter
          body={(rowData) => (
            <span className="clientName-text">
              {rowData.clientName ? rowData.clientName : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Facility Name"
          field="facilityName"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Practitioner Type"
          field="practitionerType"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.practitionerType ? rowData.practitionerType : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Specialty"
          field="specialty"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.specialty ? rowData.specialty : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Shift Start Date"
          field="shiftStartDate"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.shiftStartDate ? rowData.shiftStartDate : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Shift End Date"
          field="shiftEndDate"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.shiftEndDate ? rowData.shiftEndDate : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Worksite_Class"
          header="Shift Times"
          field="shiftTimes"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.shiftTimes ? rowData.shiftTimes : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="shiftTypes_Class"
          header="Shift Types"
          field="shiftType"
          filter
          body={(rowData) => (
            <span className="shiftType-text">
              {rowData.shiftType ? rowData.shiftType : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Facility_Class"
          header="Facility City"
          field="facilityCity"
          filter
          body={(rowData) => (
            <span className="facilityCity-text">
              {rowData.facilityCity ? rowData.facilityCity : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="facilityState_Class"
          header="Facility State"
          field="facilityState"
          filter
          body={(rowData) => (
            <span className="facilityState-text">
              {rowData.facilityState ? rowData.facilityState : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Consultant_Class"
          header="Consultant Name"
          field="consultantName"
          filter
          body={(rowData) => (
            <span className="consultantName-text">
              {rowData.consultantName ? rowData.consultantName : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}

export default Shifts;
