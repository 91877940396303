//Add constant attributes like messages
const constants = {
  FACILITY: "Facility",
  DASHBOARD: "Dashboards",
  SCHEDULING: "Scheduling",
  SPEND_MANAGEMENT: "Spend Management",
  MY_PROFILE: "My Profile",
  SETTING: "Settings",
  LOGOUT: "Logout",
  ADD_FACILITY: "ADD FACILITY",
  ADD_FACILITY_BUTTON: "Add Facility",
  ADD_FACILITY_USERS: "Add facility users",
  SUCCESSFULLY: "User added successfully",
  ACTION_INVALID: "This action is invalid!",
  USERNAME: "Username",
  LOGIN_TITLE: "WELCOME TO CLIENT PORTAL",
  LOGIN_BUTTON_CONTINUE: "Login to client portal?",
  AGREE_TERMS_TEXT: "By Signing in, you agree to our",
  TERMS_SERVICE: "Terms of Service",
  TERMS_OF_POLICY: "Privacy Policy",
  BUTTON_CONTINUE: "Continue",
  AND: "and",
  EMAIL_PLACEHOLDER: "Email",
  SELECT_HOSPITAL: "Select Client",
  SELECT_WPRKSITE: "Select Facility",
  ORDER_BY: "Order By",
  BUTTON_SUBMIT: "Submit",
  SELECT_FACILITY: "Select Facility",
  FIELD_REQUIRED: "This field is required",
  REPORTS: "REPORTS",
  CONDIDATES: "Candidates",
  LOCUMS_ANALYTICS: "Locums Analytics",
  LANDING_PAGE: "Landing Page",
  SCHEDULING_REPORTS: "Scheduled Reports",
  ADD_NEW_FACILITY: "ADD NEW FACILITY",
  BASIC_FACILITY_INFORMATION: "1.Basic Facility Information",
  FACILITY_NAME: "Facility Name",
  FACILITY_SUCCESSFULLY_MESSAGE: "Facility added successfully!",
  FACILITY_ALREADY_EXISTS: "Facility already exists",
  UPDATE_FACILITY: "EDIT FACILITY",
  UPDATE_FACILITY_SUCCESSFULLY_MESSAGE: "Facility updated successfully!",
  ACCOUNT_NAME: "Account Name",
  SELECT_ACCOUNT_NAME: "Select account name",
  FACILITY_INFORMATION: "1. Facility Information",
  ACCOUNT_STATUS: "Account Status",
  DEFINITIVE_ID: "Definitive ID",
  ACCOUNT_NUMBER: "Account Number",
  WEBSITE: "Website",
  TYPE: "Type",
  TABLEAU_SUBSCRIPTION: "Tableau Subscription",
  FACILITY_ADMIN: "2. Facility Admin",
  SELECT_ADMIN: "Select Admin",
  FULL_NAME: "Full Name",
  TITLE: "Title",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "Email",
  BUTTTON_RESET: "Reset",
  ACCOUNT_NAME_ERROR_MESSAGE: "This field is required",
  ADMIN_NAME_ERROR_MESSAGE: "This field is required",
  EDIT_FACILITY: "EDIT FACILITY",
  ADDRESS: "Address",
  FACILITY_DETAILS: "FACILITY DETAILS",
  FACILITY_USERS: "FACILITY USERS",

  FOOTER_COPYRIGHT: "© 2024 Copyright : Hayes Locums",
  LIMIT: 10000,
  ERROR_401_MESSAGE: "You are not authorized to perform this action.",
  ERROR_403_MESSAGE: "Access to this resource is forbidden.",
  ERROR_404_MESSAGE: "The requested resource could not be found.",
  ERROR_503_MESSAGE:
    "The service is temporarily unavailable. Please try again later.",
  ERROR_500_MESSAGE: "Facility already exist !",
  NO_RECORDS_FOUND: "No records available",
  REPORT_SUBMITTED_SUCCESSFULLY: "Reports submitted successfully",
  REPORT_SUBMISSION_FAILED: "Report submission failed",
  CLIENT_FIELD_IS_REQUIRED: "Client field is required !",
  REPORT_UPDATED_SUCCESSFULLY: "Report updated successfully",
  REPORT_UPDATION_FAILED: "Report updation failed",
  HOME: "Home",
  EMPTY_ERROR_MESSAGE: "This field is required",
  PDF_EXTENSSION: "PDF",
  DOCX_EXTENSSION: "DOCX",
  PNG_EXTENSION: "png",
  JPG_EXTENSION: "jpg",
  XLSX_EXTENSION: "xlsx",
  ADD_FACILITY_USER: "ADD FACILITY USER",
  FACILITY_USER: "FACILITY USER",
  EDIT_FACILITY_USER: "EDIT FACILITY USER",
  PROFILE: "Profile",
  TIME_ENTRIES: "Time Entries",
  DOCUMENTS: "Documents",
  CALENDAR: "Calendar",
  TIMESHEET: "Timesheets",
  INVOICE: "Invoices",
  SHIFT: "Shifts",
  INACTIVE_MESSAGE: "Are you sure you want to inactivate this user?",
  ACTIVE_MESSAGE: "Are you sure you want to activate this user?",
  CONFIRMATION_TITLE: "Confirmation",
  WELCOME: "Welcome",
  EXPENSES: "Expenses",
  VIEW: "View",
  LINK: "Link",
  NO_FILE_AVAILABLE: "No file available",
  NO_DATA_AVAILABLE_MESSAGE: "No data available",
  NO_RECORD_FOUND_MESSAGE: "No records found",
};
export default constants;
