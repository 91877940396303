import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useDispatch, useSelector } from "react-redux";
import { getSFWorksiteList } from "../../../redux/actions/facilityActions";
import { COLUMNS } from "../../../helpers/enums";
import constants from "../../../helpers/en";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import "../../../assets/css/WorksiteList.scss";
import { ProgressSpinner } from "primereact/progressspinner";

export default function Worksite() {
  const [globalFilter, setGlobalFilter] = useState("");
  const location = useLocation();
  const { accountNo, parentName } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { worksiteData, loading, error } = useSelector(
    (state) => state.worksite
  );
  const worksiteCount = worksiteData?.count || 0;

  const worksiteSFList = (data) => {
    if (Array.isArray(data)) {
      return data.map((worksite) => ({
        key: worksite?.accountNumber,
        data: {
          parent_name: parentName,
          select_account_num: worksite?.accountNumber,
          account_name: worksite?.name,
          account_number: worksite?.accountNumber,
          account_status: worksite?.accountStatus,
          website: worksite?.website,
          address: `${worksite?.address?.city}, ${worksite?.address?.state}`,
          type: worksite?.type,
        },
        children: worksite?.childWorksite
          ? worksiteSFList(worksite?.childWorksite)
          : [],
      }));
    }
    return [];
  };

  useEffect(() => {
    if (accountNo) {
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    }
  }, [dispatch, accountNo]);

  const nodes =
    worksiteData?.result && worksiteData.result.length > 0
      ? worksiteSFList(worksiteData.result)
      : [];

  const rowClassName = (rowData) => {
    const flattenNodes = (nodes) => {
      let result = [];
      nodes.forEach((node) => {
        result.push(node);
        if (node.children) {
          result = result.concat(flattenNodes(node.children));
        }
      });
      return result;
    };

    const allRows = flattenNodes(nodes);
    const index = allRows.indexOf(rowData);

    // Refactor the nested ternary
    if (rowData.parent && rowData.parent.length > 0) {
      return "child-row";
    }
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const handleNavigate = () => navigate("dashboard");

  const getHeader = () => {
    return (
      <div className="page-header">
        <div>
          <h2 className="page-heading">
            <i
              className="pi pi-chevron-left px-2"
              role="button"
              tabIndex={0}
              onClick={handleNavigate}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") handleNavigate();
              }}
              style={{ cursor: "pointer", fontSize: "1.3rem" }}
            ></i>
            Worksite Information ({worksiteCount})
          </h2>
        </div>
        <IconField iconPosition="left" style={{ cursor: "pointer" }}>
          <InputIcon className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder=" Search"
          />
        </IconField>
      </div>
    );
  };

  const header = getHeader();

  return (
    <div className="Outerbody-card">
      <div className="card">
        {loading ? (
          <div className="loading-container">
            <div className="loader">
              <ProgressSpinner />
            </div>
          </div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <TreeTable
            className="table-responsive"
            value={nodes.length > 0 ? nodes : []}
            globalFilter={globalFilter}
            rowClassName={rowClassName}
            header={header}
            tableStyle={{
              minWidth: "50rem",
              borderRadius: "10px",
              overflow: "auto",
            }}
            paginator
            rows={20}
            emptyMessage={
              <span
                className="custom-empty-message"
                style={{ fontSize: "18px", marginLeft: "47%" }}
              >
                No data available
              </span>
            }
          >
            {Object.values(COLUMNS).map((col, index) => (
              <Column
                key={index}
                field={col.field}
                header={
                  col.field === "parent_name" ? (
                    <span style={{ marginLeft: "2rem" }}>{col.header}</span>
                  ) : (
                    col.header
                  )
                }
                expander={col.expander}
                sortable={col.sortable}
                style={col.style}
                headerClassName={col.headerClassName}
                headerStyle={col.headerStyle}
              />
            ))}
          </TreeTable>
        )}
      </div>
    </div>
  );
}
