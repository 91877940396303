import React from "react";
import PropTypes from "prop-types"; // Import prop-types
import { FaPlus } from "react-icons/fa";
import "../../assets/css/AddButton.scss";

const AddButton = (props) => {
  return (
    <button className="add-btn" onClick={props.onClick}>
      <i>
        <FaPlus />
      </i>
      {props.name}
    </button>
  );
};

// Define prop types
AddButton.propTypes = {
  onClick: PropTypes.func.isRequired, // onClick is a required function
  name: PropTypes.string, // name is an optional string
};

export default AddButton;
