import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as ReportDelete } from "../../assets/images/icons/AddReportDelete.svg";
import CustomTooltip from "./CustomTooltip";

const ClearRowsCell = ({ onClearRows }) => {
  return (
    <CustomTooltip content="Clear Rows">
      <ReportDelete className="addreport-icons" onClick={onClearRows} />
    </CustomTooltip>
  );
};

// Add PropTypes validation
ClearRowsCell.propTypes = {
  onClearRows: PropTypes.func.isRequired, // Function to clear rows
};

export default ClearRowsCell;
