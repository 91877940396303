import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { setActiveSCProfileNavLink } from "../../../redux/actions/schedulingActions";

import "../../../assets/css/ScTimesheet.scss";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import constants from "../../../helpers/en";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { getSpendManagement } from "../../../redux/actions/spendManagementActions";

function Timesheet() {
  const dispatch = useDispatch();

  // Redux state selectors
  const { spendManagementTimesheet, loading: isDataLoading } = useSelector(
    (state) => state.spendManagement
  );

  const selectedClient = useSelector((state) => state.selection.selectedClient);
  const facilities = useSelector((state) => state.facilityList.data);

  // Local state
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);

  // Fetch initial data
  useEffect(() => {
    dispatch(setActiveSCProfileNavLink("timesheet"));
    const confirmationNumber = sessionStorage.getItem("confirmationNumber");
    if (confirmationNumber) {
      dispatch(
        getSpendManagement(constants.LIMIT, 0, "", "", "", confirmationNumber)
      );
    }
  }, [dispatch, selectedClient]);

  // Initialize filters
  const initializeFilters = useCallback(() => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      timesheetName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalFile: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalDueDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rejectionNotes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      timesheetURL: { value: null, matchMode: FilterMatchMode.CONTAINS },
      providerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientInvoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientInvoicePeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
      payrollContact: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters, facilities]);

  // Handlers
  const handleSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const handleFilterChange = (e) => {
    setFilters(e.filters);
  };

  const handlePageChange = (e) => {
    setFirst(e.first);
  };
  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };
  // Render header (if required)
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings">Timesheet</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="sc-ScTimesheet-data flex-grow-1 overflow-auto">
      <DataTable
        className="table-responsive px-3"
        value={
          Array.isArray(spendManagementTimesheet)
            ? spendManagementTimesheet
            : []
        }
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
        onPage={handlePageChange}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-shifts"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="Booking_Class"
          header="Timesheet Name"
          field="timesheetName"
          body={(rowData) => (
            <span className="timesheetName-text">
              {rowData.timesheetName ? rowData.timesheetName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Firstname_Class"
          header="Approval File"
          field="approvalFile"
          body={(rowData) => (
            <span className="approvalFile-text">
              {rowData.approvalFile ? rowData.approvalFile : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Firstname_Class"
          header="Approval Due Date"
          field="approvalDueDate"
          body={(rowData) => (
            <span className="approvalFile-text">
              {rowData.approvalDueDate ? rowData.approvalDueDate : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Firstname_Class"
          header="Approval Status"
          field="approvalStatus"
          body={(rowData) => (
            <span className="approvalStatus-text">
              {rowData.approvalStatus ? rowData.approvalStatus : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />

        <Column
          className="Facilityname_Class"
          header="Rejection Notes"
          field="rejectionNotes"
          body={(rowData) => (
            <span className="rejectionNotes-text">
              {rowData.rejectionNotes ? rowData.rejectionNotes : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Url_Class"
          headerClassName="Url_Class"
          header="Timesheet URL"
          field="timesheetURL"
          body={(rowData) =>
            rowData.timesheetURL ? (
              <a
                href={rowData.timesheetURL}
                target="blank"
                rel="noopener noreferrer"
                className="timesheet-url-link"
              >
                Link
              </a>
            ) : (
              <div className="timesheet-url-link" style={{ width: "10rem" }}>
                -
              </div>
            )
          }
        />
        <Column
          className="Speciality_Class"
          header="Provider Name"
          field="providerName"
          body={(rowData) => (
            <span className="providerName-text">
              {rowData.providerName ? rowData.providerName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />

        <Column
          className="shiftStartDate_Class"
          header="Facility Name"
          field="facilityName"
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="shiftEndDate_Class"
          header="Speciality"
          field="specialty"
          body={(rowData) => (
            <span className="specialty-text">
              {rowData.specialty ? rowData.specialty : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="shiftTimes_Class"
          header="Client Invoice"
          body={(rowData) => (
            <span className="clientInvoice-text">
              {rowData.clientInvoice ? rowData.clientInvoice : "-"}
            </span>
          )}
          field="clientInvoice"
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="shiftTypes_Class"
          header="Pay Period"
          field="clientInvoicePeriod"
          body={(rowData) => (
            <span className="clientInvoicePeriod-text">
              {rowData.clientInvoicePeriod ? rowData.clientInvoicePeriod : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Facility_Class"
          header="Payroll Contact"
          field="payrollContact"
          body={(rowData) => (
            <span className="payrollContact-text">
              {rowData.payrollContact ? rowData.payrollContact : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}

export default Timesheet;
