import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPES } from "../../../helpers/enums";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import "../../../assets/css/SpendMtgInvoice.scss";
import { ReactComponent as Download } from "../../../assets/images/icons/Download.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/Print.svg";
import { useLocation, useNavigate } from "react-router";
import constants from "../../../helpers/en";
import { Dropdown } from "primereact/dropdown";
import {
  getFacilityDBList,
  getSFWorksiteList,
} from "../../../redux/actions/facilityActions";
import {
  setSelectedClient,
  setSelectedClientId,
  setSelectedFacility,
  setSelectedFacilityId,
} from "../../../redux/actions/dashboardListActions";
import { getSpendMtgInvoice } from "../../../redux/actions/spendMtgInvoiceAction";
import DropdownSpinner from "../../../components/Commons/DrpodownSpinner";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { exportToExcel } from "../../../helpers/fileUtills";

function SpendMtgInvoicesMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const segment = location.pathname.split("/").pop();
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );

  const { data, loading } = useSelector((state) => state.facilityList);
  const { worksiteData, loading: worksiteLoading } = useSelector(
    (state) => state.worksite
  );
  const selectedClient = useSelector((state) => state.selection.selectedClient);
  const selectedFacility = useSelector(
    (state) => state.selection.selectedFacility
  );
  const { spendMtgInvoiceTimesheet, loading: isDataLoading } = useSelector(
    (state) => state.spendMtgInvoice
  );

  const [first, setFirst] = useState(0);
  const [spendMtgInvoiceData, setSpendMtgInvoiceData] = useState([]);

  const spendInvoiceList = Array.isArray(spendMtgInvoiceData)
    ? spendMtgInvoiceData
    : [];
  const spendInvoiceData = spendInvoiceList.map((item, index) => [
    index + 1,
    item?.provider,
    item?.invoiceDate,
    item?.facilityName,
    item?.clientName,
    item?.invoiceNumber,
    item?.status,
    item?.paymentTerms,
    item?.amount,
    item?.ammountRecivableSpecialist,
    item?.confirmationNumber,
    item?.specialty,
  ]);
  const spendMtgInvoiceHeaders = [
    "S.No",
    "Provider",
    "Invoice Date",
    "Facility Name",
    "Client Name",
    "Invoice Number",
    "Status",
    "Payment Terms",
    "Amount",
    "Amount Receivalble Specialist",
    "Confirmation Number",
    "Specialty",
  ];
  const fileName = "SpendManagement" + "-" + "Invoices";
  const downLoadExcelFormat = () => {
    if (segment === "spend-mtg-invoices") {
      if (spendInvoiceData && spendInvoiceData.length > 0) {
        exportToExcel(spendInvoiceData, spendMtgInvoiceHeaders, fileName);
      }
    }
  };

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
      provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      paymentTerms: { value: null, matchMode: FilterMatchMode.CONTAINS },
      amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ammountRecivableSpecialist: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }));
  }, []);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    provider: { value: null, matchMode: FilterMatchMode.CONTAINS },
    invoiceDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    invoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    paymentTerms: { value: null, matchMode: FilterMatchMode.CONTAINS },
    amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ammountRecivableSpecialist: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Fetch initial data

  useEffect(() => {
    if (
      rolePermission.role === USER_TYPES.FACILITY_ADMIN ||
      rolePermission.role === USER_TYPES.FACILITY_USER
    ) {
      const facilityAccountNo = rolePermission.userInfo?.facility_account_no;

      if (facilityAccountNo) {
        dispatch(getSFWorksiteList("", facilityAccountNo, 0, constants.LIMIT));
      }
    } else if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      const accountNo = selectedClient?.facility_account_no_fd;
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    } else {
      dispatch(getFacilityDBList());
      if (selectedClient) {
        const accountNo = selectedClient?.facility_account_no_fd;
        dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
      }
    }
  }, [dispatch, selectedClient, rolePermission.role]);

  useEffect(() => {
    const accountNo = selectedClient?.facility_account_no_fd;
    if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      if (!accountNo) {
        setSpendMtgInvoiceData([]);
      } else {
        if (spendMtgInvoiceTimesheet) {
          setSpendMtgInvoiceData(spendMtgInvoiceTimesheet);
        } else {
          setSpendMtgInvoiceData([]);
        }
      }
    } else {
      // For all other roles, apply the alternative condition
      if (spendMtgInvoiceTimesheet) {
        setSpendMtgInvoiceData(spendMtgInvoiceTimesheet);
      } else {
        setSpendMtgInvoiceData([]);
      }
    }
  }, [spendMtgInvoiceTimesheet, selectedClient, rolePermission.role]);

  useEffect(() => {
    const accountNo = selectedClient?.facility_account_no_fd;
    const facilityAccountNo = rolePermission.userInfo?.facility_account_no;
    const facilityAccountNumber = selectedFacility?.accountNumber;

    // Logic for FACILITY_ADMIN and FACILITY_USER is the same
    if (
      [USER_TYPES.FACILITY_ADMIN, USER_TYPES.FACILITY_USER].includes(
        rolePermission.role
      )
    ) {
      if (facilityAccountNo && !facilityAccountNumber) {
        dispatch(getSpendMtgInvoice(constants.LIMIT, 0, facilityAccountNo));
      }

      if (facilityAccountNo && facilityAccountNumber) {
        dispatch(
          getSpendMtgInvoice(
            constants.LIMIT,
            0,

            facilityAccountNo,
            facilityAccountNumber
          )
        );
      }
    } else if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      // SUPER_ADMIN or default role logic
      if (accountNo && !facilityAccountNumber) {
        dispatch(getSpendMtgInvoice(constants.LIMIT, 0, accountNo));
      }

      if (accountNo && facilityAccountNumber) {
        dispatch(
          getSpendMtgInvoice(
            constants.LIMIT,
            0,
            accountNo,
            facilityAccountNumber
          )
        );
      }

      if (!accountNo) {
        dispatch(getSpendMtgInvoice());
        setSpendMtgInvoiceData([]); // Reset data if no account number
      }
    }
  }, [dispatch, selectedClient, selectedFacility, rolePermission.role]);

  const onClientChange = (e) => {
    const selectedClient = e.value;
    dispatch(setSelectedClient(selectedClient));
    dispatch(setSelectedClientId(selectedClient?.facility_account_no_fd));
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const onFacilityChange = (e) => {
    const selectedFacility = e.value;
    dispatch(setSelectedFacility(selectedFacility));
    dispatch(setSelectedFacilityId(selectedFacility?.accountNumber));
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const onPage = (event) => {
    setFirst(event.first);
  };

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings">Invoices</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          {" "}
          <div className="sp-switches">
            <CustomTooltip content={"Print"}>
              <Print className="sp-icons" style={{ marginRight: "1.5rem" }} />
            </CustomTooltip>

            <CustomTooltip content={"Download"}>
              <Download
                onClick={downLoadExcelFormat}
                className="sp-icons"
                style={{ marginRight: "1.5rem" }}
              />
            </CustomTooltip>
          </div>
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              value={filters.global.value}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const handleClick = (id, confirmationNumber, invoiceNo, invoiceDate) => {
    if (id && confirmationNumber) {
      sessionStorage.setItem("confirmationNumber", confirmationNumber);
      sessionStorage.setItem("Id", id);
      sessionStorage.setItem("invoiceNo", invoiceNo);
      sessionStorage.setItem("invoiceDate", invoiceDate);
    } else {
      console.error("Error: Missing id or confirmationNumber");
    }
    navigate("spend-invoice-profile");
  };

  return (
    <div className="sc-invoice-container flex-grow-1 overflow-auto">
      {rolePermission.role === USER_TYPES.SUPER_ADMIN && (
        <div className="py-2 d-flex dashboard-pages-head-div w-100 row g-0">
          <div className="d-flex align-items-center dp-heading-input col-md-3">
            <label htmlFor="select-client" className="bold-barlow mx-1">
              {constants.SELECT_HOSPITAL}
            </label>
            <Dropdown
              className="mx-1"
              id="select-client"
              value={selectedClient}
              options={loading ? [] : data}
              onChange={onClientChange}
              placeholder="Select Client"
              optionLabel="account_name_fd"
              filter
              showClear
              loading={loading}
              emptyFilterMessage="No data available"
              filterBy="account_name_fd"
              emptyMessage={loading ? <DropdownSpinner /> : "No data available"}
            />
          </div>
          <div className="d-flex align-items-center dp-heading-input col-md-3">
            <label htmlFor="select-facility" className="bold-barlow mx-1">
              {constants.SELECT_WPRKSITE}
            </label>
            <Dropdown
              className="mx-1"
              id="select-facility"
              value={selectedFacility}
              options={worksiteLoading ? [] : worksiteData?.result || []}
              onChange={onFacilityChange}
              placeholder="Select Facility"
              optionLabel="name"
              filter
              filterBy="name"
              loading={worksiteLoading}
              showClear
              emptyFilterMessage="No data available"
              emptyMessage={
                worksiteLoading ? <DropdownSpinner /> : "No data available"
              }
            />
          </div>
        </div>
      )}

      {(rolePermission.role === USER_TYPES.FACILITY_ADMIN ||
        rolePermission.role === USER_TYPES.FACILITY_USER) && (
        <div className="py-2 d-flex justify-content-between dashboard-pages-head-div w-100 row g-0">
          {rolePermission.role === USER_TYPES.FACILITY_ADMIN && (
            <div className="d-flex align-items-center dp-heading-input col-md-3">
              <label htmlFor="select-facility" className="bold-barlow mx-1">
                {constants.SELECT_WPRKSITE}
              </label>
              <Dropdown
                className="mx-1"
                id="select-facility"
                value={selectedFacility}
                options={worksiteLoading ? [] : worksiteData?.result || []}
                onChange={onFacilityChange}
                placeholder="Select Facility"
                optionLabel="name"
                filter
                filterBy="name"
                loading={worksiteLoading}
                showClear
                emptyFilterMessage="No data available"
                emptyMessage={
                  worksiteLoading ? <DropdownSpinner /> : "No data available"
                }
              />
            </div>
          )}
        </div>
      )}

      <DataTable
        className="table-responsive px-3"
        value={Array.isArray(spendMtgInvoiceData) ? spendMtgInvoiceData : []}
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        onPage={onPage}
        onFilter={(e) => setFilters(e.filters)}
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-demo-local"
        emptyMessage={
          <div className="d-flex justify-content-center ">
            No data available.
          </div>
        }
      >
        <Column
          className="provider_class"
          header="Provider"
          field="provider"
          body={(rowData) => (
            <span className="provider-text">
              {rowData.provider ? rowData.provider : "-"}
            </span>
          )}
          filter
          filterField="provider"
          filterPlaceholder="Search"
        />
        <Column
          className="invoice_class"
          header="Invoice Date"
          field="invoiceDate"
          body={(rowData) => (
            <span className="invoiceDate-text">
              {rowData.invoiceDate ? rowData.invoiceDate : "-"}
            </span>
          )}
          filter
          filterField="invoiceDate"
          filterPlaceholder="Search"
        />
        <Column
          className="facility_class"
          header="Facility Name"
          field="facilityName"
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filter
          filterField="facilityName"
          filterPlaceholder="Search"
        />
        <Column
          className="client_class"
          header="Client Name"
          field="clientName"
          body={(rowData) => (
            <span className="clientName-text">
              {rowData.clientName ? rowData.clientName : "-"}
            </span>
          )}
          filter
          filterField="clientName"
          filterPlaceholder="Search"
        />
        <Column
          className="invoiceNum_clss"
          header="Invoice Number"
          field="invoiceNumber"
          body={(rowData) => (
            <a
              className="invoiceNumber-text"
              role="button"
              tabIndex={0}
              onClick={() =>
                handleClick(
                  rowData?.Id,
                  rowData?.confirmationNumber,
                  rowData?.invoiceNumber,
                  rowData?.invoiceDate
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault(); // Prevent scrolling when pressing the space key
                  handleClick(
                    rowData?.Id,
                    rowData?.confirmationNumber,
                    rowData?.invoiceNumber,
                    rowData?.invoiceDate
                  );
                }
              }}
            >
              {rowData.invoiceNumber}
            </a>
          )}
          filter
          filterField="invoiceNumber"
          filterPlaceholder="Search"
        />
        <Column
          className="status_class"
          header="Status"
          field="status"
          body={(rowData) => (
            <span className="status-text">
              {rowData.status ? rowData.status : "-"}
            </span>
          )}
          filter
          filterField="status"
          filterPlaceholder="Search"
        />
        <Column
          className="payment_class"
          header="Payment Terms"
          field="paymentTerms"
          body={(rowData) => (
            <span className="paymentTerms-text">
              {rowData.paymentTerms ? rowData.paymentTerms : "-"}
            </span>
          )}
          filter
          filterField="paymentTerms"
          filterPlaceholder="Search"
        />
        <Column
          className="amount_class"
          header="Amount"
          field="amount"
          body={(rowData) => (
            <span className="amount-text">
              {rowData.amount ? rowData.amount : "-"}
            </span>
          )}
          filter
          filterField="amount"
          filterPlaceholder="Search"
        />
        <Column
          className="accountReceived_class"
          header="Amount Receivalble Specialist"
          field="ammountRecivableSpecialist"
          body={(rowData) => (
            <span className="ammountRecivableSpecialist-text">
              {rowData.ammountRecivableSpecialist
                ? rowData.ammountRecivableSpecialist
                : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Confirmation_class"
          header="Confirmation Number"
          field="confirmationNumber"
          body={(rowData) => (
            <span className="confirmationNumber-text">
              {rowData.confirmationNumber ? rowData.confirmationNumber : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Specialty_class"
          header="Specialty"
          field="specialty"
          body={(rowData) => (
            <span className="specialty-text">
              {rowData.specialty ? rowData.specialty : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}
export default SpendMtgInvoicesMain;
