import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "./../../assets/css/CustomTooltip.scss";
import React from "react";
import PropTypes from "prop-types";

const CustomTooltip = ({
  content,
  placement = "top",
  children,
  animation = "scale",
  theme = "custom",
  arrow = true,
  delay = [150, 0],
}) => {
  return (
    <Tippy
      content={content}
      placement={placement}
      animation={animation}
      theme={theme}
      arrow={arrow}
      delay={delay}
    >
      {children}
    </Tippy>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.node.isRequired, // content is required and should be of type node (string, element, or array of nodes)
  placement: PropTypes.string, // placement is a string (top, bottom, left, right, etc.)
  animation: PropTypes.string, // animation type (e.g., scale, fade)
  theme: PropTypes.string, // theme name (for custom styling)
  arrow: PropTypes.bool, // arrow is a boolean
  delay: PropTypes.arrayOf(PropTypes.number), // delay is an array of numbers
  children: PropTypes.node.isRequired, // children is required (the component wrapped with the tooltip)
};

export default CustomTooltip;
