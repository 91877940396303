import {
  SCHEDULING_CONFIRMATIONS_REQUEST,
  SCHEDULING_CONFIRMATIONS_SUCCESS,
  SCHEDULING_CONFIRMATIONS_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_SHIFT_REQUEST,
  GET_SHIFT_SUCCESS,
  GET_SHIFT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  SET_ACTIVE_SCPROFILE_NAVLINK,
  VIEW_DOCUMENT_REQUEST,
  VIEW_DOCUMENT_SUCCESS,
  VIEW_DOCUMENT_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  schedulingConfirmations: [],
  error: "",
};
const initialProfileState = {
  profile: null, // Store the profile data
  loading: false, // Loading state
  error: null, // Error message
};
const initialShiftState = {
  shifts: [], // Array to hold shift data
  loading: false, // Indicates loading state
  error: null, // Error message if request fails
};
const initialDocumentState = {
  documents: [], // Array to hold fetched documents
  loading: false, // Indicates loading state
  error: null, // Holds error message if request fails
};
const initialViewDocumentState = {
  viewdocument: null,
  loading: false,
  error: null,
};
export const schedulingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULING_CONFIRMATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SCHEDULING_CONFIRMATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        schedulingConfirmations: action.payload,
      };
    case SCHEDULING_CONFIRMATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const profileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };

    case GET_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: action.payload };

    case GET_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const shiftReducer = (state = initialShiftState, action) => {
  switch (action.type) {
    case GET_SHIFT_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SHIFT_SUCCESS:
      return { ...state, loading: false, shifts: action.payload };
    case GET_SHIFT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const documentReducer = (state = initialDocumentState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_DOCUMENT_SUCCESS:
      return { ...state, loading: false, documents: action.payload };
    case GET_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStateNav = {
  activeNavLink: "",
};

export const scProfileNavReducer = (state = initialStateNav, action) => {
  switch (action.type) {
    case SET_ACTIVE_SCPROFILE_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    default:
      return state;
  }
};
export const viewDocumentReducer = (
  state = initialViewDocumentState,
  action
) => {
  switch (action.type) {
    case VIEW_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        viewdocument: action.payload,
      };
    case VIEW_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default schedulingReducer;
