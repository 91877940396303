import React, { forwardRef, useRef } from "react";
import { Toast } from "primereact/toast";
import PropTypes from "prop-types";
const CustomToast = forwardRef(
  ({ position = "top-right", life = 3000, className = "" }, ref) => {
    const toastRef = useRef(null);

    React.useImperativeHandle(ref, () => ({
      showSuccess: (message) => {
        toastRef.current.show({
          severity: "success",
          summary: "Success",
          detail: message,
          life: 3000,
        });
      },
      showError: (message) => {
        toastRef.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: life,
        });
      },
      showInfo: (message) => {
        toastRef.current.show({
          severity: "info",
          summary: "Info",
          detail: message,
          life: life,
        });
      },
      showWarn: (message) => {
        toastRef.current.show({
          severity: "warn",
          summary: "Warning",
          detail: message,
          life: life,
        });
      },
      showSecondary: (message) => {
        toastRef.current.show({
          severity: "secondary",
          summary: "",
          detail: message,
          life: life,
        });
      },
      showContrast: (message) => {
        toastRef.current.show({
          severity: "contrast",
          summary: "",
          detail: message,
          life: life,
        });
      },
    }));

    return <Toast ref={toastRef} position={position} className={className} />;
  }
);

// Fix the propTypes case (lowercase `p`)
CustomToast.propTypes = {
  position: PropTypes.string, // Validate 'position' prop as a string
  life: PropTypes.number, // Validate 'life' prop as a number
  className: PropTypes.string, // Validate 'className' prop as a string
};

// Default Props (if needed)
CustomToast.defaultProps = {
  position: "top-right",
  life: 3000,
  className: "",
};

export default CustomToast;
