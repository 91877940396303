import React from "react";
import "../../../assets/css/SchedulingProfileNav.scss";
import { ReactComponent as BackArrow } from "../../../assets/images/icons/BackArrow.svg";
import { ReactComponent as Download } from "../../../assets/images/icons/Download.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/Print.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSCProfileNavLink } from "../../../redux/actions/schedulingActions";
import { convertKBtoMB, exportToExcel } from "../../../helpers/fileUtills.js";
import constants from "../../../helpers/en";

const SchedulingProfileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const segment = location.pathname.split("/").pop();
  const activeLink = useSelector(
    (state) => state.profilenavigation.activeNavLink
  );
  const documents = useSelector((state) => state.document.documents);
  const shifts = useSelector((state) => state.shifts.shifts);

  const schedulingInvoice = useSelector(
    (state) => state.spendMtgInvoice.spendMtgInvoiceTimesheet
  );
  const spendManagementTimesheet = useSelector(
    (state) => state.spendManagement.spendManagementTimesheet
  );

  const handleLinkClick = (link) => {
    dispatch(setActiveSCProfileNavLink(link));
  };
  const handlePrint = () => {
    window.print();
  };
  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const documentsList = Array.isArray(documents) ? documents : [];
  const documentData = documentsList.map((item, index) => [
    index + 1,
    item?.Id,
    item?.fileName,
    convertKBtoMB(item?.fileSize),
    item?.fileType,
    item?.uploadedDate,
    item?.relatedTo,
  ]);
  const documentHeaders = [
    "S.No",
    "Id",
    "File Name",
    "File Size",
    "File Type",
    "Uploaded Date",
    "Related To",
  ];
  const invoiceList = Array.isArray(schedulingInvoice) ? schedulingInvoice : [];
  const InvoiceData = invoiceList.map((item, index) => [
    index + 1,
    item?.invoiceDate,
    item?.invoiveName,
    item?.invoiceNumber,
    item?.amount,
    item?.dateCientInvoiceSent,
    item?.facilityName,
    item?.specialty,
    item?.timesheetClientInvoiceTotal,
    item?.expensesTotal,
    item?.creditMemoTotal,
    item?.invoiceTotalIncludingCreditMemo,
    item?.ammountRecivableSpecialist,
    item?.confirmationNumber,
  ]);
  const invoiceHeaders = [
    "S.No",
    "Invoice Date",
    "Client Invoice",
    "Invoice Number",
    "Amount",
    "Date-Client Invoice Sent",
    "Facility Name",
    "Speciality",
    "Timesheet Client Invoice Total",
    "Expenses Total",
    "Credit Memo Total",
    "Invoice Total-Including Credit Memos",
    "AR Specailist",
    "Confirmation Number",
  ];
  const timesheetList = Array.isArray(spendManagementTimesheet)
    ? spendManagementTimesheet
    : [];
  const timesheetData = timesheetList.map((item, index) => [
    index + 1, // Row Number
    item?.timesheetName, // File Name
    item?.approvalFile, // Uploaded Date
    item?.approvalDueDate, // File Size
    item?.approvalStatus,
    item?.rejectionNotes, // Uploaded Date
    item?.timesheetURL, // Uploaded Date
    item?.providerName, // Uploaded Date
    item?.facilityName, // Uploaded Date
    item?.specialty, // Uploaded Date
    item?.clientInvoice, // Uploaded Date
    item?.clientInvoicePeriod, // Uploaded Date
    item?.payrollContact, // Uploaded Date
  ]);
  const timesheetHeaders = [
    "S.No",
    "Timesheet Name",
    "Approval File",
    "Approval Due Date",
    "Approval Status",
    "Rejection Notes",
    "Timesheet URL",
    "Provider Name",
    "Facility Name",
    "Speciality",
    "Client Invoice",
    "Pay Period",
    "Payroll Contact",
  ];
  const shiftsList = Array.isArray(shifts) ? shifts : [];
  const shiftData = shiftsList.map((item, index) => [
    index + 1, // Row Number
    item?.confirmationNumber, // Uploaded Date
    item?.bookingStatus, // File Size
    item?.firstName,
    item?.clientName, // Uploaded Date
    item?.facilityName, // Uploaded Date
    item?.practitionerType, // Uploaded Date?
    item?.specialty, // Uploaded Date
    item?.shiftStartDate, // Uploaded Date
    item?.shiftEndDate, // Uploaded Date
    item?.shiftTimes, // Uploaded Date
    item?.shiftType, // Uploaded Date
    item?.facilityCity, // Uploaded Date
    item?.facilityState, // Uploaded Date
    item?.consultantName, // Uploaded Date
  ]);
  const shiftHeaders = [
    "S.No",
    "Confirmation Number",
    "Booking Status",
    "Provider",
    "Client Name",
    "Facility Name",
    "Practitioner Type",
    "Specialty",
    "Shift Start Date",
    "Shift End Dat",
    "Shift Times",
    "Shift Types",
    "Facility City",
    "Facility State",
    "Consultant Name",
  ];

  const fileName = "Scheduling" + "-" + capitalize(segment);
  const downLoadExcelFormat = () => {
    if (segment === "documents") {
      exportToExcel(documentData, documentHeaders, fileName);
    } else if (segment === "shifts") {
      exportToExcel(shiftData, shiftHeaders, fileName);
    } else if (segment === "timesheet") {
      exportToExcel(timesheetData, timesheetHeaders, fileName);
    } else if (segment === "invoice") {
      exportToExcel(InvoiceData, invoiceHeaders, fileName);
    }
  };

  return (
    <div className=" d-flex sp-nav-container">
      <button
        className="d-flex justify-content-center align-items-center ps-back-btn"
        onClick={() => navigate("..")}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            // Handles Enter and Space key press
            navigate("..");
          }
        }}
      >
        <BackArrow />
      </button>

      <ul>
        <Link
          to=""
          className={activeLink === "" ? "link active" : "link"}
          onClick={() => handleLinkClick("")}
        >
          {constants.PROFILE}
        </Link>
        <Link
          to="shifts"
          className={activeLink === "shifts" ? "link active" : "link"}
          onClick={() => handleLinkClick("shifts")}
        >
          {constants.SHIFT}
        </Link>
        <Link
          to="calendar"
          className={activeLink === "calendar" ? "link active" : "link"}
          onClick={() => handleLinkClick("calendar")}
        >
          {constants.CALENDAR}
        </Link>
        <Link
          to="timesheet"
          className={activeLink === "timesheet" ? "link active" : "link"}
          onClick={() => handleLinkClick("timesheet")}
        >
          {constants.TIMESHEET}
        </Link>
        <Link
          to="invoice"
          className={activeLink === "invoice" ? "link active" : "link"}
          onClick={() => handleLinkClick("invoice")}
        >
          {constants.INVOICE}
        </Link>
        <Link
          to="documents"
          className={activeLink === "documents" ? "link active" : "link"}
          onClick={() => handleLinkClick("documents")}
        >
          {constants.DOCUMENTS}
        </Link>
      </ul>
      <div className="sp-switches-container">
        <div className="sp-switches">
          <CustomTooltip content={"Print"}>
            <Print onClick={handlePrint} className="sp-icons" />
          </CustomTooltip>
          {(segment === "documents" ||
            segment === "shifts" ||
            segment === "timesheet" ||
            segment === "invoice") && (
            <CustomTooltip content={"Download"}>
              <Download onClick={downLoadExcelFormat} className="sp-icons" />
            </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default SchedulingProfileNav;
