import React from "react";
import "../../../assets/css/SchedulingProfileNav.scss";
import { ReactComponent as BackArrow } from "../../../assets/images/icons/BackArrow.svg";
import { ReactComponent as Download } from "../../../assets/images/icons/Download.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/Print.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  convertKBtoMB,
  downloadExcel,
  exportToExcel,
} from "../../../helpers/fileUtills";
import { setActiveSPProfileNavLink } from "../../../redux/actions/spendManagementActions";
import constants from "../../../helpers/en";

function SpendManagementNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeLink = useSelector(
    (state) => state.spProfilenavigation.activeNavLink
  );
  const segment = location.pathname.split("/").pop();
  const { timeEntries } = useSelector((state) => state.timeEntries.timeEntries);
  const { spendDocuments } = useSelector((state) => state.spendDocuments);
  console.log("timeEntries====", timeEntries);

  const dispatch = useDispatch();

  const handleLinkClick = (link) => {
    dispatch(setActiveSPProfileNavLink(link));
  };
  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  const spendDocumentsList = Array.isArray(spendDocuments)
    ? spendDocuments
    : [];
  const documentData = spendDocumentsList.map((item, index) => [
    index + 1,
    item?.Id,
    item?.fileName,
    convertKBtoMB(item?.fileSize),
    item?.fileType,
    item?.uploadedDate,
    item?.relatedTo,
  ]);
  const documentHeaders = [
    "S.No",
    "Id",
    "File Name",
    "File Size",
    "File Type",
    "Uploaded Date",
    "Related To",
  ];
  const timeEntriesList = Array.isArray(timeEntries) ? timeEntries : [];
  const timeEntriesData = timeEntriesList.map((item, index) => [
    index + 1,
    item?.timesheetApproved,
    item?.firstName,
    item?.practitionerType,
    item?.specialty,
    item?.dateWorked,
    item?.ShiftStartEndTime,
    item?.Beeper,
    item?.clientName,
    item?.facilityName,
    item?.facilityState,
    item?.facilityCity,
    item?.confirmationNumber,
    item?.consultantName,
  ]);
  const timeEntriesHeaders = [
    "S.No",
    "Timesheeet Approved",
    "First Name",
    "Practitioner Type",
    "Specialty",
    "Date Worked",
    "Shift Start/End Time",
    "Beeper",
    "Client Name",
    "Facility Name",
    "Facility State",
    "Facility City",
    "Confirmation Number",
    "Consultant Name",
  ];

  const handlePrint = () => {
    window.print();
  };
  const fileName = "SpendManagement-Timesheet" + "-" + capitalize(segment);
  const downLoadExcelFormat = () => {
    if (segment === "time-entries") {
      exportToExcel(timeEntriesData, timeEntriesHeaders, fileName);
    } else if (segment === "documents") {
      exportToExcel(documentData, documentHeaders, fileName);
    }
  };

  return (
    <div className=" d-flex sp-nav-container">
      <button
        className="d-flex justify-content-center align-items-center ps-back-btn"
        onClick={() => navigate("..")}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            // Handles Enter and Space key press
            navigate("..");
          }
        }}
      >
        <BackArrow />
      </button>

      <ul>
        <Link
          to=""
          className={activeLink === "" ? "link active" : "link"}
          onClick={() => handleLinkClick("")}
        >
          {constants.PROFILE}
        </Link>
        <Link
          to="time-entries"
          className={activeLink === "time-entries" ? "link active" : "link"}
          onClick={() => handleLinkClick("time-entries")}
        >
          {constants.TIME_ENTRIES}
        </Link>
        <Link
          to="documents"
          className={activeLink === "documents" ? "link active" : "link"}
          onClick={() => handleLinkClick("documents")}
        >
          {constants.DOCUMENTS}
        </Link>
      </ul>
      <div className="sp-switches-container">
        <div className="sp-switches">
          <CustomTooltip content={"Print"}>
            <Print onClick={handlePrint} className="sp-icons" />
          </CustomTooltip>
          {(segment === "documents" || segment === "time-entries") && (
            <CustomTooltip content={"Download"}>
              <Download onClick={downLoadExcelFormat} className="sp-icons" />
            </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpendManagementNav;
