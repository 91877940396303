import axios from "axios";
import {
  SCHEDULING_CONFIRMATIONS_REQUEST,
  SCHEDULING_CONFIRMATIONS_SUCCESS,
  SCHEDULING_CONFIRMATIONS_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_SHIFT_REQUEST,
  GET_SHIFT_SUCCESS,
  GET_SHIFT_FAILURE,
  GET_DOCUMENT_REQUEST,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
  SET_ACTIVE_SCPROFILE_NAVLINK,
  VIEW_DOCUMENT_REQUEST,
  VIEW_DOCUMENT_SUCCESS,
  VIEW_DOCUMENT_FAILURE,
} from "./actionTypes";
import { headersRequest } from "../../helpers/commonUtills";
import {
  convertBase64ToPDF,
  openBase64InToDOCX,
} from "../../helpers/fileUtills";

export const getSchedulingConfirmations = (
  limit = 100,
  offset = 0,
  facilityPrompt = "",
  accountNumber = "",
  worksitwAccountNumber = ""
) => {
  return (dispatch) => {
    dispatch({ type: SCHEDULING_CONFIRMATIONS_REQUEST });

    const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations?limit=${limit}&offset=${offset}&facilityPrompt=${facilityPrompt}&accountNumber=${accountNumber}&worksitwAccountNumber=${worksitwAccountNumber}`;

    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({
          type: SCHEDULING_CONFIRMATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: SCHEDULING_CONFIRMATIONS_FAILURE,
          payload: error.message,
        });
      });
  };
};

// Action to fetch profile
export const getProfile = (confirmationNumber = "") => {
  return (dispatch) => {
    dispatch({ type: GET_PROFILE_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations/profile?confirmationNumber=${confirmationNumber}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_PROFILE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);

        dispatch({
          type: GET_PROFILE_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

// Action to fetch profile
export const fetchShifts = (limit = 2, offset = 0, confirmationNumber = "") => {
  return (dispatch) => {
    dispatch({ type: GET_SHIFT_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations/shifts?limit=${limit}&offset=${offset}&confirmationNumber=${confirmationNumber}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_SHIFT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: GET_SHIFT_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

// Action to fetch profile
export const fetchDocuments = (confirmationNumber = "") => {
  return (dispatch) => {
    dispatch({ type: GET_DOCUMENT_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations/document?confirmationNumber=${confirmationNumber}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_DOCUMENT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: GET_DOCUMENT_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};
// Action to fetch profile
export const viewPDFDocument = (documentId = "", fileType = "") => {
  return (dispatch) => {
    dispatch({ type: VIEW_DOCUMENT_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations/viewDocument?documentId=${documentId}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        if (fileType === "pdf") {
          convertBase64ToPDF(response.data);
        } else if (fileType === "docx" || fileType === "doc") {
          openBase64InToDOCX(response.data);
        } else {
          console.error("Unsupported file type:", fileType); // Optional: handle unsupported types
        }

        dispatch({ type: VIEW_DOCUMENT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: VIEW_DOCUMENT_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

export const setActiveSCProfileNavLink = (link) => ({
  type: SET_ACTIVE_SCPROFILE_NAVLINK,
  payload: link,
});
