export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";
export const CLEAR_AUTH = "CLEAR_AUTH";

export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILURE = "FETCH_DATA_FAILURE";
export const RESET_FACILITY_DATA = "RESET_FACILITY_DATA";

export const FETCH_ADMINS_REQUEST = "FETCH_ADMINS_REQUEST";
export const FETCH_ADMINS_SUCCESS = "FETCH_ADMINS_SUCCESS";
export const FETCH_ADMINS_FAILURE = "FETCH_ADMINS_FAILURE";
export const RESET_ADMIN_DATA = "RESET_ADMIN_DATA";

export const FETCH_LIST_REQUEST = "FETCH_LIST_REQUEST";
export const FETCH_LIST_SUCCESS = "FETCH_LIST_SUCCESS";
export const FETCH_LIST_FAILURE = "FETCH_LIST_FAILURE";

export const POST_DATA_REQUEST = "POST_DATA_REQUEST";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const POST_DATA_FAILURE = "POST_DATA_FAILURE";

export const EDIT_DATA_REQUEST = "EDIT_DATA_REQUEST";
export const EDIT_DATA_SUCCESS = "EDIT_DATA_SUCCESS";
export const EDIT_DATA_FAILURE = "EDIT_DATA_FAILURE";

export const FETCH_DASHBOARD_REPORTS_REQUEST =
  "FETCH_DASHBOARD_REPORTS_REQUEST";
export const FETCH_DASHBOARD_REPORTS_SUCCESS =
  "FETCH_DASHBOARD_REPORTS_SUCCESS";
export const FETCH_DASHBOARD_REPORTS_FAILURE =
  "FETCH_DASHBOARD_REPORTS_FAILURE";
export const CLEAR_DASHBOARD_REPORTS_FAILURE =
  "CLEAR_DASHBOARD_REPORTS_FAILURE";

export const POST_DASHBOARD_REPORTS_REQUEST = "POST_DASHBOARD_REPORTS_REQUEST";
export const POST_DASHBOARD_REPORTS_SUCCESS = "POST_DASHBOARD_REPORTS_SUCCESS";
export const POST_DASHBOARD_REPORTS_FAILURE = "POST_DASHBOARD_REPORTS_FAILURE";

export const EDIT_DASHBOARD_REPORTS_REQUEST = "EDIT_DASHBOARD_REPORTS_REQUEST";
export const EDIT_DASHBOARD_REPORTS_SUCCESS = "EDIT_DASHBOARD_REPORTS_SUCCESS";
export const EDIT_DASHBOARD_REPORTS_FAILURE = "EDIT_DASHBOARD_REPORTS_FAILURE";

export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const SET_SELECTED_FACILITY = "SET_SELECTED_FACILITY";
export const SET_SELECTED_CHILD_FACILITY = "SET_SELECTED_CHILD_FACILITY";
export const SET_SELECTED_CLIENT_ID = "SET_SELECTED_CLIENT_ID";
export const SET_SELECTED_FACILITY_ID = "SET_SELECTED_FACILITY_ID";
export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SCHEDULING_CONFIRMATIONS_REQUEST =
  "SCHEDULING_CONFIRMATIONS_REQUEST";
export const SCHEDULING_CONFIRMATIONS_SUCCESS =
  "SCHEDULING_CONFIRMATIONS_SUCCESS";
export const SCHEDULING_CONFIRMATIONS_FAILURE =
  "SCHEDULING_CONFIRMATIONS_FAILURE";

//MAIN HEADER NAVLINK
export const SET_ACTIVE_MAIN_NAVLINK = "SET_ACTIVE_MAIN_NAVLINK";

//DASHBOARD NAVLINK
export const SET_ACTIVE_DASH_NAVLINK = "SET_ACTIVE_DASH_NAVLINK";

//SCHEDULING NAVLINK
export const SET_ACTIVE_SCPROFILE_NAVLINK = "SET_ACTIVE_SCPROFILE_NAVLINK";

//SPEND MANAGEMENT MAIN NAVLINK
export const SET_ACTIVE_SP_MAIN_NAVLINK = "SET_ACTIVE_SP_MAIN_NAVLINK";

//SPEND MANAGEMENT TIMESHEET NAVLINK
export const SET_ACTIVE_SPPROFILE_NAVLINK = "SET_ACTIVE_SPPROFILE_NAVLINK";

//SPEND MANAGEMENT INVOICE NAVLINK
export const SET_ACTIVE_IV_SPPROFILE_NAVLINK =
  "SET_ACTIVE_IV_SPPROFILE_NAVLINK";

//scheduling profile
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
//Shift
export const GET_SHIFT_REQUEST = "GET_SHIFT_REQUEST";
export const GET_SHIFT_SUCCESS = "GET_SHIFT_SUCCESS";
export const GET_SHIFT_FAILURE = "GET_SHIFT_FAILURE";

export const GET_DOCUMENT_REQUEST = "GET_DOCUMENT_REQUEST";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";

export const SET_DS_REPORT_DATA = "SET_DS_REPORT_DATA";
export const CLEAR_DS_REPORT_DATA = "CLEAR_DS_REPORT_DATA";
//Facility Admin Section
export const GET_REPORT_DETAILS = "GET_REPORT_DETAILS";
export const SET_REPORT_DETAILS = "SET_REPORT_DETAILS";
export const REPORT_DETAILS_FAILURE = "REPORT_DETAILS_FAILURE";

// actionTypes.js
export const FETCH_REPORTS_REQUEST = "FETCH_REPORTS_REQUEST";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";

export const SET_ADMIN_FACILITY = "SET_ADMIN_FACILITY";
export const SET_ADMIN_FACILITY_ID = "SET_ADMIN_FACILITY_ID";
export const SET_ADMIN_CHILD_FACILITY = "SET_ADMIN_CHILD_FACILITY";
export const CLEAR_ALL_ADMIN_FACILITIES = "CLEAR_ALL_ADMIN_FACILITIES";

export const FETCH_FACILITY_USER_REQUEST = "FETCH_FACILITY_USER_REQUEST";
export const FETCH_FACILITY_USER_SUCCESS = "FETCH_FACILITY_USER_SUCCESS";
export const FETCH_FACILITY_USER_FAILURE = "FETCH_FACILITY_USER_FAILURE";

export const POST_FACILITY_USER_REQUEST = "POST_FACILITY_USER_REQUEST";
export const POST_FACILITY_USER_SUCCESS = "POST_FACILITY_USER_SUCCESS";
export const POST_FACILITY_USER_FAILURE = "POST_FACILITY_USER_FAILURE";

export const EDIT_FACILITY_USER_REQUEST = "EDIT_FACILITY_USER_REQUEST";
export const EDIT_FACILITY_USER_SUCCESS = "EDIT_FACILITY_USER_SUCCESS";
export const EDIT_FACILITY_USER_FAILURE = "EDIT_FACILITY_USER_FAILURE";

export const VIEW_DOCUMENT_REQUEST = "VIEW_DOCUMENT_REQUEST";
export const VIEW_DOCUMENT_SUCCESS = "VIEW_DOCUMENT_SUCCESS";
export const VIEW_DOCUMENT_FAILURE = "VIEW_DOCUMENT_FAILURE";

export const SPEND_TIMESHEET_REQUEST = "SPEND_TIMESHEET_REQUEST";
export const SPEND_TIMESHEET_SUCCESS = "SPEND_TIMESHEET_SUCCESS";
export const SPEND_TIMESHEET_FAILURE = "SPEND_TIMESHEET_FAILURE";

export const FETCH_SPPROFILE_REQUEST = "FETCH_SPPROFILE_REQUEST";
export const FETCH_SPPROFILE_SUCCESS = "FETCH_SPPROFILE_SUCCESS";
export const FETCH_SPPROFILE_FAILURE = "FETCH_SPPROFILE_FAILURE";

export const GET_TIMEENTRIES_REQUEST = "GET_TIMEENTRIES_REQUEST";
export const GET_TIMEENTRIES_SUCCESS = "GET_TIMEENTRIES_SUCCESS";
export const GET_TIMEENTRIES_FAILURE = "GET_TIMEENTRIES_FAILURE";

export const FETCH_FACILITY_USER_DB_REQUEST = "FETCH_FACILITY_USER_DB_REQUEST";
export const FETCH_FACILITY_USER_DB_SUCCESS = "FETCH_FACILITY_USER_DB_SUCCESS";
export const FETCH_FACILITY_USER_DB_FAILURE = "FETCH_FACILITY_USER_DB_FAILURE";

export const GET_SPEND_DOCUMENT_REQUEST = "GET_SPEND_DOCUMENT_REQUEST";
export const GET_SPEND_DOCUMENT_SUCCESS = "GET_SPEND_DOCUMENT_SUCCESS";
export const GET_SPEND_DOCUMENT_FAILURE = "GET_SPEND_DOCUMENT_FAILURE";

export const VIEW_SPEND_DOCUMENT_REQUEST = "VIEW_SPEND_DOCUMENT_REQUEST";
export const VIEW_SPEND_DOCUMENT_SUCCESS = "VIEW_SPEND_DOCUMENT_SUCCESS";
export const VIEW_SPEND_DOCUMENT_FAILURE = "VIEW_SPEND_DOCUMENT_FAILURE";

export const SPEND_INVOICE_REQUEST = "SPEND_INVOICE_REQUEST";
export const SPEND_INVOICE_SUCCESS = "SPEND_INVOICE_SUCCESS";
export const SPEND_INVOICE_FAILURE = "SPEND_INVOICE_FAILURE";

export const INVOICE_TIMEENTRIES_REQUEST = "INVOICE_TIMEENTRIES_REQUEST";
export const INVOICE_TIMEENTRIES_SUCCESS = "INVOICE_TIMEENTRIES_SUCCESS";
export const INVOICE_TIMEENTRIES_FAILURE = "INVOICE_TIMEENTRIES_FAILURE";

export const INVOICE_EXPENSES_REQUEST = "INVOICE_EXPENSES_REQUEST";
export const INVOICE_EXPENSES_SUCCESS = "INVOICE_EXPENSES_SUCCESS";
export const INVOICE_EXPENSES_FAILURE = "INVOICE_EXPENSES_FAILURE";

export const SET_CHECK_STATUS = "SET_CHECK_STATUS";
export const LOGOUT_USER = "LOGOUT_USER";

//SPEND MANAGEMENT INVOICES
export const GET_SP_INV_PROFILE_REQUEST = "GET_SP_INV_PROFILE_REQUEST";
export const GET_SP_INV_PROFILE_SUCCESS = "GET_SP_INV_PROFILE_SUCCESS";
export const GET_SP_INV_PROFILE_FAILURE = "GET_SP_INV_PROFILE_FAILURE";

export const GET_SP_INV_DOCUMENT_REQUEST = "GET_SP_INV_DOCUMENT_REQUEST";
export const GET_SP_INV_DOCUMENT_SUCCESS = "GET_SP_INV_DOCUMENT_SUCCESS";
export const GET_SP_INV_DOCUMENT_FAILURE = "GET_SP_INV_DOCUMENT_FAILURE";
