import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";
import FacilityPageTable from "../pages/SuperAdmin/Facility/FacilityPageTable";
import WorkSiteList from "../pages/SuperAdmin/Facility/WorkSitesList";
import PrivateRoute from "../components/Commons/PrivateRoute";
import { ProgressSpinner } from "primereact/progressspinner";
import CandidateEditReport from "../pages/SuperAdmin/Dashboard/Candidate/CandidateEditReport";
import SchedulingEditReport from "../pages/SuperAdmin/Dashboard/Scheduling/SchedulingEditReport";
import SpendManagementEditReport from "../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementEditReport";
import LocumsAnalyticsEditReport from "../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsEditReport";
import LandingPageDashboardEditReport from "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardEditReport";
import ScheduledReportsEditReport from "../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsEditReport";
import EmailConfirmation from "../pages/SignIn/EmailConfirmation";
import TablaeuPage from "../components/Commons/TableauPage";
import SchedulingProfile from "../pages/SuperAdmin/Scheduling/SchedulingProfile";
import ScProfile from "../pages/SuperAdmin/Scheduling/ScProfile";
import Shifts from "../pages/SuperAdmin/Scheduling/Shifts";
import Calendar from "../pages/SuperAdmin/Scheduling/Calendar";
import Documents from "../pages/SuperAdmin/Scheduling/Documents";
// import PdfViewer from "../pages/SuperAdmin/Scheduling/PdfViewer";
import DocumentsTable from "../pages/SuperAdmin/Scheduling/DocumentTable";
import SpendManagementProfile from "../pages/SuperAdmin/SpendManagement/SpendManagementProfile";
import SpendProfile from "../pages/SuperAdmin/SpendManagement/SpendProfile";
import TimeEntries from "../pages/SuperAdmin/SpendManagement/TimeEntries";
import SpendDocuments from "../pages/SuperAdmin/SpendManagement/SpendDocuments";
import SpInvoiceProfile from "../pages/SuperAdmin/SpendManagement/SpInvoiceProfile";
import InvProfile from "../pages/SuperAdmin/SpendManagement/InvProfile";
import InvTimeEntries from "../pages/SuperAdmin/SpendManagement/InvTimeEntries";
import InvExpenses from "../pages/SuperAdmin/SpendManagement/InvExpenses";
import InvDocuments from "../pages/SuperAdmin/SpendManagement/InvDocuments";
import SpendMtgInvoicesMain from "../pages/SuperAdmin/SpendManagement/SpendMtgInvoicesMain";
import SpendMgtTimesheetMain from "../pages/SuperAdmin/SpendManagement/SpendMgtTimesheetMain";
import Timesheet from "../pages/SuperAdmin/Scheduling/Timesheet";
import Invoice from "../pages/SuperAdmin/Scheduling/Invoice";
import MyProfile from "../pages/SuperAdmin/MyProfile";

const Login = lazy(() => import("../pages/SignIn/Signin"));
const SuperAdminPage = lazy(() => import("../pages/SuperAdmin/SuperAdminPage"));
const Facility = lazy(() => import("../pages/SuperAdmin/Facility"));
const DashboardPage = lazy(() => import("../pages/SuperAdmin/DashboardPage"));
const Candidate = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/Candidate")
);
const SchedulingDashboard = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/Scheduling")
);
const SpendManagementDashboard = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagement")
);
const ScheduledReports = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReports")
);
const LandingPageDashboard = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboard"
  )
);
const LocumsAnalytics = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalytics")
);
const EmailVerificationSuccess = lazy(() =>
  import("../pages/SignIn/EmailVerificationSuccess")
);
const CandidateMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/CandidateMain")
);
const CandidateAddReport = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Candidate/CandidateAddReport")
);
const SchedulingMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/SchedulingMain")
);
const SchedulingAddReport = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/Scheduling/SchedulingAddReport")
);
const SpendManagementMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementMain")
);
const SpendManagementAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/SpendManagement/SpendManagementAddReport"
  )
);
const LocumsAnalyticsMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsMain")
);
const LocumsAnalyticsAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LocumsAnalytics/LocumsAnalyticsAddReport"
  )
);
const LandingPageDashboardMain = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardMain"
  )
);
const LandingPageDashboardAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/LandingPageDashboard/LandingPageDashboardAddReport"
  )
);
const ScheduledReportsMain = lazy(() =>
  import("../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsMain")
);
const ScheduledReportsAddReport = lazy(() =>
  import(
    "../pages/SuperAdmin/Dashboard/ScheduledReports/ScheduledReportsAddReport"
  )
);
const ScheduleManagement = lazy(() =>
  import("../pages/SuperAdmin/ScheduleManagement")
);
const SchedulingConfirmations = lazy(() =>
  import("../pages/SuperAdmin/Scheduling/SchedulingConfirmations")
);
const SpendMtgTimesheets = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement/SpendMtgTimesheets")
);
const SpendMtgInvoices = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement/SpendMtgInvoices")
);
const SpendManagement = lazy(() =>
  import("../pages/SuperAdmin/SpendManagement")
);

const NotFound = lazy(() => import("../pages/NotFound"));
const RoutesConfig = () => {
  return (
    <Provider store={store}>
      <Router>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {/* Use ProgressSpinner as the loader */}
              <ProgressSpinner />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/email-verification-success"
              element={<EmailVerificationSuccess />}
            />
            <Route path="/email-confirm" element={<EmailConfirmation />} />
            <Route
              path="/home/*"
              element={
                <PrivateRoute>
                  <SuperAdminPage />
                </PrivateRoute>
              }
            >
              
              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <Facility />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <FacilityPageTable />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="worksite-list"
                  element={
                    <PrivateRoute>
                      <WorkSiteList />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="profile"
                element={
                  <PrivateRoute>
                    <MyProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path="dashboard/*"
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <Candidate />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <CandidateMain />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <CandidateAddReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <CandidateEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="scheduling"
                  element={
                    <PrivateRoute>
                      <SchedulingDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SchedulingMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <SchedulingEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <SchedulingAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="spend-management"
                  element={
                    <PrivateRoute>
                      <SpendManagementDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SpendManagementMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <SpendManagementEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <SpendManagementAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="locums-analytics"
                  element={
                    <PrivateRoute>
                      <LocumsAnalytics />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <LocumsAnalyticsMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <LocumsAnalyticsEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <LocumsAnalyticsAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="landing-page-dashboard"
                  element={
                    <PrivateRoute>
                      <LandingPageDashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <LandingPageDashboardMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <LandingPageDashboardEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <LandingPageDashboardAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
                <Route
                  path="scheduled-reports"
                  element={
                    <PrivateRoute>
                      <ScheduledReports />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <ScheduledReportsMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="editreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <ScheduledReportsEditReport />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="show-iframe"
                    element={
                      <PrivateRoute>
                        <TablaeuPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="addreport"
                    element={
                      <PrivateRoute allowedRoles={["Super Admin"]}>
                        <ScheduledReportsAddReport />
                      </PrivateRoute>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="schedule-management/*"
                element={
                  <PrivateRoute>
                    <ScheduleManagement />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <SchedulingConfirmations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="scheduling-profile"
                  element={
                    <PrivateRoute>
                      <SchedulingProfile />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <ScProfile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="shifts"
                    element={
                      <PrivateRoute>
                        <Shifts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="calendar"
                    element={
                      <PrivateRoute>
                        <Calendar />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="timesheet"
                    element={
                      <PrivateRoute>
                        <Timesheet />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="invoice"
                    element={
                      <PrivateRoute>
                        <Invoice />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="documents/*"
                    element={
                      <PrivateRoute>
                        <Documents />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="*"
                      element={
                        <PrivateRoute>
                          <DocumentsTable />
                        </PrivateRoute>
                      }
                    ></Route>
                  </Route>
                </Route>
              </Route>
              <Route
                path="spend-management/*"
                element={
                  <PrivateRoute>
                    <SpendManagement />
                  </PrivateRoute>
                }
              >
                <Route
                  path="*"
                  element={
                    <PrivateRoute>
                      <SpendMtgTimesheets />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SpendMgtTimesheetMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="spendManagement-profile"
                    element={
                      <PrivateRoute>
                        <SpendManagementProfile />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="*"
                      element={
                        <PrivateRoute>
                          <SpendProfile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="time-entries"
                      element={
                        <PrivateRoute>
                          <TimeEntries />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="documents"
                      element={
                        <PrivateRoute>
                          <SpendDocuments />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                </Route>

                <Route
                  path="spend-mtg-invoices"
                  element={
                    <PrivateRoute>
                      <SpendMtgInvoices />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <SpendMtgInvoicesMain />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="spend-invoice-profile"
                    element={
                      <PrivateRoute>
                        <SpInvoiceProfile />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="*"
                      element={
                        <PrivateRoute>
                          <InvProfile />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="expenses"
                      element={
                        <PrivateRoute>
                          <InvExpenses />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="time-entries"
                      element={
                        <PrivateRoute>
                          <InvTimeEntries />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="documents"
                      element={
                        <PrivateRoute>
                          <InvDocuments />
                        </PrivateRoute>
                      }
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default RoutesConfig;
