import axios from "axios";
import {
  SPEND_TIMESHEET_REQUEST,
  SPEND_TIMESHEET_SUCCESS,
  SPEND_TIMESHEET_FAILURE,
  GET_TIMEENTRIES_REQUEST,
  GET_TIMEENTRIES_SUCCESS,
  GET_TIMEENTRIES_FAILURE,
  FETCH_SPPROFILE_REQUEST,
  FETCH_SPPROFILE_SUCCESS,
  FETCH_SPPROFILE_FAILURE,
  SET_ACTIVE_SPPROFILE_NAVLINK,
  GET_SPEND_DOCUMENT_REQUEST,
  GET_SPEND_DOCUMENT_SUCCESS,
  GET_SPEND_DOCUMENT_FAILURE,
  VIEW_SPEND_DOCUMENT_REQUEST,
  VIEW_SPEND_DOCUMENT_SUCCESS,
  VIEW_SPEND_DOCUMENT_FAILURE,
  SET_ACTIVE_IV_SPPROFILE_NAVLINK,
  SET_ACTIVE_SP_MAIN_NAVLINK,
} from "./actionTypes";
import { headersRequest } from "../../helpers/commonUtills";
import {
  convertBase64ToPDF,
  openBase64InToDOCX,
} from "../../helpers/fileUtills";
import constants from "../../helpers/en";

export const getSpendManagement = (
  limit = constants.LIMIT,
  offset = 0,
  facilityPrompt = "",
  accountNumber = "",
  worksitwAccountNumber = "",
  confirmationNumber = ""
) => {
  return (dispatch) => {
    dispatch({ type: SPEND_TIMESHEET_REQUEST });

    const url = `${process.env.REACT_APP_API_URL}/spendManagementTimesheet?limit=${limit}&offset=${offset}&facilityPrompt=${facilityPrompt}&accountNumber=${accountNumber}&worksitwAccountNumber=${worksitwAccountNumber}&confirmationNumber=${confirmationNumber}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({
          type: SPEND_TIMESHEET_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: SPEND_TIMESHEET_FAILURE,
          payload: error.message,
        });
      });
  };
};

// Action to fetch timeEntries
export const fetchTimeEntries = (
  limit = constants.LIMIT,
  offset = 0,
  facilityPrompt,
  confirmationNumber = "",
  Id = ""
) => {
  return (dispatch) => {
    dispatch({ type: GET_TIMEENTRIES_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/spendManagementTimesheet/timeEntries?limit=${limit}&offset=${offset}&facilityPrompt=${facilityPrompt}&confirmationNumber=${confirmationNumber}&Id=${Id}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_TIMEENTRIES_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: GET_TIMEENTRIES_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

export const fetchSpProfileRequest = () => ({
  type: FETCH_SPPROFILE_REQUEST,
});

export const fetchSpProfileSuccess = (data) => ({
  type: FETCH_SPPROFILE_SUCCESS,
  payload: data,
});

export const fetchSpProfileFailure = (error) => ({
  type: FETCH_SPPROFILE_FAILURE,
  payload: error,
});

export const fetchSpProfileById = (confirmationNumber, Id) => {
  return (dispatch) => {
    dispatch(fetchSpProfileRequest());
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/spendManagementTimesheet/profile?confirmationNumber=${confirmationNumber}&Id=${Id}`,

        { headers: headersRequest }
      )
      .then((response) => {
        dispatch(fetchSpProfileSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchSpProfileFailure(error.message));
      });
  };
};

// Dispatches action to fetch spend documents by confirmation number and spend ID
export const fetchSpendDocuments = (confirmationNumber = "", spendId = "") => {
  return (dispatch) => {
    dispatch({ type: GET_SPEND_DOCUMENT_REQUEST });
    // Dynamically construct the API URL with parameters
    const url = `${process.env.REACT_APP_API_URL}/spendManagementTimesheet/document?confirmationNumber=${confirmationNumber}&Id=${spendId}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_SPEND_DOCUMENT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        // Handle error: safely access error.response and dispatch failure action
        const errorMessage =
          error?.response?.data?.message || "Failed to fetch documents";
        const errorCode = error?.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`); // Log error details for debugging
        dispatch({
          type: GET_SPEND_DOCUMENT_FAILURE,
          payload: errorMessage,
        });
      });
  };
};
// Action to fetch profile
export const viewSpendPDFDocument = (documentId = "", fileType = "") => {
  const confirmationNumber = sessionStorage.getItem("SpconfirmationNumber");
  if (!confirmationNumber) {
    console.error("Confirmation number not found in session storage.");
    return;
  }
  return (dispatch) => {
    dispatch({ type: VIEW_SPEND_DOCUMENT_REQUEST });
    if (confirmationNumber) {
      const url = `${process.env.REACT_APP_API_URL}/schedulingConfirmations/viewDocument?confirmationNumber=${confirmationNumber}&documentId=${documentId}`;

      axios
        .get(url, { headers: headersRequest })
        .then((response) => {
          if (fileType === "pdf") {
            convertBase64ToPDF(response.data);
          } else if (fileType === "docx" || fileType === "doc") {
            openBase64InToDOCX(response.data);
          } else {
            console.error("Unsupported file type:", fileType); // Optional: handle unsupported types
          }

          dispatch({
            type: VIEW_SPEND_DOCUMENT_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || "Failed to fetch documents";
          const errorCode = error.response?.status || "Unknown Error Code";
          console.error(`API Error (${errorCode}): ${errorMessage}`);
          console.log("error", error.message);
          dispatch({
            type: VIEW_SPEND_DOCUMENT_FAILURE,
            payload: error.response?.data?.message || "Failed to fetch profile",
          });
        });
    }
  };
};
export const setActiveSPMainNavLink = (link) => ({
  type: SET_ACTIVE_SP_MAIN_NAVLINK,
  payload: link,
});

export const setActiveSPProfileNavLink = (link) => ({
  type: SET_ACTIVE_SPPROFILE_NAVLINK,
  payload: link,
});

export const setActiveSPProfileIvNavLink = (link) => ({
  type: SET_ACTIVE_IV_SPPROFILE_NAVLINK,
  payload: link,
});
