import React from "react";
import "../../../assets/css/SchedulingProfileNav.scss";
import { ReactComponent as BackArrow } from "../../../assets/images/icons/BackArrow.svg";
import { ReactComponent as Download } from "../../../assets/images/icons/Download.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/Print.svg";

import { Link, useNavigate, useLocation } from "react-router-dom";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import { convertKBtoMB, exportToExcel } from "../../../helpers/fileUtills";
import constants from "../../../helpers/en";
import { setActiveSPProfileIvNavLink } from "../../../redux/actions/spendManagementActions";

const InvoiceNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const segment = location.pathname.split("/").pop();
  const activeLink = useSelector(
    (state) => state.spProfileIvnavigation.activeNavLink
  );
  const spIvDocument = useSelector((state) => state.spIvDocument?.data || []);
  const invTimeEntries = useSelector(
    (state) => state.invTimeEntries.invTimeEntries
  );
  const invExpenses = useSelector((state) => state.invExpenses.invExpenses);
  const capitalize = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const documentsList = Array.isArray(spIvDocument) ? spIvDocument : [];
  const documentData = documentsList.map((item, index) => [
    index + 1,
    item?.Id,
    item?.fileName,
    item?.fileType,
    convertKBtoMB(item?.fileSize),
    item?.uploadedDate,
    item?.relatedTo,
  ]);
  const documentHeaders = [
    "S.No",
    "Id",
    "File Name",
    "File Type",
    "File Size",
    "Uploaded Date",
    "Related To",
  ];

  const expensesList = Array.isArray(invExpenses) ? invExpenses : [];
  const expensesData = expensesList.map((item, index) => [
    index + 1,
    item?.clientInvoice,
    item?.description,
    item?.amount,
    item?.expenseType,
    item?.practitionerType,
    item?.specialty,
    item?.facilityName,
    item?.invoiceDate,
    item?.invoiceNumber,
    item?.status,
  ]);
  const expensesHeaders = [
    "S.No",
    "Client Invoice",
    "Description",
    "Amount",
    "Expense Type",
    "Provider Name",
    "Client Name",
    "Facility Name",
    "Invoice Date",
    "Invoice Number",
    "Status",
  ];
  const timeEntriesList = Array.isArray(invTimeEntries) ? invTimeEntries : [];
  const timeEntriesData = timeEntriesList.map((item, index) => [
    index + 1,
    item?.shiftType,
    item?.description,
    item?.amount,
    item?.providerName,
    item?.facilityName,
    item?.clientName,
    item?.invoiceDate,
    item?.invoiceNumber,
    item?.status,
  ]);
  const timeEntriesHeaders = [
    "S.No",
    "Shift Type",
    "Description",
    "Amount",
    "Provider Name",
    "Facility Name",
    "Client Name",
    "Invoice Date",
    "Invoice Number",
    "Status",
  ];

  const handlePrint = () => {
    window.print();
  };

  const handleLinkClick = (link) => {
    dispatch(setActiveSPProfileIvNavLink(link));
  };
  const fileName = "SpendManagement-Invoice" + "-" + capitalize(segment);
  const downLoadExcelFormat = () => {
    if (segment === "documents") {
      exportToExcel(documentData, documentHeaders, fileName);
    } else if (segment === "expenses") {
      exportToExcel(expensesData, expensesHeaders, fileName);
    } else if (segment === "time-entries") {
      exportToExcel(timeEntriesData, timeEntriesHeaders, fileName);
    }
  };

  return (
    <div className=" d-flex sp-nav-container">
      <button
        className="d-flex justify-content-center align-items-center ps-back-btn"
        onClick={() => navigate("..")}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            // Handles Enter and Space key press
            navigate("..");
          }
        }}
      >
        <BackArrow />
      </button>

      <ul>
        <Link
          to=""
          className={activeLink === "" ? "link active" : "link"}
          onClick={() => handleLinkClick("")}
        >
          {constants.PROFILE}
        </Link>
        <Link
          to="expenses"
          className={activeLink === "expenses" ? "link active" : "link"}
          onClick={() => handleLinkClick("expenses")}
        >
          {constants.EXPENSES}
        </Link>
        <Link
          to="time-entries"
          className={activeLink === "time-entries" ? "link active" : "link"}
          onClick={() => handleLinkClick("time-entries")}
        >
          {constants.TIME_ENTRIES}
        </Link>
        <Link
          to="documents"
          className={activeLink === "documents" ? "link active" : "link"}
          onClick={() => handleLinkClick("documents")}
        >
          {constants.DOCUMENTS}
        </Link>
      </ul>
      <div className="sp-switches-container">
        <div className="sp-switches">
          <CustomTooltip content={"Print"}>
            <Print onClick={handlePrint} className="sp-icons" />
          </CustomTooltip>
          {(segment === "documents" ||
            segment === "time-entries" ||
            segment === "expenses") && (
            <CustomTooltip content={"Download"}>
              <Download onClick={downLoadExcelFormat} className="sp-icons" />
            </CustomTooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceNavbar;
