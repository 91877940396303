// reducers/index.js
import { combineReducers } from "redux";
import { authReducer, activeSatauReducer } from "./authReducer";
import {
  facilitySFReducer,
  getFacilityDBReducer,
  AddFacilityReducer,
  adminListReducer,
  worksiteReducer,
  mainNavReducer,
  fetchFacilityUserReducer,
  postFacilityUserReducer,
  facilityUserDBReducer,
} from "./facilityReducer";
import {
  dashboardNavReducer,
  dsReportReducer,
  getDashboardReportsReducer,
  selectionReducer,
} from "./dashboardReducers";
import roleReducer from "./rollReducer";
import {
  schedulingReducer,
  profileReducer,
  shiftReducer,
  documentReducer,
  scProfileNavReducer,
  viewDocumentReducer,
} from "./schedulingReducer";
import {
  reportReducer,
  reportByClientReducer,
  adminFacilityReducer,
} from "./facilityAdminReducers";
import {
  spendManagementReducer,
  spProfileNavReducer,
  SpProfileReducer,
  timeEntiresReducer,
  documentSpendReducer,
  viewSpendDocumentReducer,
  spIvProfileNavReducer,
  spMainNavReducer,
} from "./spendManagementReducers";
import {
  InvExpensesReducer,
  InvTimeEntiresReducer,
  spendMtgInvoiceReducer,
  SpIvDocumentReducer,
  SpIvProfileReducer,
} from "./spendMtgInvoiceReducers";

const rootReducer = combineReducers({
  UserInfo: authReducer,
  hospital: facilitySFReducer,
  admin: adminListReducer,
  facilityList: getFacilityDBReducer,
  post: AddFacilityReducer,
  worksite: worksiteReducer,
  dashboardReportList: getDashboardReportsReducer,
  selection: selectionReducer,
  rolePermission: roleReducer,
  scheduling: schedulingReducer,
  mainNavigation: mainNavReducer,
  dashboardNavigation: dashboardNavReducer,
  shifts: shiftReducer,
  profile: profileReducer,
  document: documentReducer,
  profilenavigation: scProfileNavReducer,
  dsReport: dsReportReducer,
  tableauReport: reportReducer,
  reportName: reportByClientReducer,
  adminFacilityData: adminFacilityReducer,
  getFacilityUser: fetchFacilityUserReducer,
  postFacilityUser: postFacilityUserReducer,
  viewDocument: viewDocumentReducer,
  spendManagement: spendManagementReducer,
  timeEntries: timeEntiresReducer,
  SpProfile: SpProfileReducer,
  spMainNavigation: spMainNavReducer,
  spProfilenavigation: spProfileNavReducer,
  spProfileIvnavigation: spIvProfileNavReducer,
  facilityUserDB: facilityUserDBReducer,
  spendDocuments: documentSpendReducer,
  spendViewDocument: viewSpendDocumentReducer,
  checkStatus: activeSatauReducer,
  spendMtgInvoice: spendMtgInvoiceReducer,
  spIvProfile: SpIvProfileReducer,
  spIvDocument: SpIvDocumentReducer,
  invTimeEntries: InvTimeEntiresReducer,
  invExpenses: InvExpensesReducer,
});

export default rootReducer;
