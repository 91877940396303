import React, { memo, useEffect, useRef, useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/AddNewUser.scss";
import "../../../assets/css/InputSwitch.scss";
import RegularButton from "../../../components/Commons/RegularButton";
import { Dropdown } from "primereact/dropdown";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Personadata1, USER_TYPES } from "../../../helpers/enums";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types"; // Import PropTypes
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFacilityUser,
  getSFWorksiteList,
  postFacilityUser,
} from "../../../redux/actions/facilityActions";
import { MultiSelect } from "primereact/multiselect";
import CustomToast from "../../../components/Commons/CustomToast";
import * as Yup from "yup";
import constants from "../../../helpers/en";
import {
  clearAllAdminFacilities,
  setAdminFacility,
  setAdminFacilityID,
} from "../../../redux/actions/facilityAdminAction";
import DropdownSpinner from "../../../components/Commons/DrpodownSpinner";

const AddFacilityUser = memo((props) => {
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [candidates, setCandidates] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [spendManagement, setSpendManagement] = useState(false);
  const [locumsAnalytics, setLocumsAnalytics] = useState(false);
  const [scheduledReport, setScheduledReport] = useState(false);
  const [landingPage, setLandingPage] = useState(false);
  const [scheduleManagement, setScheduleManagement] = useState(false);
  const [spendManagementMenu, setSpendManagementMenu] = useState(false);
  const [selectedPersonas, setSelectedPersonas] = useState(null);
  const closeAddFacilityUser = props.closeAddFacilityUser;
  const [result, setResult] = useState({});
  const clientPropsData = props?.hospital;
  const initialValues = {
    userType: null,
    addUser: null,
    fullName: "",
    title: "",
    phoneNumber: "",
    email: "",
    accountName: "",
  };
  //redux selectors
  const { data } = useSelector((state) => state.getFacilityUser);
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );

  const personaKeys = Object.keys(Personadata1[0]?.personaData);
  const capitalizedArray = personaKeys.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });

  const resetSwitches = () => {
    setCandidates(false);
    setScheduling(false);
    setSpendManagement(false);
    setLocumsAnalytics(false);
    setScheduledReport(false);
    setLandingPage(false);
    setScheduleManagement(false);
    setSpendManagementMenu(false);
  };

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.SUPER_ADMIN) return;
    dispatch(fetchFacilityUser(clientPropsData?.facility_account_no_fd));
  }, [dispatch]);

  const handlePersonaSelection = (selected) => {
    setSelectedPersonas(selected);

    const resultObject = selected.reduce((acc, persona) => {
      acc[persona.toLowerCase()] = true;
      return acc;
    }, {});
    setResult(resultObject);
  };

  const validationSchema = Yup.object({
    userType: Yup.array()
      .min(1, constants.FIELD_REQUIRED)
      .required(constants.FIELD_REQUIRED),
    addUser: Yup.mixed().required(constants.FIELD_REQUIRED),
  });

  const selectedAdminFacility = useSelector(
    (state) => state.adminFacilityData.adminFacility
  );

  const handleUserChange = (optionValue, setFieldValue) => {
    setFieldValue("fullName", optionValue?.fullName || "");
    setFieldValue("title", optionValue?.title || "");
    setFieldValue("phoneNumber", optionValue?.phone || "");
    setFieldValue("email", optionValue?.email || "");
    if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      setFieldValue("accountName", clientPropsData?.account_name_fd || "");
    }
    if (rolePermission.role === USER_TYPES.FACILITY_ADMIN) {
      setFieldValue("accountName", selectedAdminFacility?.name || "");
    }
  };

  //DROPDOWN
  const { worksiteData, loading: worksiteLoading } = useSelector(
    (state) => state.worksite
  );

  useEffect(() => {
    return () => {
      if (rolePermission.role !== USER_TYPES.FACILITY_ADMIN) return;
      dispatch(clearAllAdminFacilities());
    };
  }, []);

  //local states
  const getWorksite = () => {
    let accountNo = rolePermission.userInfo?.facility_account_no;
    if (accountNo) {
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    }
  };

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_ADMIN) return;
    getWorksite();
  }, [dispatch]);

  const onFacilityChange = (e) => {
    dispatch(setAdminFacility(e.value));
    dispatch(setAdminFacilityID(e.value?.accountNumber));
  };

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_ADMIN) return;
    dispatch(fetchFacilityUser(selectedAdminFacility?.accountNumber));
  }, [dispatch, selectedAdminFacility]);

  let options = [];

  if (!worksiteLoading) {
    if (Array.isArray(worksiteData?.result)) {
      options = worksiteData?.result;
    } else {
      options = [];
    }
  }

  return (
    <div className=" modal-overlay d-flex ">
      <CustomToast ref={toastRef} position="top-right" />
      <div className="d-flex w-100 justify-content-center ">
        <div className="modal-container">
          <div className="d-flex justify-content-between mx-3 align-items-center">
            <h2 className="popup-head-text">{constants.ADD_FACILITY_USER}</h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={props.closeAddFacilityUser}
              />
            </div>
          </div>
          <div className="ar-main-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
                  const userData = [
                    {
                      facility_account_no:
                        clientPropsData?.facility_account_no_fd,
                      full_name: selectedUser?.fullName,
                      title: selectedUser?.title || "",
                      is_active: true,
                      email_verified: true,
                      // email_verified: false,
                      email: selectedUser?.email || "",
                      account_name: clientPropsData?.account_name_fd || "",
                      role: "Facility User",
                      phone: selectedUser?.phone || "",
                      tableau_subscription: false,
                      salesforce_id: selectedUser?.salesforceId,
                    },
                  ];

                  const AccessData = [
                    {
                      candidates: candidates,
                      scheduling: scheduling,
                      spend_management_dashboard: spendManagement,
                      locums_analytics: locumsAnalytics,
                      scheduled_report: scheduledReport,
                      landing_page: landingPage,
                      schedule_management: scheduleManagement,
                      spend_management_menu: spendManagementMenu,
                    },
                  ];

                  const userTypeData = [result];
                  dispatch(
                    postFacilityUser(
                      userData,
                      AccessData,
                      userTypeData,
                      toastRef,
                      closeAddFacilityUser
                    )
                  );
                }

                if (rolePermission.role === USER_TYPES.FACILITY_ADMIN) {
                  const userData = [
                    {
                      facility_account_no: Number(
                        selectedAdminFacility?.accountNumber
                      ),
                      full_name: selectedUser?.fullName,
                      title: selectedUser?.title || "",
                      is_active: true,
                      email_verified: false,
                      email: selectedUser?.email || "",
                      account_name: selectedAdminFacility?.name || "",
                      role: "Facility User",
                      phone: selectedUser?.phone || "",
                      tableau_subscription: false,
                      salesforce_id: selectedUser?.salesforceId,
                    },
                  ];

                  const AccessData = [
                    {
                      candidates: candidates,
                      scheduling: scheduling,
                      spend_management_dashboard: spendManagement,
                      locums_analytics: locumsAnalytics,
                      scheduled_report: scheduledReport,
                      landing_page: landingPage,
                      schedule_management: scheduleManagement,
                      spend_management_menu: spendManagementMenu,
                    },
                  ];

                  const userTypeData = [result];
                  dispatch(
                    postFacilityUser(
                      userData,
                      AccessData,
                      userTypeData,
                      toastRef,
                      closeAddFacilityUser
                    )
                  );
                }
              }}
            >
              {({ values, setFieldValue, resetForm }) => (
                <Form>
                  <div className=" form-container d-flex flex-wrap">
                    <div className="ar-input-card">
                      {rolePermission.role === USER_TYPES.SUPER_ADMIN && (
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 bold-barlow ar-label"
                            htmlFor="parent-account"
                            name="parent-account"
                          >
                            Parent account
                          </label>
                          <input
                            className="form-control col-8 ar-input"
                            name="parent-account"
                            value={props.hospital?.account_name_fd}
                            disabled
                            id="parent-account"
                          />
                        </div>
                      )}
                      {rolePermission.role === USER_TYPES.FACILITY_ADMIN && (
                        <div className="row g-0 my-1">
                          <label
                            htmlFor="select-facility"
                            className="col-4 bold-barlow ar-label "
                            name="select-worksite"
                          >
                            {constants.SELECT_FACILITY}
                          </label>
                          <div className="col-8 ar-input">
                            <Dropdown
                              className="w-100"
                              id="select-facility"
                              value={selectedAdminFacility}
                              options={options}
                              onChange={onFacilityChange}
                              placeholder="Select Facility"
                              optionLabel="name"
                              filter
                              filterBy="name"
                              loading={worksiteLoading}
                              showClear
                              emptyFilterMessage="No data available"
                              emptyMessage={
                                worksiteLoading ? (
                                  <DropdownSpinner />
                                ) : (
                                  "No data available"
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 star-after bold-barlow ar-label"
                          htmlFor="userType"
                          name="userType"
                        >
                          Select User Type
                        </label>
                        <div className=" col-8 worksite-dropdown">
                          <MultiSelect
                            id="userType"
                            className="w-100"
                            value={values?.userType}
                            onChange={(e) => {
                              handlePersonaSelection(e.value);
                              setFieldValue("userType", e.value);
                            }}
                            options={capitalizedArray}
                            placeholder="Select user persona"
                          />
                          <ErrorMessage
                            name="userType"
                            component="div"
                            className="error-text"
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 bold-barlow ar-label"
                          htmlFor="client-portal-user"
                          name="client-portal-user"
                        >
                          Client Portal User
                        </label>
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 star-after bold-barlow ar-label"
                          htmlFor="addUser"
                          name="addUser"
                        >
                          Add user
                        </label>
                        <div className=" col-8 worksite-dropdown">
                          <Dropdown
                            className="w-100"
                            id="addUser"
                            name="addUser"
                            value={values.addUser}
                            options={Array.isArray(data) ? data : []}
                            onChange={(e) => {
                              setSelectedUser(e.value);
                              setFieldValue(
                                "addUser",
                                e.value === undefined ? null : e.value
                              );
                              handleUserChange(e.value, setFieldValue);
                            }}
                            optionLabel="fullName"
                            placeholder="Select a user"
                            filter
                            filterBy="fullName"
                            filterPlaceholder="Search"
                            emptyFilterMessage="No data available"
                            emptyMessage="No data available"
                            showClear
                          />
                          <ErrorMessage
                            name="addUser"
                            component="div"
                            className="error-text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ar-input-card">
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 bold-barlow ar-label"
                          htmlFor="userDetails"
                          name="userDetails"
                        >
                          User details
                        </label>
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 ar-label"
                          htmlFor="fullName"
                          name="fullName"
                        >
                          Full Name
                        </label>
                        <Field
                          readOnly
                          disabled
                          className="form-control col-8 ar-input"
                          name="fullName"
                          placeholder="John Doe"
                          id="fullName"
                        />
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 ar-label"
                          htmlFor="title"
                          name="title"
                        >
                          Title
                        </label>
                        <Field
                          readOnly
                          disabled
                          className="form-control col-8 ar-input"
                          name="title"
                          placeholder="(eg., HR, Hiring Manager, Medical Director)"
                          id="title"
                        />
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 ar-label"
                          htmlFor="phoneNumber"
                          name="phoneNumber"
                        >
                          Phone Number
                        </label>
                        <Field
                          readOnly
                          disabled
                          className="form-control col-8 ar-input"
                          name="phoneNumber"
                          placeholder="(123)987-6543"
                          id="phoneNumber"
                        />
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 ar-label"
                          htmlFor="email"
                          name="email"
                        >
                          Email
                        </label>
                        <Field
                          readOnly
                          disabled
                          className="form-control col-8 ar-input"
                          name="email"
                          placeholder="abc@xyz.com"
                          id="email"
                        />
                      </div>
                      <div className="row g-0 my-1">
                        <label
                          className="col-4 ar-label"
                          htmlFor="accountName"
                          name="accountName"
                        >
                          Account Name
                        </label>
                        <Field
                          readOnly
                          disabled
                          className="form-control col-8 ar-input"
                          name="accountName"
                          placeholder="Account Name"
                          id="accountName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ar-dpcontainer">
                    <p className="bold-barlow ar-permission-label">
                      Dashboard Permissions : Salesforce Embedded/Read-Only &
                      Tableau Access
                    </p>
                    <div className="ar-row">
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Candidates</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={candidates}
                            onChange={(e) => setCandidates(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Scheduling</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={scheduling}
                            onChange={(e) => setScheduling(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Spend Management</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={spendManagement}
                            onChange={(e) => setSpendManagement(e.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ar-row">
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Locums Analytics</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={locumsAnalytics}
                            onChange={(e) => setLocumsAnalytics(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Scheduled Report</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={scheduledReport}
                            onChange={(e) => setScheduledReport(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row g-0 my-1 ar-row-column">
                        <div className="col-6 ar-dp-label">
                          <p>Landing Page</p>
                        </div>
                        <div className="col-6 ar-switch-container">
                          <InputSwitch
                            className=" switch"
                            checked={landingPage}
                            onChange={(e) => setLandingPage(e.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <p className="bold-barlow ar-permission-label">
                      Manu Permissions : Salesforce Embedded/Read-Only & Tableau
                      Access
                    </p>

                    <div className="ar-row">
                      <div className="ar-row-column">
                        <div className="row g-0 my-1">
                          <div className="col-6 ar-dp-label">
                            <p>Schedule management</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={scheduleManagement}
                              onChange={(e) => setScheduleManagement(e.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ar-row-column">
                        <div className="row g-0 my-1">
                          <div className="col-6 ar-dp-label">
                            <p>Spend Management</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={spendManagementMenu}
                              onChange={(e) => setSpendManagementMenu(e.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex p-2 justify-content-end">
                    <RegularButton
                      className="me-2"
                      color={"#ffffff"}
                      backgroundColor={"#8e94a1"}
                      btnname={"Reset"}
                      onClick={() => {
                        resetForm();
                        resetSwitches();
                      }}
                    />
                    <RegularButton
                      color={"#424c63"}
                      backgroundColor={"#00e676"}
                      type={"submit"}
                      // onClick={successNavigate}
                      btnname={"Submit"}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
});
// PropTypes for the props passed to the component
AddFacilityUser.propTypes = {
  closeAddFacilityUser: PropTypes.func.isRequired,
  hospital: PropTypes.shape({
    account_name_fd: PropTypes.string,
  }),
  worksiteUsers: PropTypes.array.isRequired,
};
export default AddFacilityUser;
