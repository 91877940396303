import React, { useEffect, useState } from "react";
import "../../assets/css/EmailVerificationSuccess.scss";
import { ReactComponent as Tick } from "../../assets/images/icons/Tick.svg";
import { useLocation, useNavigate } from "react-router-dom";

function EmailConfirmation() {
  const [showToast, setShowToast] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;

  const showSuccessToast = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 8000);
  };

  useEffect(() => {
    showSuccessToast();
  }, []);

  return (
    <div className="flex-grow-1 overflow-auto email-success-container">
      <div className="w-100 d-flex align-items-center justify-content-center email-success-div">
        <div className="email-success-card">
          <div className="email-success-data">
            <p className="bold">Confirm Your Details</p>
            <div className="row g-0">
              <p className="col">Full Name</p>
              <p className="col bold">{data?.full_name || ""}</p>
            </div>
            <div className="row g-0">
              <p className="col">Job Title/Role</p>
              <p className="col bold">{data?.title || ""}</p>
            </div>
            <div className="row g-0">
              <p className="col">Email</p>
              <p className="col bold">{data?.email || ""}</p>
            </div>
            <div className="row g-0">
              <p className="col">Direct Phone Number</p>
              <p className="col bold">{data?.phone || ""}</p>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center">
            <button
              className="back-to-login bold mt-3"
              onClick={() => {
                navigate("/");
              }}
              tabIndex={0} // Makes the <p> element focusable via the Tab key
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  navigate("/"); // Navigate on Enter or Space key press
                }
              }}
              aria-label="Back to login" // Adds an accessible label for screen readers
            >
              Back
            </button>
          </div>
        </div>
        {showToast && (
          <div className="email-success-toast bold">
            <Tick className="me-2" /> Thank you for confirming your details
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailConfirmation;
