import React, { useState, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { ReactComponent as Gotoo } from "../../../assets/images/icons/Gotoo.svg";
import {
  fetchSpendDocuments,
  viewSpendPDFDocument,
  setActiveSPProfileNavLink,
} from "../../../redux/actions/spendManagementActions";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import "../../../assets/css/Documents.scss";
import { getFileIcon } from "../../../helpers/commonUtills";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { convertKBtoMB } from "../../../helpers/fileUtills";

function SpendDocuments() {
  const dispatch = useDispatch();

  // Ensure that documents is always an array (default to empty array if undefined)
  const { spendDocuments, loading } = useSelector(
    (state) => state.spendDocuments
  );
  // Set active navigation link on mount
  useEffect(() => {
    dispatch(setActiveSPProfileNavLink("documents"));
  }, [dispatch]);

  // Fetch documents on mount
  useEffect(() => {
    const confirmationNumber = sessionStorage.getItem("SpconfirmationNumber");
    const spendId = sessionStorage.getItem("SpId");
    if (confirmationNumber && spendId) {
      dispatch(fetchSpendDocuments(confirmationNumber, spendId));
    }
  }, [dispatch]);

  // Define and initialize filters
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileSize: { value: null, matchMode: FilterMatchMode.CONTAINS },
    uploadedDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    relatedTo: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleFilterChange = useCallback((e) => {
    setFilters(e.filters);
  }, []);

  // Function to open PDF in a new tab with iframe
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <CustomTooltip content={"View Report"}>
          <Gotoo
            className="action-icons"
            onClick={() => {
              // If file type is PDF, dispatch viewSpendPDFDocument
              dispatch(viewSpendPDFDocument(rowData?.Id, rowData?.fileType));
            }}
          />
        </CustomTooltip>
      </div>
    );
  };

  const iconBodyTemplate = (rowData) => {
    const icon = getFileIcon(rowData?.fileType);
    return icon ? (
      <img src={icon} alt={rowData?.fileType} width="30" height="30" />
    ) : (
      "-"
    );
  };
  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings ">Documents</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%", marginRight: "-0.3rem" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };
  const fileSizeTemplate = (rowData) => {
    const KbtoMb = convertKBtoMB(rowData.fileSize);
    return KbtoMb;
  };
  return (
    <div className="sc-documents-container flex-grow-1 px-3 overflow-auto">
      <DataTable
        value={
          Array.isArray(spendDocuments) && spendDocuments[0] !== null
            ? spendDocuments
            : []
        } // Always pass an array here
        loading={loading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        resizableColumns
        rows={20}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-documents"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          headerClassName="center-header"
          bodyStyle={{ textAlign: "center" }}
          header="File Type"
          body={iconBodyTemplate}
        />
        <Column
          className="column-bookingstatus"
          headerClassName="column-bookingstatus"
          header="File Name"
          field="fileName"
          body={(rowData) => (
            <span className="fileName-text">
              {rowData?.fileName ? rowData?.fileName : "-"}
            </span>
          )}
          filter
          filterField="fileName"
          filterPlaceholder="Search"
        />
        <Column
          className="column-firstname"
          headerClassName="column-firstname"
          header="File Size"
          field="fileSize"
          body={fileSizeTemplate}
        />
        <Column
          className="column-lastname"
          headerClassName="column-lastname"
          header="Uploaded Date"
          field="uploadedDate"
          body={(rowData) => (
            <span className="uploadedDate-text">
              {rowData?.uploadedDate ? rowData?.uploadedDate : "-"}
            </span>
          )}
          filter
          filterField="uploadedDate"
          filterPlaceholder="Search"
        />
        <Column
          header="Related to"
          field="relatedTo"
          style={{ width: "100rem" }}
          body={(rowData) => (
            <span className="relatedTo-text">
              {rowData?.relatedTo ? rowData?.relatedTo : "-"}
            </span>
          )}
          filter
          filterField="relatedTo"
          filterPlaceholder="Search"
        />
        <Column
          headerClassName="center-header"
          bodyStyle={{ textAlign: "center" }}
          header="Action"
          body={actionBodyTemplate}
        />
      </DataTable>
    </div>
  );
}

export default SpendDocuments;
