import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { ReactComponent as SpinnerIcon } from "../../assets/images/icons/Spinner.svg"; // Replace with the path to your SVG file

const DropdownSpinner = ({ size = 50 }) => {
  return (
    <div className="w-100 d-flex justify-content-center pe-4">
      <SpinnerIcon width={size} height={size} />
    </div>
  );
};

// Add prop validation
DropdownSpinner.propTypes = {
  size: PropTypes.number, // `size` must be a number
};

export default DropdownSpinner;
