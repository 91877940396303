import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { setActiveSCProfileNavLink } from "../../../redux/actions/schedulingActions";

import "../../../assets/css/ScInvoice.scss";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import constants from "../../../helpers/en";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { getSpendMtgInvoice } from "../../../redux/actions/spendMtgInvoiceAction";

function Invoice() {
  const dispatch = useDispatch();

  // Redux state selectors
  const { spendMtgInvoiceTimesheet, loading: isDataLoading } = useSelector(
    (state) => state.spendMtgInvoice
  );
  const selectedClient = useSelector((state) => state.selection.selectedClient);
  const facilities = useSelector((state) => state.facilityList.data);

  // Local state
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);

  // Fetch initial data
  useEffect(() => {
    dispatch(setActiveSCProfileNavLink("invoice"));
    const confirmationNumber = sessionStorage.getItem("confirmationNumber");
    if (confirmationNumber) {
      dispatch(
        getSpendMtgInvoice(constants.LIMIT, 0, "", "", confirmationNumber)
      );
    }
  }, [dispatch, selectedClient]);

  // Initialize filters
  const initializeFilters = useCallback(() => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiveName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dateCientInvoiceSent: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
      timesheetClientInvoiceTotal: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      expensesTotal: { value: null, matchMode: FilterMatchMode.CONTAINS },
      creditMemoTotal: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceTotalIncludingCreditMemo: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      ammountRecivableSpecialist: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters, facilities]);

  // Handlers
  const handleSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const handleFilterChange = (e) => {
    setFilters(e.filters);
  };

  const handlePageChange = (e) => {
    setFirst(e.first);
  };
  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };
  // Render header (if required)
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count" >
          <h4 className="profile-headings">Invoice</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%"}}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="sc-invoice-data flex-grow-1 overflow-auto">
      <DataTable
        className="table-responsive px-3"
        value={
          Array.isArray(spendMtgInvoiceTimesheet)
            ? spendMtgInvoiceTimesheet
            : []
        }
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
        onPage={handlePageChange}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-shifts"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="Invoice_Class"
          header="Invoice Date"
          field="invoiceDate"
          filter
          body={(rowData) => (
            <span className="invoiceDate-text">{rowData.invoiceDate}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Client_Class"
          header="Client Invoice"
          field="invoiveName"
          filter
          body={(rowData) => (
            <span className="invoiveName-text">{rowData.invoiveName}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Invoice_Class"
          header="Invoice Number"
          field="invoiceNumber"
          filter
          body={(rowData) => (
            <span className="invoiceNumber-text">{rowData.invoiceNumber}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Amount_Class"
          header="Amount"
          field="amount"
          filter
          body={(rowData) => (
            <span className="amount-text">{rowData.amount}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="dateCientInvoiceSent_Class"
          header="Date-Client Invoice Sent"
          field="dateCientInvoiceSent"
          filter
          body={(rowData) => (
            <span className="dateCientInvoiceSent-text">
              {rowData.dateCientInvoiceSent}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Facility_Class"
          header="Facility Name"
          field="facilityName"
          filter
          body={(rowData) => (
            <span className="facilityName-text">{rowData.facilityName}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Speciality_Class"
          header="Speciality"
          field="specialty"
          filter
          body={(rowData) => (
            <span className="specialty-text">{rowData.specialty}</span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Timesheet_Class"
          header="Timesheet Client Invoice Total"
          field="timesheetClientInvoiceTotal"
          filter
          body={(rowData) => (
            <span className="timesheetInvoiceTotal-text">
              {rowData.timesheetClientInvoiceTotal}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Expenses_Class"
          header="Expenses Total"
          field="expensesTotal"
          filter
          body={(rowData) => (
            <span className="Client-text">{rowData.expensesTotal}</span>
          )}
          filterPlaceholder="Search"
        />

        <Column
          className="Credit_Class"
          header="Credit Memo Total"
          field="creditMemoTotal"
          filter
          body={(rowData) => (
            <span className="creditMemoTotal-text">
              {rowData.creditMemoTotal}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="invoice_total_Class"
          header="Invoice Total-Including Credit Memos"
          field="invoiceTotalIncludingCreditMemo"
          filter
          body={(rowData) => (
            <span className="invoiceTotalIncludingCreditMemo-text">
              {rowData.invoiceTotalIncludingCreditMemo}
            </span>
          )}
          filterPlaceholder="Search"
        />

        <Column
          className="AR_Class"
          header="AR Specailist"
          field="ammountRecivableSpecialist"
          filter
          body={(rowData) => (
            <span className="ammountRecivableSpecialist-text">
              {rowData.ammountRecivableSpecialist}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="Confirmation_Class"
          header="Confirmation Number"
          field="confirmationNumber"
          filter
          body={(rowData) => (
            <span className="confirmationNumber-text">
              {rowData.confirmationNumber}
            </span>
          )}
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}

export default Invoice;
