import React, { useEffect } from "react";
import "../../../assets/css/ScProfile.scss";
import ProfileCards from "../../../components/Commons/ProfileCards";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSpendDocuments,
  fetchSpProfileById,
  setActiveSPProfileNavLink,
  viewSpendPDFDocument,
} from "../../../redux/actions/spendManagementActions";
import CustomSpinner from "../../../components/Commons/CustomSpinner";
import constants from "../../../helpers/en";

const SpendProfile = () => {
  const dispatch = useDispatch();
  const confirmationNum = sessionStorage.getItem("SpconfirmationNumber");
  const id = sessionStorage.getItem("SpId");

  // Redux Selectors
  const { data: profileList } = useSelector((state) => state.SpProfile);
  const { spendDocuments, loading } = useSelector(
    (state) => state.spendDocuments
  );

  // Fetch profile data

  useEffect(() => {
    if (confirmationNum && id) {
      dispatch(fetchSpProfileById(confirmationNum, id));
    }
  }, [dispatch]);

  // Set Active Navigation Link
  useEffect(() => {
    dispatch(setActiveSPProfileNavLink(""));
  }, [dispatch]);

  useEffect(() => {
    const confirmationNum = sessionStorage.getItem("SpconfirmationNumber");
    const id = sessionStorage.getItem("SpId");
    if (confirmationNum && id) {
      dispatch(fetchSpendDocuments(confirmationNum, id));
    }
  }, [dispatch]);

  const viewConfirmationLink = (event) => {
    event.preventDefault();
    dispatch(
      viewSpendPDFDocument(spendDocuments[0]?.Id, spendDocuments[0]?.fileType)
    );
  };
  const ConfirmationLink = () => {
    const link = getProfileValue("confirmationLink");
    if (link) {
      return (
        <a
          href={link}
          onClick={viewConfirmationLink}
          className={
            spendDocuments === constants.NO_RECORD_FOUND_MESSAGE
              ? "confirmationUnLink"
              : "confirmationLink"
          }
        >
          {spendDocuments === constants.NO_RECORD_FOUND_MESSAGE
            ? "-"
            : constants.LINK}
        </a>
      );
    } else {
      return <span>{constants.NO_FILE_AVAILABLE}</span>;
    }
  };

  // Utility function to safely get profile data
  const getProfileValue = (key) => profileList?.[0]?.[key] || "-";

  const renderRates = () => {
    const url = profileList?.[0]?.providerTimesheetURL || "";

    if (url && url.startsWith("http")) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {constants.LINK}
        </a>
      );
    }
    return "-";
  };

  // Profile Card Data
  const card1 = [
    { label: "Facility Name", value: profileList?.[0]?.facilityName || "-" },
    {
      label: "Facility City & State",
      value: profileList?.[0]?.facilityCityState || "-",
    },
    { label: "Client Name", value: profileList?.[0]?.clientName || "-" },
    {
      label: "Confirmation Letter Link",
      value: ConfirmationLink(),
      tag: "a",
    },
    { label: "Provider Name", value: profileList?.[0]?.providerName || "-" },
    {
      label: "Practitioner Type",
      value: profileList?.[0]?.practitionerType || "-",
    },
    { label: "Specialty", value: profileList?.[0]?.specialty || "-" },
  ];

  const card2 = [
    {
      label: "Payroll Contact",
      value: profileList?.[0]?.payrollContact || "-",
    },
    {
      label: "Client Consultant",
      value: profileList?.[0]?.clientConsultant || "-",
    },
    { label: "Client Email", value: profileList?.[0]?.clientEmail || "-" },
    {
      label: "Consultant Phone",
      value: profileList?.[0]?.consultantPhone || "-",
    },
  ];

  const card3 = [
    {
      label: "Provider Timesheet URL",
      value: renderRates(),
      tag: "a",
    },
    { label: "Pay Period", value: profileList?.[0]?.payPeriod || "-" },
    {
      label: "Actual Days Worked",
      value: profileList?.[0]?.actualDatesWorkedInPayPeriod || "-",
    },
    { label: "Total Hours​", value: profileList?.[0]?.totalHours || "-" },
    { label: "Total OT Hours​", value: profileList?.[0]?.totalOTHours || "-" },
    {
      label: "Approval Request Date",
      value: profileList?.[0]?.approvalRequestDate || "-",
    },
    {
      label: "Approval Status",
      value: profileList?.[0]?.approvalStatus || "-",
    },
    {
      label: "Approved Comments",
      value: profileList?.[0]?.approvedComments || "-",
    },
    {
      label: "Rejected Comments",
      value: profileList?.[0]?.rejectedComments || "-",
    },
  ];
  // Loading fallback
  if (!profileList || profileList.length === 0) {
    return (
      <div className="sc-profile-container flex-grow-1 overflow-auto">
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div className="sc-profile-container flex-grow-1 overflow-auto">
      <div className="sc-pr-inner-container">
        <p className="heading_name bold-barlow">
          {getProfileValue("facilityName")}
        </p>
        <p className="assign_Num">Assignment Number: {confirmationNum}</p>
        <div className="mt-2 d-flex w-100 flex-wrap">
          <div className="sc-card-container">
            <ProfileCards data={card1} />
          </div>
          <div className="sc-card-container">
            <ProfileCards data={card2} />
          </div>
          <div className="sc-card-container">
            <ProfileCards data={card3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendProfile;
