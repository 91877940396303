import React from "react";
import SpendManagementNav from "./SpendManagementNav";
import { Outlet } from "react-router-dom";

function SpendManagementProfile() {
  return (
    <div className="d-flex flex-column flex-grow-1 overflow-auto p-3 h-100">
      <SpendManagementNav />
      <Outlet />
    </div>
  );
}

export default SpendManagementProfile;
