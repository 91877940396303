import React, { memo, useEffect, useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/FacilityInfoPopup.scss";
import constants from "../../../helpers/en";
import PropTypes from "prop-types"; // Import PropTypes
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  fetchFacilityUserDBById,
} from "../../../redux/actions/facilityActions";
import EditFacilityUser from "./EditFacilityUser";
import ConfirmationDialog from "../../../components/Commons/ConfirmationDialog ";
import { HiOutlinePencil } from "react-icons/hi2";
import { indexTemplate } from "../../../utils";

const FacilityInfoPopup = memo((props) => {
  const isSubscribed = props.hospital?.tableau_subscription_ud;
  const dispatch = useDispatch();
  const id = props.hospital?.facility_account_no_fd;
  const [openEditUserPopup, setOpenEditUserPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [statusType, setStatusType] = useState(null);

  const facilityUserData = useSelector((state) => state.facilityUserDB.data);

  useEffect(() => {
    dispatch(fetchFacilityUserDBById(id));
  }, [dispatch, id]);

  const openEditFacilityUser = () => {
    setOpenEditUserPopup(true);
  };
  const closeEditFacilityUser = () => {
    setOpenEditUserPopup(false);
  };

  const showEditFacilityUser = (user) => {
    setSelectedUser(user);
    openEditFacilityUser();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <CustomTooltip content={"Edit"}>
          <Edit
            className="action-icons"
            onClick={() => showEditFacilityUser(rowData)}
          />
        </CustomTooltip>
      </div>
    );
  };

  const renderLocationValue = () => {
    return (
      <div>
        {props.hospital?.city_fd ? props.hospital?.city_fd : "-"} :{" "}
        {props.hospital?.account_name_fd
          ? props.hospital?.account_name_fd
          : "-"}
      </div>
    );
  };

  const capitalizeAndJoin = (rowData) => {
    return rowData.personaData
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(", ");
  };

  const showConfirmDialog = (status, userId) => {
    setStatusType(status);
    setUserId(userId);
    setShowDialog(true);
  };
  const handleConfirm = (statusType, userId) => {
    dispatch(activateUser(statusType, userId, id));
    setShowDialog(false); // Close the dialog after confirmation
  };
  const handleCancel = () => {
    setShowDialog(false); // Close the dialog after rejection
  };
  // Define the dynamic class for each row's status
  const getStatusClass = (status) => {
    return status ? "active-status" : "inactive-status"; // Return class based on status
  };

  return (
    <div className="modal-overlay-facilityInfo">
      <ConfirmationDialog
        showDialog={showDialog}
        onConfirm={(status) => handleConfirm(statusType, userId)} // Pass status and id dynamically
        onCancel={handleCancel}
        status={statusType}
        name={"User"}
      />
      <div className="d-flex w-100 justify-content-center">
        <div className="modal-container-facilityInfo">
          <div className="d-flex justify-content-between align-items-center px-2">
            <h2 className="popup-head-text">
              {props.hospital?.account_name_fd.toUpperCase()}
            </h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={props.closeFacilityInfo}
              />
            </div>
          </div>
          <div className="white-container">
            <p className="main-text bold">{constants.FACILITY_INFORMATION}</p>
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="row">
                  <p className="col-4 ">{constants.ACCOUNT_NAME}</p>
                  <p className="w-52  col-8">
                    {props.hospital?.account_name_fd
                      ? props.hospital?.account_name_fd
                      : "--"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4 ">{constants.ACCOUNT_STATUS}</p>
                  <p className="w-50  col-8">
                    {props.hospital?.account_status_fd
                      ? props.hospital?.account_status_fd
                      : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.ADDRESS}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.street_fd
                      ? props.hospital?.street_fd
                      : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4"></p>
                  <p className="w-50 col-8">
                    {props.hospital?.city_fd ? props.hospital?.city_fd : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4"></p>
                  <p className="w-50 col-8">
                    {props.hospital?.state_fd ? props.hospital?.state_fd : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4"> </p>
                  <p className="w-50  col-8">
                    {props.hospital?.zip_code_fd
                      ? props.hospital?.zip_code_fd
                      : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4 "></p>
                  <p className="w-50 col-8">
                    {props.hospital?.country_fd
                      ? props.hospital?.country_fd
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <p className="col-4">{constants.ACCOUNT_NUMBER}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.facility_account_no_fd
                      ? props.hospital?.facility_account_no_fd
                      : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.TYPE}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.specialities_fd
                      ? props.hospital?.specialities_fd
                      : "-"}
                  </p>
                </div>
              </div>
            </div>

            <hr className="dotted-line-facilityInfo" />

            {/* Tableau Subscription Toggle with TRUE/FALSE inside the button */}

            <div className="row w-50 g-0 ">
              <label
                htmlFor="tableau-subscription"
                className="col-4 bold-barlow m-0"
              >
                {constants.TABLEAU_SUBSCRIPTION}
              </label>
              <div className="col-12 col-md-6 mb-3 d-flex">
                <InputSwitch checked={isSubscribed} />
                <span className="ms-2 main-text tableau-span">
                  {isSubscribed ? "True" : "False"}
                </span>
              </div>
            </div>

            <p className="main-text bold">{constants.FACILITY_ADMIN}</p>
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="row px-2 px-md-0">
                  <p className="col-4 bold">{constants.SELECT_ADMIN}</p>
                  <p className="w-50 mb-1 col-8">
                    {props.hospital?.full_name_ud
                      ? props.hospital?.full_name_ud
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <p className="col-4">{constants.FULL_NAME}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.full_name_ud
                      ? props.hospital?.full_name_ud
                      : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.TITLE}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.title_ud ? props.hospital?.title_ud : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.PHONE_NUMBER}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.phone_ud ? props.hospital?.phone_ud : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.EMAIL}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.email_ud ? props.hospital?.email_ud : "-"}
                  </p>
                </div>
                <div className="row">
                  <p className="col-4">{constants.ACCOUNT_NAME}</p>
                  <p className="w-50 col-8">
                    {props.hospital?.account_name_ud
                      ? props.hospital?.account_name_ud
                      : props.hospital?.account_name_fd}
                  </p>
                </div>
              </div>
            </div>
            <hr className="dotted-line-facilityInfo" />
            <p className="main-text bold">3. Facility User Details</p>
            <div className="user-table">
              <DataTable
                className="table-responsive"
                value={Array.isArray(facilityUserData) ? facilityUserData : []}
                emptyMessage={
                  <div className="d-flex justify-content-center ">
                    No user found.
                  </div>
                }
              >
                <Column
                  body={indexTemplate}
                  header="Sr.No."
                  headerClassName="center-header"
                  bodyStyle={{ textAlign: "center" }}
                />
                <Column field="full_name" header="Name" />
                <Column
                  body={capitalizeAndJoin}
                  header="User Type"
                  style={{ width: "20rem" }}
                />
                <Column body={renderLocationValue} header="Location" />
                <Column
                  field="is_active"
                  header="Status"
                  headerClassName="center-header"
                  style={{ width: "8rem" }}
                  bodyStyle={{ textAlign: "center" }}
                  body={(rowData) => (
                    <span
                      className={`ellipsis-text-status ${getStatusClass(
                        rowData.is_active
                      )}`}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        showConfirmDialog(rowData.is_active, rowData.user_id);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault(); // Prevent scrolling for the space key
                          showConfirmDialog(rowData.is_active, rowData.user_id);
                        }
                      }}
                    >
                      {rowData.is_active ? "Active" : "Inactive"}
                      <i className={` ${getStatusClass(rowData.is_active)}`}>
                        <HiOutlinePencil />
                      </i>
                    </span>
                  )}
                />
                <Column
                  headerClassName="center-header"
                  bodyStyle={{ textAlign: "center" }}
                  body={actionBodyTemplate}
                  header="Action"
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      {openEditUserPopup && (
        <EditFacilityUser
          id={id}
          user={selectedUser}
          closeEditFacilityUser={closeEditFacilityUser}
        />
      )}
    </div>
  );
});
// PropTypes validation
FacilityInfoPopup.propTypes = {
  hospital: PropTypes.shape({
    account_name_fd: PropTypes.string,
    account_status_fd: PropTypes.string,
    street_fd: PropTypes.string,
    city_fd: PropTypes.string,
    state_fd: PropTypes.string,
    zip_code_fd: PropTypes.string,
    country_fd: PropTypes.string,
    facility_account_no_fd: PropTypes.string,
    definitive_id_fd: PropTypes.string,
    website_fd: PropTypes.string,
    specialities_fd: PropTypes.string,
    full_name_ud: PropTypes.string,
    role_ud: PropTypes.string,
    phone_ud: PropTypes.string,
    email_ud: PropTypes.string,
    account_name_ud: PropTypes.string,
    title_ud: PropTypes.string,
    tableau_subscription_ud: PropTypes.bool,
  }),
  closeFacilityInfo: PropTypes.func.isRequired,
};
export default FacilityInfoPopup;
