import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_TYPES } from "../../../helpers/enums";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import "../../../assets/css/SpendMtgTimesheet.scss";
import { ReactComponent as Download } from "../../../assets/images/icons/Download.svg";
import { ReactComponent as Print } from "../../../assets/images/icons/Print.svg";
import { useLocation, useNavigate } from "react-router";
import constants from "../../../helpers/en";
import { Dropdown } from "primereact/dropdown";
import {
  getSFWorksiteList,
  getFacilityDBList,
} from "../../../redux/actions/facilityActions";
import {
  setSelectedClient,
  setSelectedClientId,
  setSelectedFacility,
  setSelectedFacilityId,
} from "../../../redux/actions/dashboardListActions";
import { getSpendManagement } from "../../../redux/actions/spendManagementActions";
import DropdownSpinner from "../../../components/Commons/DrpodownSpinner";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { exportToExcel } from "../../../helpers/fileUtills";

function SpendMgtTimesheetMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const segment = location.pathname.split("/").pop();
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );
  const { spendManagementTimesheet, loading: isDataLoading } = useSelector(
    (state) => state.spendManagement
  );
  const { data, loading } = useSelector((state) => state.facilityList);
  const { worksiteData, loading: worksiteLoading } = useSelector(
    (state) => state.worksite
  );
  const selectedClient = useSelector((state) => state.selection.selectedClient);
  const selectedFacility = useSelector(
    (state) => state.selection.selectedFacility
  );

  // Local state
  const [first, setFirst] = useState(0);
  const [spendTimesheetData, setSpendTimesheetData] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    timesheetName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approvalFile: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approvalDueDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    approvalStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
    rejectionNotes: { value: null, matchMode: FilterMatchMode.CONTAINS },
    timesheetURL: { value: null, matchMode: FilterMatchMode.CONTAINS },
    facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
    clientInvoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
    clientInvoicePeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    payrollContact: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
      timesheetName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalFile: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalDueDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      approvalStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
      rejectionNotes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      timesheetURL: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientInvoice: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientInvoicePeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
      payrollContact: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }));
  }, []);
  // Fetch initial data
  const handlePrint = () => {
    // window.print();
  };
  const spendTimesheetList = Array.isArray(spendTimesheetData)
    ? spendTimesheetData
    : [];
  const spendData = spendTimesheetList.map((item, index) => [
    index + 1,
    item?.timesheetName,
    item?.approvalFile,
    item?.approvalDueDate,
    item?.approvalStatus,
    item?.rejectionNotes,
    item?.timesheetURL,
    item?.facilityName,
    item?.specialty,
    item?.clientInvoice,
    item?.clientInvoicePeriod,
    item?.payrollContact,
  ]);
  const spendTimesheetHeaders = [
    "S.No",
    "Timesheet Name",
    "Approval File",
    "Approval Due Date",
    "Approval Status",
    "Rejection Notes",
    "Timesheet URL",
    "Facility Name",
    "Speciality",
    "Client Invoice",
    "Pay Period",
    "	Payroll Contact",
  ];
  const fileName = "SpendManagement" + "-" + "Timesheets";
  const downLoadExcelFormat = () => {
    if (segment === "spend-management") {
      if (spendData && spendData.length > 0) {
        exportToExcel(spendData, spendTimesheetHeaders, fileName);
      }
    }
  };

  useEffect(() => {
    if (
      rolePermission.role === USER_TYPES.FACILITY_ADMIN ||
      rolePermission.role === USER_TYPES.FACILITY_USER
    ) {
      const facilityAccountNo = rolePermission.userInfo?.facility_account_no;

      if (facilityAccountNo) {
        dispatch(getSFWorksiteList("", facilityAccountNo, 0, constants.LIMIT));
      }
    } else if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      const accountNo = selectedClient?.facility_account_no_fd;
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    } else {
      dispatch(getFacilityDBList());
      if (selectedClient) {
        const accountNo = selectedClient?.facility_account_no_fd;
        dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
      }
    }
  }, [dispatch, selectedClient, rolePermission.role]);

  useEffect(() => {
    const accountNo = selectedClient?.facility_account_no_fd;
    if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      if (!accountNo) {
        setSpendTimesheetData([]);
      } else {
        const validSpendManagementTimesheet = Array.isArray(
          spendManagementTimesheet
        )
          ? spendManagementTimesheet
          : [];

        const filteredData = validSpendManagementTimesheet.filter(
          (item) => item !== null
        );

        setSpendTimesheetData(filteredData.length === 0 ? [] : filteredData);
      }
    } else {
      const validSpendManagementTimesheet = Array.isArray(
        spendManagementTimesheet
      )
        ? spendManagementTimesheet
        : [];

      const filteredData = validSpendManagementTimesheet.filter(
        (item) => item !== null
      );

      setSpendTimesheetData(filteredData.length === 0 ? [] : filteredData);
    }
  }, [
    spendManagementTimesheet,
    selectedClient?.facility_account_no_fd,
    rolePermission.role,
  ]);

  useEffect(() => {
    const accountNo = selectedClient?.facility_account_no_fd;
    const facilityAccountNo = rolePermission.userInfo?.facility_account_no;
    const facilityAccountNumber = selectedFacility?.accountNumber;

    // Logic for FACILITY_ADMIN and FACILITY_USER is the same
    if (
      [USER_TYPES.FACILITY_ADMIN, USER_TYPES.FACILITY_USER].includes(
        rolePermission.role
      )
    ) {
      if (facilityAccountNo && !facilityAccountNumber) {
        dispatch(getSpendManagement(constants.LIMIT, 0, "", facilityAccountNo));
      }

      if (facilityAccountNo && facilityAccountNumber) {
        dispatch(
          getSpendManagement(
            constants.LIMIT,
            0,
            "",
            facilityAccountNo,
            facilityAccountNumber
          )
        );
      }
    } else if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
      // SUPER_ADMIN or default role logic
      if (accountNo && !facilityAccountNumber) {
        dispatch(getSpendManagement(constants.LIMIT, 0, "", accountNo));
      }

      if (accountNo && facilityAccountNumber) {
        dispatch(
          getSpendManagement(
            constants.LIMIT,
            0,
            "",
            accountNo,
            facilityAccountNumber
          )
        );
      }

      if (!accountNo) {
        dispatch(getSpendManagement());
        setSpendTimesheetData([]); // Reset data if no account number
      }
    }
  }, [dispatch, selectedClient, selectedFacility, rolePermission.role]);

  const onClientChange = (e) => {
    const selectedClient = e.value;
    dispatch(setSelectedClient(selectedClient));
    dispatch(setSelectedClientId(selectedClient?.facility_account_no_fd));
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const onFacilityChange = (e) => {
    const selectedFacility = e.value;
    dispatch(setSelectedFacility(selectedFacility));
    dispatch(setSelectedFacilityId(selectedFacility?.accountNumber));
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    }));

    if (!selectedFacility?.accountNumber) {
      setSpendTimesheetData([]);
    }
  };

  const onPage = (event) => {
    setFirst(event.first);
  };

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings">Timesheet</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <div className="sp-switches">
            <CustomTooltip content={"Print"}>
              <Print
                onClick={handlePrint}
                className="sp-icons"
                style={{ marginRight: "1.5rem" }}
              />
            </CustomTooltip>

            <CustomTooltip content={"Download"}>
              <Download
                className="sp-icons"
                onClick={downLoadExcelFormat}
                style={{ marginRight: "1.5rem" }}
              />
            </CustomTooltip>
          </div>
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              value={filters.global.value}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const handleClick = (id, confirmationNumber) => {
    if (id && confirmationNumber) {
      sessionStorage.setItem("SpconfirmationNumber", confirmationNumber);
      sessionStorage.setItem("SpId", id);
    } else {
      console.error("Error: Missing id or confirmationNumber");
    }
    navigate("spendManagement-profile");
  };

  return (
    <div className="sp-timesheet-container flex-grow-1 overflow-auto">
      {rolePermission.role === USER_TYPES.SUPER_ADMIN && (
        <div className="py-2 d-flex dashboard-pages-head-div w-100 row g-0">
          <div className="d-flex align-items-center dp-heading-input col-md-3">
            <label htmlFor="select-client" className="bold-barlow mx-1">
              {constants.SELECT_HOSPITAL}
            </label>
            <Dropdown
              className="mx-1"
              id="select-client"
              value={selectedClient}
              options={loading ? [] : data}
              onChange={onClientChange}
              placeholder="Select Client"
              optionLabel="account_name_fd"
              filter
              filterBy="account_name_fd"
              showClear
              loading={loading}
              emptyFilterMessage="No data available"
              emptyMessage={loading ? <DropdownSpinner /> : "No data available"}
            />
          </div>
          <div className="d-flex align-items-center dp-heading-input col-md-3">
            <label htmlFor="select-facility" className="bold-barlow mx-1">
              {constants.SELECT_WPRKSITE}
            </label>
            <Dropdown
              className="mx-1"
              id="select-facility"
              value={selectedFacility}
              options={worksiteLoading ? [] : worksiteData?.result || []}
              onChange={onFacilityChange}
              placeholder="Select Facility"
              optionLabel="name"
              filter
              filterBy="name"
              loading={worksiteLoading}
              showClear
              emptyFilterMessage="No data available"
              emptyMessage={
                worksiteLoading ? <DropdownSpinner /> : "No data available"
              }
            />
          </div>
        </div>
      )}
      {(rolePermission.role === USER_TYPES.FACILITY_ADMIN ||
        rolePermission.role === USER_TYPES.FACILITY_USER) && (
        <div className="py-2 d-flex dashboard-pages-head-div w-100 row g-0">
          {rolePermission.role === USER_TYPES.FACILITY_ADMIN && (
            <div className="d-flex align-items-center dp-heading-input col-md-3">
              <label htmlFor="select-facility" className="bold-barlow mx-1">
                {constants.SELECT_WPRKSITE}
              </label>
              <Dropdown
                className="mx-1"
                id="select-facility"
                value={selectedFacility}
                options={worksiteLoading ? [] : worksiteData?.result || []}
                onChange={onFacilityChange}
                placeholder="Select Facility"
                optionLabel="name"
                filter
                filterBy="name"
                loading={worksiteLoading}
                showClear
                emptyFilterMessage="No data available"
                emptyMessage={
                  worksiteLoading ? <DropdownSpinner /> : "No data available"
                }
              />
            </div>
          )}
        </div>
      )}
      <DataTable
        className="table-responsive px-3"
        value={Array.isArray(spendTimesheetData) ? spendTimesheetData : []}
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        onPage={onPage}
        onFilter={(e) => setFilters(e.filters)}
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-demo-local"
        emptyMessage={
          spendTimesheetData.length === 0 ? (
            <div className="d-flex justify-content-center">
              No data available.
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              No valid data available.
            </div>
          )
        }
      >
        <Column
          className="Timeshet_Num"
          header="Timesheet Name"
          field="timesheetName"
          filter
          filterField="timesheetName"
          body={(rowData) => (
            <span className="timesheet-text">
              <a
                role="button"
                tabIndex={0}
                onClick={() =>
                  handleClick(rowData.Id, rowData.confirmationNumber)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    e.preventDefault(); // Prevent scrolling when pressing the space key
                    handleClick(rowData.Id, rowData.confirmationNumber);
                  }
                }}
              >
                {rowData.timesheetName}
              </a>
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="approval_File"
          header="Approval File"
          field="approvalFile"
          body={(rowData) => (
            <span className="approvalFile-text">
              {rowData.approvalFile ? rowData.approvalFile : "-"}
            </span>
          )}
          filter
          filterField="approvalFile"
          filterPlaceholder="Search"
        />
        <Column
          className="approval_clss"
          header="Approval Due Date"
          field="approvalDueDate"
          body={(rowData) => (
            <span className="approvalDueDate-text">
              {rowData.approvalDueDate ? rowData.approvalDueDate : "-"}
            </span>
          )}
          filter
          filterField="approvalDueDate"
          filterPlaceholder="Search"
        />
        <Column
          className="approvalstatus_clss"
          header="Approval Status"
          field="approvalStatus"
          body={(rowData) => (
            <span className="approvalStatus-text">
              {rowData.approvalStatus ? rowData.approvalStatus : "-"}
            </span>
          )}
          filter
          filterField="approvalStatus"
          filterPlaceholder="Search"
        />
        <Column
          className="rejection_clss"
          header="Rejection Notes"
          field="rejectionNotes"
          body={(rowData) => (
            <span className="Rejection-text">
              {rowData.rejectionNotes ? rowData.rejectionNotes : "-"}
            </span>
          )}
          filter
          filterField="rejectionNotes"
          filterPlaceholder="Search"
        />
        <Column
          className="timesheet_clss"
          header="Timesheet URL"
          field="timesheetURL"
          headerClassName="timesheet-header"
          body={(rowData) => {
            // Extract the URL from the HTML string
            const urlMatch = rowData.timesheetURL?.match(/href="([^"]+)"/);
            const url = urlMatch ? urlMatch[1] : "";

            return (
              <div className="ellipsis-text" style={{ width: "8rem" }}>
                {url ? (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    Timesheet Link
                  </a>
                ) : (
                  <div className="no-pointer" style={{ width: "8rem" }}>
                    -
                  </div>
                )}
              </div>
            );
          }}
        />

        <Column
          className="facility_class"
          header="Facility Name"
          field="facilityName"
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filter
          filterField="facilityName"
          filterPlaceholder="Search"
        />
        <Column
          className="specialty_clss"
          header="Speciality"
          field="specialty"
          body={(rowData) => (
            <span className="specialty-text">
              {rowData.specialty ? rowData.specialty : "-"}
            </span>
          )}
          filter
          filterField="specialty"
          filterPlaceholder="Search"
        />
        <Column
          className="clientInvoice_class"
          header="Client Invoice"
          field="clientInvoice"
          body={(rowData) => (
            <span className="clientInvoice-text">
              {rowData.clientInvoice ? rowData.clientInvoice : "-"}
            </span>
          )}
          filter
          filterField="clientInvoice"
          filterPlaceholder="Search"
        />

        <Column
          className="Pay Period_clss"
          header="Pay Period"
          field="clientInvoicePeriod"
          body={(rowData) => (
            <span className="clientInvoicePeriod-text">
              {rowData.clientInvoicePeriod ? rowData.clientInvoicePeriod : "-"}
            </span>
          )}
          filter
          filterField="clientInvoicePeriod"
          filterPlaceholder="Search"
        />
        <Column
          className="payrollContact_class"
          header="Payroll Contact"
          field="payrollContact"
          body={(rowData) => (
            <span className="payrollContact-text">
              {rowData.payrollContact ? rowData.payrollContact : "-"}
            </span>
          )}
          filter
          filterField="payrollContact"
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}
export default SpendMgtTimesheetMain;
