import * as XLSX from "xlsx"; // Import the xlsx library
import { saveAs } from "file-saver";

 // Clean up base64 string to remove any unwanted characters (newlines, spaces, etc.)
  export const cleanBase64 = (base64Data) => {
    if (typeof base64Data !== "string") {
      console.error("The provided base64 data is not a string.");
      return "";
    }
    return base64Data.replace(/[^A-Za-z0-9+/=]/g, "");
  };

   // Function to convert base64 to PDF
  export const convertBase64ToPDF = (base64Data) => {
     try {
      if (typeof base64Data !== "string") {
        console.error("base64Data is not a valid string:", base64Data);
        return;
      }

      const cleanedBase64Data = cleanBase64(base64Data);
  
      if (!/^[A-Za-z0-9+/=]+$/.test(cleanedBase64Data)) {
        throw new Error("Invalid base64 format");
      }

      const byteCharacters = atob(cleanedBase64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/pdf" });

      const url = URL.createObjectURL(blob);
       openPdfInNewTab(url);
     } catch (error) {
      console.error("Error decoding base64:", error.message);
    }
  };

  //Function to open PDF in a new tab with iframe
  export const openPdfInNewTab = (pdfUrl) => {
    if (pdfUrl) {
      const newTab = window.open(); // Open a new tab
      newTab.document.write(`
        <html>
          <head>
            <title>PDF Viewer</title>
          </head>
          <body>
            <iframe src="${pdfUrl}" width="100%" height="100%" style="border: none;"></iframe>
          </body>
        </html>
      `); // Inject the iframe with the PDF URL into the new tab
      newTab.document.close(); // Close the document to render the content
    } else {
      console.error("PDF URL is not available yet.");
    }
  };
  export const downloadExcel = (documents) => {
    const ws = XLSX.utils.json_to_sheet(documents); // Convert JSON data to sheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Append sheet to workbook
    // Trigger the download of the Excel file
    XLSX.writeFile(wb, "table-data.xlsx"); // Specify the filename for download
  };

  export const exportToExcel = (data,headers,fileName) => {
    const excelData = [headers, ...data];
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      worksheet["!cols"] = [
      { wch: 5 },  
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 },
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 }, 
      { wch: 20 } 

    ];
    worksheet["!rows"] = [
      { hpx: 20 },  
      { hpx: 15 },  
      { hpx: 15 }, 
      { hpx: 15 },  
      { wch: 10 },  
      { wch: 15 },  
      { wch: 10 },  
      { wch: 30 },  

    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
     const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
     const fileBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    const customFileName = `${fileName}.xlsx`;
    saveAs(fileBlob, customFileName);
  };
// Function to open DOCX file in a new tab
export const openBase64InToDOCX = (base64Data) => {
  const byteCharacters = atob(base64Data); // Decode base64 to byte characters
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  const url = URL.createObjectURL(blob);
   // Open the new tab with the Blob URL
  const newTab = window.open(); // Open a new tab
  newTab.document.write(`
    <html>
      <head>
        <title>DOCX Document</title>
      </head>
      <body>
        <iframe src="${url}" width="100%" height="100%" style="border: none;"></iframe>
      </body>
    </html>
  `); // Inject the iframe with the Blob URL into the new tab
  newTab.document.close(); // Close the document to render the content
};

export const convertKBtoMB = (kb) => {
  return `${(kb / 1024).toFixed(2)} MB`; // Convert KB to MB and append "MB"
}