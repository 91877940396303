import React, { useState, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { ReactComponent as Gotoo } from "../../../assets/images/icons/Gotoo.svg";
import {
  setActiveSPProfileIvNavLink,
  viewSpendPDFDocument,
} from "../../../redux/actions/spendManagementActions";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import "../../../assets/css/InvDocuments.scss";
import { getFileIcon } from "../../../helpers/commonUtills";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { fetchSpInvDocuments } from "../../../redux/actions/spendMtgInvoiceAction";
import { convertKBtoMB } from "../../../helpers/fileUtills";

const InvDocuments = () => {
  const dispatch = useDispatch();

  const id = sessionStorage?.getItem("Id");

  // Ensure that documents is always an array (default to empty array if undefined)
  const documentData = useSelector((state) => state.spIvDocument?.data || []);
  const documentLoading = useSelector((state) => state.spIvDocument?.loading);

  // Set active navigation link on mount
  useEffect(() => {
    dispatch(setActiveSPProfileIvNavLink("documents"));
  }, [dispatch]);

  // Fetch documents on mount
  useEffect(() => {
    dispatch(fetchSpInvDocuments(id));
  }, [dispatch]);

  // Define and initialize filters
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    fileSize: { value: null, matchMode: FilterMatchMode.CONTAINS },
    uploadedDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
    relatedTo: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const handleFilterChange = useCallback((e) => {
    setFilters(e.filters);
  }, []);

  // Function to open PDF in a new tab with iframe
  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <CustomTooltip content={"View"}>
          <Gotoo
            className="action-icons"
            onClick={() => {
              // If file type is PDF, dispatch viewSpendPDFDocument
              dispatch(viewSpendPDFDocument(rowData?.Id, rowData.fileType));
            }}
          />
        </CustomTooltip>
      </div>
    );
  };

  const iconBodyTemplate = (rowData) => {
    const icon = getFileIcon(rowData?.fileType);
    return icon ? (
      <img src={icon} alt={rowData?.fileType} width="30" height="30" />
    ) : null;
  };
  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };
  const fileSizeTemplate = (rowData) => {
    const KbtoMb = convertKBtoMB(rowData.fileSize);
    return KbtoMb;
  };
  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings ">Documents</h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="inv-documents-container flex-grow-1 overflow-auto">
      <DataTable
        className="table-responsive px-3"
        value={
          Array.isArray(documentData) && documentData[0] !== null
            ? documentData
            : []
        } // Always pass an array here
        stripedRows
        loading={documentLoading}
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        resizableColumns
        rows={20}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-documents"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="column-confirmationnumber"
          headerClassName="column-confirmationnumber"
          header="File Type"
          body={iconBodyTemplate}
        />
        <Column
          className="column-bookingstatus"
          headerClassName="column-bookingstatus"
          header="File Name"
          field="fileName"
          filter
          filterField="fileName"
          filterPlaceholder="Search"
        />
        <Column
          className="column-firstname"
          headerClassName="column-firstname"
          header="File Size"
          body={fileSizeTemplate}
        />
        <Column
          className="column-lastname"
          headerClassName="column-lastname"
          header="Uploaded Date"
          field="uploadedDate"
          filter
          filterField="uploadedDate"
          filterPlaceholder="Search"
        />
        <Column
          className="column-clientname"
          headerClassName="column-clientname"
          header="Related to"
          field="relatedTo"
          filter
          filterField="relatedTo"
          filterPlaceholder="Search"
        />
        <Column
          className="action-column"
          headerClassName="action-header"
          header="Action"
          body={actionBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default InvDocuments;
