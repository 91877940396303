import React, { useEffect } from "react";
import "../../../assets/css/Calendar.scss";
import { useDispatch } from "react-redux";
import { setActiveSCProfileNavLink } from "../../../redux/actions/schedulingActions";

function Calendar() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveSCProfileNavLink("calnedar"));
  }, []);
  return (
    <div className="sc-calendar-container d-flex align-items-center justify-content-center flex-grow-1 overflow-auto">
      CALENDAR : UNDER DEVELOPMENT
    </div>
  );
}

export default Calendar;
