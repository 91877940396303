import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import "../../../assets/css/InvTimeEntries.scss";
import "../../../assets/css/primeReactTheme.scss";
import { setActiveSPProfileIvNavLink } from "../../../redux/actions/spendManagementActions";
import { invoiceTimeEntries } from "../../../redux/actions/spendMtgInvoiceAction";
import constants from "../../../helpers/en";

function InvTimeEntries() {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const [first, setFirst] = useState(0);

  const invTimeEntries = useSelector(
    (state) => state.invTimeEntries.invTimeEntries
  );
  const isDataLoading = useSelector((state) => state.invTimeEntries.loading);
  const facilities = useSelector((state) => state.facilityList.data);
  const confirmationNumber = sessionStorage.getItem("confirmationNumber");
  const id = sessionStorage.getItem("Id");
  const invoiceNo = sessionStorage.getItem("invoiceNo");
  const invoiceDate = sessionStorage.getItem("invoiceDate");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  };

  const formattedDate = id ? formatDate(invoiceDate) : "";

  useEffect(() => {
    dispatch(setActiveSPProfileIvNavLink("time-entries"));
  }, [dispatch]);

  useEffect(() => {
    if (confirmationNumber && id) {
      dispatch(invoiceTimeEntries(constants.LIMIT, 0, confirmationNumber, id));
    }
  }, [dispatch, confirmationNumber, id]);

  const handleFilterChange = (e) => {
    setFilters(e.filters);
  };

  const handlePageChange = (e) => {
    setFirst(e.first);
  };

  const initializeFilters = useCallback(() => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      shiftType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
      providerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
      invoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters, facilities]);

  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="heading_name">
          <p className="locums-confirmation-header bold-barlow">{invoiceNo}</p>
          <p className="invoice_date">Invoice Date: {formattedDate}</p>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="inv-te-container flex-grow-1 overflow-auto ">
      <DataTable
        className="table-responsive px-3"
        value={
          Array.isArray(invTimeEntries) && invTimeEntries[0] !== null
            ? invTimeEntries
            : []
        } // Always pass an array here
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        onPage={handlePageChange}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-shifts"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="client_class"
          header="Shift Type"
          field="shiftType"
          body={(rowData) => (
            <span className="shiftType-text">
              {rowData.shiftType ? rowData.shiftType : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="First_class"
          header="Description"
          field="description"
          body={(rowData) => (
            <span className="description-text">
              {rowData.description ? rowData.description : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Last_class"
          header="Amount"
          field="amount"
          body={(rowData) => (
            <span className="amount-text">
              {rowData.amount ? rowData.amount : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />

        <Column
          className="Practitioner_class"
          header="Provider Name"
          field="providerName"
          body={(rowData) => (
            <span className="providerName-text">
              {rowData.providerName ? rowData.providerName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />

        <Column
          className="Date_class"
          header="Facility Name"
          field="facilityName"
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Beeper_class"
          header="Client Name"
          field="clientName"
          body={(rowData) => (
            <span className="clientName-text">
              {rowData.clientName ? rowData.clientName : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="Beeper_class"
          header="Invoice Date"
          field="invoiceDate"
          body={(rowData) => (
            <span className="invoiceDate-text">
              {rowData.invoiceDate ? rowData.invoiceDate : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />

        <Column
          className="End_class"
          header="Invoice Number"
          field="invoiceNumber"
          body={(rowData) => (
            <span className="invoiceNumber-text">
              {rowData.invoiceNumber ? rowData.invoiceNumber : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
        <Column
          className="End_class"
          header="Status"
          field="status"
          body={(rowData) => (
            <span className="status-text">
              {rowData.status ? rowData.status : "-"}
            </span>
          )}
          filter
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}

export default InvTimeEntries;
