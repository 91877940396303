import React, { memo } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/AddNewUser.scss";
import "../../../assets/css/InputSwitch.scss";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types"; // Import PropTypes
import constants from "../../../helpers/en";

const ViewFacilityUser = memo((props) => {
  const candidates = props.user?.candidates;
  const scheduling = props.user?.scheduling;
  const spendManagement = props.user?.spend_management_dashboard;
  const locumsAnalytics = props.user?.locums_analytics;
  const scheduledReport = props.user?.scheduled_report;
  const landingPage = props.user?.landing_page;
  const scheduleManagement = props.user?.schedule_management;
  const spendManagementMenu = props.user?.spend_management_menu;
  const originalArray = props.user?.personaData;
  const capitalizedPropArray = originalArray.map(
    (value, index) =>
      value.charAt(0).toUpperCase() +
      value.slice(1) +
      (index < originalArray.length - 1 ? ", " : "")
  );

  const getProfileValue = (key) => props.user?.[key] || "-";

  return (
    <div className=" modal-overlay d-flex ">
      <div className="d-flex w-100 justify-content-center ">
        <div className="modal-container">
          <div className="d-flex justify-content-between mx-3 align-items-center">
            <h2 className="popup-head-text">{constants.FACILITY_USER}</h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={props.closeViewFacilityUser}
              />
            </div>
          </div>
          <div className="ar-main-container">
            <div className=" form-container d-flex flex-wrap">
              <div className="ar-input-card">
                <div className="row g-0 my-1">
                  <label
                    htmlFor="select-facility"
                    className="col-4 bold-barlow ar-label "
                    name="select-worksite"
                  >
                    Account Name
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {getProfileValue("account_name")}
                  </p>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="userType"
                    name="userType"
                  >
                    Select User Type
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {capitalizedPropArray}
                  </p>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="client-portal-user"
                    name="client-portal-user"
                  >
                    Client Portal User
                  </label>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="addUser"
                    name="addUser"
                  >
                    Full Name
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {getProfileValue("full_name")}
                  </p>
                </div>
              </div>
              <div className="ar-input-card">
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="userDetails"
                    name="userDetails"
                  >
                    User details
                  </label>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="title"
                    name="title"
                  >
                    Title
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {getProfileValue("title")}
                  </p>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="phoneNumber"
                    name="phoneNumber"
                  >
                    Phone Number
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {getProfileValue("phone")}
                  </p>
                </div>
                <div className="row g-0 my-1">
                  <label
                    className="col-4 bold-barlow ar-label"
                    htmlFor="email"
                    name="email"
                  >
                    Email
                  </label>
                  <p className="col-8 ar-input main-text text-color">
                    {getProfileValue("email")}
                  </p>
                </div>
              </div>
            </div>
            <div className="ar-dpcontainer">
              <p className="bold-barlow ar-permission-label">
                Dashboard Permissions : Salesforce Embedded/Read-Only & Tableau
                Access
              </p>
              <div className="ar-row">
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Candidates</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch className=" switch" checked={candidates} />
                  </div>
                </div>
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Scheduling</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch className=" switch" checked={scheduling} />
                  </div>
                </div>
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Spend Management</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch
                      className=" switch"
                      checked={spendManagement}
                    />
                  </div>
                </div>
              </div>
              <div className="ar-row">
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Locums Analytics</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch
                      className=" switch"
                      checked={locumsAnalytics}
                    />
                  </div>
                </div>
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Scheduled Report</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch
                      className=" switch"
                      checked={scheduledReport}
                    />
                  </div>
                </div>
                <div className="row g-0 my-1 ar-row-column">
                  <div className="col-6 ar-dp-label">
                    <p>Landing Page</p>
                  </div>
                  <div className="col-6 ar-switch-container">
                    <InputSwitch className=" switch" checked={landingPage} />
                  </div>
                </div>
              </div>

              <p className="bold-barlow ar-permission-label">
                Manu Permissions : Salesforce Embedded/Read-Only & Tableau
                Access
              </p>

              <div className="ar-row">
                <div className="ar-row-column">
                  <div className="row g-0 my-1">
                    <div className="col-6 ar-dp-label">
                      <p>Schedule management</p>
                    </div>
                    <div className="col-6 ar-switch-container">
                      <InputSwitch
                        className=" switch"
                        checked={scheduleManagement}
                      />
                    </div>
                  </div>
                </div>
                <div className="ar-row-column">
                  <div className="row g-0 my-1">
                    <div className="col-6 ar-dp-label">
                      <p>Spend Management</p>
                    </div>
                    <div className="col-6 ar-switch-container">
                      <InputSwitch
                        className=" switch"
                        checked={spendManagementMenu}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
// PropTypes for the props passed to the component
ViewFacilityUser.propTypes = {
  closeViewFacilityUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    account_name_fd: PropTypes.string,
    candidates: PropTypes.bool,
    scheduling: PropTypes.bool,
    spend_management_dashboard: PropTypes.bool,
    locums_analytics: PropTypes.bool,
    scheduled_report: PropTypes.bool,
    landing_page: PropTypes.bool,
    schedule_management: PropTypes.bool,
    spend_management_menu: PropTypes.bool,
    personaData: PropTypes.array,
  }),
};
export default ViewFacilityUser;
