import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import * as Yup from "yup";
import RegularButton from "../../../components/Commons/RegularButton";
import { useSelector, useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { useDebounce } from "use-debounce";
import { Toast } from "primereact/toast";
import {
  getAdminSFList,
  getFacilitySFList,
  addFacility,
  getFacilityDBList,
} from "../../../redux/actions/facilityActions";
import constants from "../../../helpers/en";
import { getCurrentDate } from "../../../utils";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/AddFaciltyPopup.scss";
import {
  resetAdminData,
  resetFacilityData,
} from "../../../redux/actions/listActions";
import DropdownSpinner from "../../../components/Commons/DrpodownSpinner";
function AddFacilityPopup(props) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const formikRef = useRef(null);
  const { data: hospitals, loading: hospitalLoading } = useSelector(
    (state) => state.hospital
  );
  const { admins } = useSelector((state) => state.admin);
  const adminLoading = useSelector((state) => state.admin.loading);

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery] = useDebounce(searchQuery, 500);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState({});
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const closeForm = props.closeForm;
  const closePopup = () => {
    dispatch(resetFacilityData());
    dispatch(resetAdminData());
    closeForm();
  };
  useEffect(() => {
    if (debouncedQuery) {
      dispatch(getFacilitySFList(debouncedQuery));
    }
  }, [debouncedQuery, dispatch]);

  useEffect(() => {
    dispatch(getFacilityDBList());
  }, [dispatch]);

  useEffect(() => {
    if (formikRef.current && debouncedQuery) {
      formikRef.current.resetForm();
    }
    dispatch(resetAdminData());
  }, [debouncedQuery]);

  const validationSchema = Yup.object({
    hospital: Yup.mixed().required(constants.ACCOUNT_NAME_ERROR_MESSAGE),
    admin: Yup.mixed().required(constants.ADMIN_NAME_ERROR_MESSAGE),
  });

  const handleHospitalChange = (selectedOption, setFieldValue) => {
    setFieldValue("name", selectedOption?.name || "");
    setFieldValue("accountStatus", selectedOption?.accountStatus || "");
    setFieldValue("street", selectedOption?.address?.street || "");
    setFieldValue("city", selectedOption?.address?.city || "");
    setFieldValue("state", selectedOption?.address?.state || "");
    setFieldValue("country", selectedOption?.address?.country || "");
    setFieldValue("zipCode", selectedOption?.address?.postalCode || "");
    setFieldValue("definitiveId", selectedOption?.definitiveId || "");
    setFieldValue("accountNumber", selectedOption?.accountNumber || "");
    setFieldValue("website", selectedOption?.website || "");
    setFieldValue("hospitalType", selectedOption?.type || "");
    setFieldValue("account_Name", selectedOption?.name || "");
    //clear admin fields
    setFieldValue("fullName", "");
    setFieldValue("title", "");
    setFieldValue("phone", "");
    setFieldValue("email", "");
    setFieldValue("admin", "");

    setSearchQuery("");
    const hospitalId = selectedOption?.accountNumber;
    dispatch(getAdminSFList(hospitalId));
  };

  const handleAdminChange = (selectedOption, setFieldValue) => {
    setFieldValue("fullName", selectedOption?.fullName || "");
    setFieldValue("title", selectedOption?.title || "");
    setFieldValue("phone", selectedOption?.phone || "");
    setFieldValue("email", selectedOption?.email || "");
  };

  const handleResetAll = () => {
    setIsSubscribed(false);
  };

  const dropdownHeader = (options) => {
    return (
      <div className="p-dropdown-header">
        <IconField iconPosition="right">
          <InputIcon className="pi pi-search" />
          <InputText
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Type to search facilities..."
            style={{ width: "100%" }}
          />
        </IconField>
      </div>
    );
  };

  return (
    <div className=" modal-overlay">
      <Toast ref={toast} />
      <div className="d-flex w-100 justify-content-center">
        <div className="modal-container">
          <div className="d-flex justify-content-between mx-3 align-items-center">
            <h2 className="popup-head-text">{constants.ADD_FACILITY}</h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={() => {
                  closePopup();
                }}
              />
            </div>
          </div>

          <div className="rounded-3 form-container p-3">
            <Formik
              initialValues={{
                hospital: "",
                admin: "",
                id: "",
                name: "",
                accountStatus: "",
                street: "",
                city: "",
                state: "",
                country: "",
                zipCode: "",
                definitiveId: "",
                accountNumber: "",
                website: "",
                hospitalType: "",
                fullName: "",
                title: "",
                phone: "",
                email: "",
                account_Name: "",
                hospitalId: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const facilityPayload = [
                  {
                    facility_account_no: Number(
                      selectedFacility?.accountNumber || ""
                    ),
                    account_name: selectedFacility?.name || "",
                    account_status: selectedFacility?.accountStatus || "",
                    create_date: getCurrentDate(),
                    specialities: selectedFacility?.type || "",
                    salesforce_id: selectedFacility?.accountNumber || "",
                    definitive_Id: Number(selectedFacility?.definitiveId || ""),
                    website: selectedFacility?.website || "",
                    facility_type: selectedFacility?.type || "",
                    street: selectedFacility.address?.street || "",
                    city: selectedFacility.address?.city || "",
                    state: selectedFacility.address?.state || "",
                    zip_code: Number(
                      selectedFacility.address?.postalCode || ""
                    ),
                    country: selectedFacility.address?.country,
                    is_active: true,
                  },
                ];
                const adminPayload = [
                  {
                    facility_account_no: Number(
                      selectedFacility?.accountNumber || ""
                    ),

                    full_name: selectedAdmin?.fullName || "",
                    title: selectedAdmin?.title || "",
                    is_active: true,
                    // email_verified: false,
                    email_verified: true,
                    email: selectedAdmin?.email || "",
                    account_name: selectedFacility?.name || "",
                    role: "Facility Admin",
                    phone: selectedAdmin?.phone || "",
                    tableau_subscription: isSubscribed,
                    salesforce_id: selectedAdmin?.salesforceId || "",
                    specialties: selectedAdmin?.specialty || "",
                  },
                ];

                dispatch(
                  addFacility(facilityPayload, adminPayload, toast, closePopup)
                );
              }}
              innerRef={formikRef}
            >
              {({ values, setFieldValue, touched, errors, resetForm }) => (
                <Form>
                  <div className="row w-50 g-0 md-2">
                    <label
                      className="col-4 star-after bold "
                      htmlFor="facility-type"
                      name="facility-type"
                    >
                      {constants.ACCOUNT_NAME}
                    </label>
                    <div className="col-12 col-md-6 mb-3 d-flex flex-column worksite-dropdown">
                      <Dropdown
                        className="add-facility-dropdown"
                        id="hospital"
                        options={Array.isArray(hospitals) ? hospitals : []}
                        value={values.hospital}
                        onChange={(e) => {
                          setSelectedFacility(e.value);
                          setFieldValue("hospital", e.value);
                          handleHospitalChange(e.value, setFieldValue);
                        }}
                        optionLabel="name"
                        placeholder={constants.SELECT_ACCOUNT_NAME}
                        filter
                        loading={hospitalLoading}
                        filterTemplate={dropdownHeader}
                        emptyMessage={
                          hospitalLoading ? (
                            <DropdownSpinner />
                          ) : (
                            "No data available"
                          )
                        }
                      />
                      <ErrorMessage
                        name="hospital"
                        component="small"
                        className="error-text"
                      />
                    </div>
                  </div>

                  <p className="main-text bold">
                    {constants.FACILITY_INFORMATION}
                  </p>

                  <div className="row g-0 px-2 px-md-7">
                    <div className="col-12 col-md-6 ">
                      <div className="row ">
                        <label className="col-4 " htmlFor="name">
                          {constants.ACCOUNT_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50 mb-4 col-8"
                          name="name"
                          placeholder="Full legal name of the facility or healthcare facility"
                          id="name"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="accountStatus">
                          {constants.ACCOUNT_STATUS}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="accountStatus"
                          placeholder="Website"
                          id="accountStatus"
                        />
                      </div>

                      <div className="row">
                        <label className="col-4" htmlFor="street">
                          {constants.ADDRESS}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="street"
                          placeholder="Street"
                          id="street"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label" htmlFor="city"></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="city"
                          placeholder="City"
                          id="city"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label" htmlFor="state"></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="state"
                          placeholder="State"
                          id="state"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label" htmlFor="zipCode"></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="zipCode"
                          placeholder="Zip code"
                          id="zipCode"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label" htmlFor="country"></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="country"
                          placeholder="Country"
                          id="country"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="definitiveId">
                          {constants.DEFINITIVE_ID}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="definitiveId"
                          placeholder="(123)456-7890"
                          id="definitiveId"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <label className="col-4" htmlFor="accountNumber">
                          {constants.ACCOUNT_NUMBER}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="accountNumber"
                          placeholder="Account number"
                          id="accountNumber"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="website">
                          {constants.WEBSITE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="website"
                          placeholder="Website"
                          id="website"
                        />
                      </div>

                      <div className="row">
                        <label className="col-4" htmlFor="website">
                          {constants.TYPE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="hospitalType"
                          placeholder="Type"
                          id="Type"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="dotted-line mx-5" />

                  <div className="row w-50 g-0 md-2">
                    <label
                      className="col-4 bold "
                      htmlFor="custom-subscription-switch"
                    >
                      {constants.TABLEAU_SUBSCRIPTION}
                    </label>
                    <div className="col-12 col-md-6 mb-3 d-flex align-items-center worksite-dropdown">
                      <div
                        className={`custom-switch ${
                          isSubscribed ? "active" : ""
                        }`}
                        onClick={() => setIsSubscribed(!isSubscribed)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            setIsSubscribed(!isSubscribed); // Trigger the same toggle action on Enter/Space key press
                          }
                        }}
                        tabIndex={0} // Makes the <div> focusable and part of the tab order
                      >
                        <div className="custom-toggle"></div>
                      </div>

                      <div className="ms-2">
                        {isSubscribed ? "TRUE" : "FALSE"}
                      </div>
                    </div>
                  </div>

                  <p className="main-text bold">{constants.FACILITY_ADMIN}</p>
                  <div className="row g-0">
                    <div className="row w-50 g-0 md-2">
                      <label
                        className="col-4 star-after bold"
                        htmlFor="select-admin"
                        name="select-admin"
                      >
                        {constants.SELECT_ADMIN}
                      </label>
                      <div className="col-12 col-md-6 mb-3 d-flex flex-column worksite-dropdown">
                        <Dropdown
                          className="add-facility-dropdown"
                          id="admin"
                          value={values.admin}
                          options={Array.isArray(admins) ? admins : []}
                          onChange={(e) => {
                            setSelectedAdmin(e.value);
                            setFieldValue("admin", e.value);
                            handleAdminChange(e.value, setFieldValue);
                          }}
                          optionLabel="fullName"
                          placeholder="Add required person for the role of admin"
                          filter
                          filterBy="fullName"
                          filterPlaceholder="Search Admin"
                          loading={adminLoading}
                          emptyFilterMessage={
                            adminLoading ? (
                              <DropdownSpinner />
                            ) : (
                              "No data available"
                            )
                          }
                          emptyMessage={
                            adminLoading ? (
                              <DropdownSpinner />
                            ) : (
                              "No data available"
                            )
                          }
                        />
                        <ErrorMessage
                          name="admin"
                          component="small"
                          className="error-text"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <label className="col-4" htmlFor="fullName">
                          {constants.FULL_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-6"
                          name="fullName"
                          placeholder="Your name"
                          id="fullName"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="title">
                          {constants.TITLE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="title"
                          placeholder="(e.g. HR, Hiring Manager, Medical Director)"
                          id="title"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="phone">
                          {constants.PHONE_NUMBER}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="phone"
                          placeholder="(123)456-7890"
                          id="phone"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="email">
                          {constants.EMAIL}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="email"
                          placeholder="example@exe.com"
                          id="email"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="account_Name">
                          {constants.ACCOUNT_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="account_Name"
                          placeholder=" Account name"
                          id="account_Name"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4"></label>
                        <div className="d-flex w-50 col-8 p-2 justify-content-end">
                          <RegularButton
                            className="me-2"
                            color={"#ffffff"}
                            backgroundColor={"#8e94a1"}
                            btnname={constants.BUTTTON_RESET}
                            onClick={() => {
                              handleResetAll();
                              resetForm();
                            }}
                          />
                          <RegularButton
                            color={"#424c63"}
                            backgroundColor={"#00e676"}
                            type={"submit"}
                            btnname={constants.BUTTON_SUBMIT}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
// Add PropTypes for props validation
AddFacilityPopup.propTypes = {
  closeForm: PropTypes.func.isRequired, // This is a function
};
export default AddFacilityPopup;
