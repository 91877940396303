import {
  GET_REPORT_DETAILS,
  SET_REPORT_DETAILS,
  REPORT_DETAILS_FAILURE,
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_SUCCESS,
  FETCH_REPORTS_FAILURE,
  SET_ADMIN_FACILITY,
  SET_ADMIN_FACILITY_ID,
  SET_ADMIN_CHILD_FACILITY,
  CLEAR_ALL_ADMIN_FACILITIES,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  reportDetails: [],
  error: null,
};
const initialReportState = {
  loading: false,
  reports: [],
  error: null,
};
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REPORT_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_REPORT_DETAILS:
      return {
        ...state,
        loading: false,
        reportDetails: action.payload,
      };
    case REPORT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const reportByClientReducer = (state = initialReportState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.payload,
      };
    case FETCH_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialFacilityState = {
  adminFacility: null,
  adminFacilityID: null,
  adminChildFacility: null,
};

// Reducer
export const adminFacilityReducer = (state = initialFacilityState, action) => {
  switch (action.type) {
    case SET_ADMIN_FACILITY:
      return {
        ...state,
        adminFacility: action.payload,
        adminChildFacility: null,
      };
    case SET_ADMIN_FACILITY_ID:
      return { ...state, adminFacilityID: action.payload };
    case SET_ADMIN_CHILD_FACILITY:
      return { ...state, adminChildFacility: action.payload };
    case CLEAR_ALL_ADMIN_FACILITIES:
      return {
        ...state,
        adminFacility: null,
        adminFacilityID: null,
        adminChildFacility: null,
      };
    default:
      return state;
  }
};
