import React from "react";
import PropTypes from "prop-types"; // Import prop-types for validation
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector, useDispatch } from "react-redux";
import { handleLogout } from "../../helpers/headerMenu";

const PrivateRoute = ({ children, allowedRoles }) => {
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  const dispatch = useDispatch();

  const roleType = useSelector(
    (state) => state.rolePermission.permissions.role
  ); // Show a loading state while the Auth0 state is being determined
  if (isLoading) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* Use ProgressSpinner as the loader */}
          <ProgressSpinner />
        </div>
      </div>
    );
  }

  // If the user is not authenticated, redirect them to the login page
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Check if the user has the required role from the allowedRoles
  if (
    user &&
    allowedRoles?.some((role) => roleType?.includes(role)) === false
  ) {
    handleLogout(logout, Navigate, dispatch);
  }

  // If the user is authenticated and has the required role(s), render the children (the protected route component)
  return children;
};

// Define prop types
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a valid React node and required
  allowedRoles: PropTypes.arrayOf(PropTypes.string), // Validate that allowedRoles is an array of strings and required
};

export default PrivateRoute;
