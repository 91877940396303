import React from "react";
import PropTypes from "prop-types"; // Import prop-types for validation
import { Link } from "react-router-dom"; // Import Link from React Router
import "./../../assets/css/ProfileCards.scss";

const ProfileCards = ({ data }) => {
  return (
    <div className="pr-card-container">
      <div className="row g-0 pr-row">
        {data.map((item) => (
          <React.Fragment key={item.id || item.label}>
            <div className="col-5">
              <p className="bold-barlow">{item.label}</p>
            </div>
            <div className="col-7">{renderCardContent(item)}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

// Helper function to simplify conditional rendering
const renderCardContent = (item) => {
  if (item.tag === "a") {
    return (
      <a
        href={item.route || "#"}
        className="pr-card-link"
        onClick={(e) => {
          if (item.route === "#") {
            e.preventDefault(); // Prevent navigation for href="#"
          }
        }}
      >
        {item.value}
      </a>
    );
  } else if (item.tag === "link") {
    return (
      <Link to={item.route || "#"} className="pr-card-link">
        {item.value}
      </Link>
    );
  } else {
    return <p>{item.value}</p>;
  }
};

// Define prop types
ProfileCards.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Unique identifier for key
      label: PropTypes.string.isRequired, // 'label' must be a string
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired, // 'value' can be a string or number
      tag: PropTypes.oneOf(["p", "a", "link"]), // 'tag' specifies the element type
      route: PropTypes.string, // 'route' is optional but required if 'tag' is 'a' or 'link'
    })
  ).isRequired, // 'data' is an array and required
};

export default ProfileCards;
