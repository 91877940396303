import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/RegularButton.scss";

function RegularButton({
  color = "#fff",
  btnname = "Click Me",
  backgroundColor = "#007bff",
  className = "",
  type = "button",
  onClick,
}) {
  const buttonStyles = {
    color,
    backgroundColor,
  };
  const btnclasses = `regular-btn ${className}`;

  return (
    <button
      className={btnclasses}
      style={buttonStyles}
      onClick={onClick}
      type={type} // Add the type to the button for HTML standards compliance
    >
      {btnname}
    </button>
  );
}

// PropTypes validation
RegularButton.propTypes = {
  color: PropTypes.string,
  btnname: PropTypes.string,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func, // Ensures `onClick` is passed and is a function.
};

// Export the component
export default RegularButton;
