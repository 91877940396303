//Example
export const USER_TYPES = {
  SUPER_ADMIN: "Super Admin",
  FACILITY_ADMIN: "Facility Admin",
  FACILITY_USER: "Facility User",
};

// worksite
export const COLUMNS = {
  PARENT_ACCOUNT: {
    field: "parent_name",
    header: "Parent Account",
    style: { width: "18%" },
    headerClassName: "parent-account-header",
    expander: true,
  },

  ACCOUNT_NAME: {
    field: "account_name",
    header: "Account Name",
    style: { width: "18%" },
  },
  ACCOUNT_NUMBER: {
    field: "account_number",
    header: "Account Number",
  },
  ACCOUNT_STATUS: {
    field: "account_status",
    header: "Account Status",
  },
  WEBSITE: {
    field: "website",
    header: "Website",
  },
  ADDRESS: {
    field: "address",
    header: "Address (City & State)",
  },
  TYPE: {
    field: "type",
    header: "Type",
  },
};

//SpendMangemnt
export const invoiceData = [
  {
    id: 1,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 2,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 3,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 4,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 5,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 6,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },

  {
    id: 1,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 2,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 3,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 4,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 5,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 6,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 7,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 8,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 9,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 10,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
  {
    id: 11,
    providerNames: "Willam Schaffer ",
    invoiceDates: "9/29/2023",
    facilityNames: "Maine Veter Hospital",
    clientName: "Ascension",
    invoiceNumber: "INV-9067",
    status: "Paid",
    paymentTerm: "NET30",
    amount: "",
    accountReceived: "0 Akash Lee",
    confirmNumber: "A-02128",
    invoiceDate: "10/9/2023",
    speciality: "Hematology-Oncology",
  },
  {
    id: 12,
    providerNames: "Ava Swayze",
    invoiceDates: "8/29/2023",
    facilityNames: "Unity Point Hospital",
    clientName: "Acute Care Surgery",
    invoiceNumber: "INV-6785",
    status: "Paid",
    paymentTerm: "Due Upon Recepit",
    amount: "",
    accountReceived: "0 Rodrigo Botero",
    confirmNumber: "A-02148",
    invoiceDate: "10/9/2023",
    speciality: "Urology",
  },
];

//Facility user
export const facilityUser = [
  {
    sr_No: "1",
    facility_User: "Recruter",
    contactPerson: "Ava Davis",
    location: "Orlando",
    specialities: "Cardilogy",
    status: "Active",
  },
  {
    sr_No: "2",
    facility_User: "Department",
    contactPerson: "olivia Williams",
    location: "Maimi",
    specialities: "Oncology",
    status: "Active",
  },
  {
    sr_No: "3",
    facility_User: "Accounting",
    contactPerson: "Ethan Davis",
    location: "Tampa",
    specialities: "Orthopetics",
    status: "Active",
  },
  {
    sr_No: "4",
    facility_User: "Leadership",
    contactPerson: "James",
    location: "Orlando",
    specialities: "Cardilogy",
    status: "On Hold",
  },
  {
    sr_No: "5",
    facility_User: "Guest",
    contactPerson: "James",
    location: "Orlando",
    specialities: "Cardilogy",
    status: "On Hold",
  },
];

export const workSites = [
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
  {
    name: "Health Medical Center",
    type: "General",
    location: "1234 Sunshine Boulevard, Miami, Florida",
    admin: "Ethan Johnson",
    contacts: {
      hospital: "(123) 456-7890",
      general: "(123) 456-7890",
      email: "Info@BrowardHealthNoth.com",
      fax: "(123) 456-7890",
    },
    subscribed: true,
  },
];

export const worksiteUsers = [
  {
    userName: "John Doe",
    userType: "Admin",
    phoneNumber: "123-456-7890",
    accountName: "City Hospital",
    title: "Hospital Administrator",
    email: "johndoe@cityhospital.com",
  },
  {
    userName: "Jane Smith",
    userType: "Doctor",
    phoneNumber: "987-654-3210",
    accountName: "Green Valley Clinic",
    title: "Chief Surgeon",
    email: "janesmith@greenvalleyclinic.com",
  },
  {
    userName: "Michael Brown",
    userType: "Nurse",
    phoneNumber: "456-789-1234",
    accountName: "Blue River Hospital",
    title: "Senior Nurse",
    email: "michaelbrown@blueriverhospital.com",
  },
  {
    userName: "Emily Davis",
    userType: "Receptionist",
    phoneNumber: "321-654-9870",
    accountName: "Silver Oak Medical Center",
    title: "Front Desk Manager",
    email: "emilydavis@silveroak.com",
  },
  {
    userName: "Chris Wilson",
    userType: "Technician",
    phoneNumber: "789-123-4567",
    accountName: "Skyline Diagnostics",
    title: "Lab Technician",
    email: "chriswilson@skylinediagnostics.com",
  },
  {
    userName: "Patricia Taylor",
    userType: "Admin",
    phoneNumber: "654-987-3210",
    accountName: "Harmony Care",
    title: "HR Manager",
    email: "patriciataylor@harmonycare.com",
  },
  {
    userName: "James Anderson",
    userType: "Doctor",
    phoneNumber: "123-987-6543",
    accountName: "Sunrise Hospital",
    title: "Pediatrician",
    email: "jamesanderson@sunrisehospital.com",
  },
  {
    userName: "Laura Thomas",
    userType: "Nurse",
    phoneNumber: "456-123-7890",
    accountName: "Golden Valley Health",
    title: "Head Nurse",
    email: "laurathomas@goldenvalleyhealth.com",
  },
  {
    userName: "Daniel Martinez",
    userType: "Technician",
    phoneNumber: "789-456-1230",
    accountName: "Metro Labs",
    title: "X-ray Technician",
    email: "danielmartinez@metrolabs.com",
  },
  {
    userName: "Sophia Hernandez",
    userType: "Receptionist",
    phoneNumber: "321-789-6543",
    accountName: "Downtown Clinic",
    title: "Receptionist",
    email: "sophiahernandez@downtownclinic.com",
  },
  {
    userName: "Olivia White",
    userType: "Admin",
    phoneNumber: "654-321-9876",
    accountName: "River Valley Hospital",
    title: "Operations Manager",
    email: "oliviawhite@rivervalleyhospital.com",
  },
  {
    userName: "Ethan Harris",
    userType: "Doctor",
    phoneNumber: "987-321-6540",
    accountName: "Hilltop Medical",
    title: "Orthopedic Specialist",
    email: "ethanharris@hilltopmedical.com",
  },
  {
    userName: "Isabella Clark",
    userType: "Nurse",
    phoneNumber: "789-654-1239",
    accountName: "Oceanview Health",
    title: "Nurse Practitioner",
    email: "isabellaclark@oceanviewhealth.com",
  },
  {
    userName: "Alexander Lee",
    userType: "Technician",
    phoneNumber: "321-987-6547",
    accountName: "Mountain Labs",
    title: "Lab Assistant",
    email: "alexanderlee@mountainlabs.com",
  },
  {
    userName: "Mia Lewis",
    userType: "Receptionist",
    phoneNumber: "456-789-3211",
    accountName: "Lakeside Clinic",
    title: "Front Desk Staff",
    email: "mialewis@lakesideclinic.com",
  },
  {
    userName: "William Robinson",
    userType: "Admin",
    phoneNumber: "987-654-1238",
    accountName: "Evergreen Medical",
    title: "IT Administrator",
    email: "williamrobinson@evergreenmedical.com",
  },
  {
    userName: "Charlotte Walker",
    userType: "Doctor",
    phoneNumber: "654-123-7892",
    accountName: "Central Health",
    title: "Dermatologist",
    email: "charlottewalker@centralhealth.com",
  },
  {
    userName: "Benjamin Hall",
    userType: "Nurse",
    phoneNumber: "123-789-6543",
    accountName: "Northern Care",
    title: "Registered Nurse",
    email: "benjaminhall@northerncare.com",
  },
  {
    userName: "Abigail Young",
    userType: "Technician",
    phoneNumber: "789-123-4568",
    accountName: "Westside Labs",
    title: "Medical Imaging Specialist",
    email: "abigailyoung@westsidelabs.com",
  },
  {
    userName: "Lucas King",
    userType: "Receptionist",
    phoneNumber: "321-654-9871",
    accountName: "Eastside Clinic",
    title: "Clinic Coordinator",
    email: "lucasking@eastsideclinic.com",
  },
];

export const shiftdata = [
  {
    confirmationnumber: "A-05727",
    bookingstatus: "Active",
    firstname: "Kanayo",
    lastname: "Ubesie",
    clientname: "SSM Health Care Corporation",
    facilityname: "SSM Health St. Clare Hospital - Baraboo",
    practitionertype: "Physician",
    specialty: "OB/GYN",
    shiftstartdate: "12/14/2024",
    shiftenddate: "12/15/2024",
    shifttimes: "5:00 PM to 9:00 PM",
    shifttype: "Shift (Hourly)",
    facilitycity: "Baraboo",
    facilitystate: "WI",
    consultantname: "Killian Thrush",
  },
  {
    confirmationnumber: "A-05731",
    bookingstatus: "Active",
    firstname: "William",
    lastname: "Mackin",
    clientname: "Cheyenne Regional Medical Center",
    facilityname: "SSM Health St. Clare Hospital - Baraboo",
    practitionertype: "Physician",
    specialty: "OB/GYN",
    shiftstartdate: "12/13/2024",
    shiftenddate: "12/15/2024",
    shifttimes: "5:00 PM to 9:00 PM",
    shifttype: "Shift (Hourly)",
    facilitycity: "Baraboo",
    facilitystate: "WI",
    consultantname: "Killian Thrush",
  },
  {
    confirmationnumber: "A-05731",
    bookingstatus: "Active",
    firstname: "William",
    lastname: "Mackin",
    clientname: "Cheyenne Regional Medical Center",
    facilityname: "SSM Health St. Clare Hospital - Baraboo",
    practitionertype: "Physician",
    specialty: "OB/GYN",
    shiftstartdate: "12/14/2024",
    shiftenddate: "12/15/2024",
    shifttimes: "5:00 PM to 9:00 PM",
    shifttype: "Shift (Hourly)",
    facilitycity: "Baraboo",
    facilitystate: "WI",
    consultantname: "Killian Thrush",
  },
];

export const doccumentdata = [
  {
    filetype: "Excel",
    filename: "File Name",
    filesize: "124KB",
    uploadeddate: "04/12/2024",
    relatedto: "Related Name",
  },
  {
    filetype: "Word",
    filename: "File Name",
    filesize: "1.2MB",
    uploadeddate: "03/12/2024",
    relatedto: "Related Name",
  },
  {
    filetype: "PDF",
    filename: "File Name",
    filesize: "124KB",
    uploadeddate: "04/12/2024",
    relatedto: "Related Name",
  },
  {
    filetype: "Word",
    filename: "File Name",
    filesize: "1.2MB",
    uploadeddate: "03/12/2024",
    relatedto: "Related Name",
  },
];
export const staticData = [
  {
    timesheetname:
      "William Schaffer (Due 2024-10-07)-NO CONS OVERRIDE-PUSHED TO OFFCYCLE",
    approvalfile: "A-03289-10/11/2024",
    approvalduedate: "9-Oct-24",
    approvalstatus: "",
    rejectionnotes: "",
    timesheetUrl: "Link",
    providername: "",
    facilityCity: "William Allen Schaffer",
    facilityState: "Maine Veterans Home Augusta",
    specialty: "Adult Psychiatry  ",
    clientInvoice: "2062.50 ",
    clientinvoice: "2024-09-23 to 2024-10-06  ",
    payrollContact: "Jacob C. Anderson",
  },
  {
    timesheetname: "Ava Swayze (Due 2024-10-07)",
    approvalfile: "A-03289-10/11/2024",
    approvalduedate: "9-Oct-24",
    approvalstatus: "",
    rejectionnotes: "",
    timesheetUrl: "Link",
    facilityCity: "Ava Marlene Swayze",
    facilityState: "RiverStone",
    specialty: "Hospitalist ",
    clientInvoice: "24640.00  ",
    clientinvoice: "2024-09-23 to 2024-10-06  ",
    payrollContact: "Jacob C. Anderson",
  },
];

export const reportsData = [
  {
    reportName: "Annual Financial Report 2024",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Employee Engagement Survey Results",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Customer Satisfaction Report Q1",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Market Trends Analysis 2024",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "IT Infrastructure Audit",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Environmental Impact Report",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Sales Performance Q2",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Product Development Plan 2025",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Corporate Social Responsibility Report",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Risk Assessment Summary",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Annual Financial Report 2024",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Annual Financial Report 2024",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
  {
    reportName: "Annual Financial Report 2024",
    reportUrl: `https://us-east-1.online.tableau.com/t/hayeslocumstableau/views/DailyKPIs_v0-4UpdatedDateFilters/Apps`,
  },
];

export const Personadata = [
  "Recruiter",
  "Department",
  "Accounting",
  "Leadership",
  "Guest",
];

export const Personadata1 = [
  {
    user_id: 1,
    personaData: {
      recruiter: true,
      department: false,
      accounting: true,
      leadership: false,
      guest: false,
    },
  },
];

export const userDataInfo = [
  {
    userType: "Recruiter",
    contactPerson: "Alice Johnson",
    location: "New York",
    speciality: "Cardiology",
    status: "Active",
  },
  {
    userType: "Department",
    contactPerson: "Dr. Brian Carter",
    location: "Los Angeles",
    speciality: "Neurology",
    status: "On Hold",
  },
  {
    userType: "Accounting",
    contactPerson: "Emma Williams",
    location: "Chicago",
    speciality: "Pediatrics",
    status: "Active",
  },
  {
    userType: "Leadership",
    contactPerson: "Michael Smith",
    location: "Houston",
    speciality: "Orthopedics",
    status: "Active",
  },
  {
    userType: "Guest",
    contactPerson: "Sarah Thompson",
    location: "Phoenix",
    speciality: "Dermatology",
    status: "On Hold",
  },
  {
    userType: "Recruiter",
    contactPerson: "Christopher Davis",
    location: "Philadelphia",
    speciality: "Oncology",
    status: "Active",
  },
  {
    userType: "Department",
    contactPerson: "Sophia Martinez",
    location: "San Antonio",
    speciality: "Radiology",
    status: "Active",
  },
  {
    userType: "Accounting",
    contactPerson: "David Wilson",
    location: "San Diego",
    speciality: "Cardiology",
    status: "On Hold",
  },
  {
    userType: "Leadership",
    contactPerson: "Olivia Anderson",
    location: "Dallas",
    speciality: "Endocrinology",
    status: "Active",
  },
  {
    userType: "Guest",
    contactPerson: "James Taylor",
    location: "Austin",
    speciality: "Gastroenterology",
    status: "On Hold",
  },
];
