// ConfirmationDialog.js
import React, { useRef } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import "../../assets/css/ConfirmDialog.scss";
import constants from "../../helpers/en";

const ConfirmationDialog = ({
  showDialog,
  onConfirm,
  onCancel,
  status,
  name,
}) => {
  const toast = useRef(null);

  const accept = () => {
    toast.current.show({
      severity: "success",
      summary: "Confirmed",
      detail: status
        ? `${name} inactivated successfully !`
        : `${name} activated successfully !`,
      life: 3000,
    });
    onConfirm(); // Call the onConfirm function passed via props
  };

  const reject = () => {
    onCancel();
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={showDialog}
        onHide={() => {}}
        message={
          status
            ? `Are you sure you want to inactivate this ${name.toLowerCase()}?`
            : `Are you sure you want to activate this ${name.toLowerCase()}?`
        }
        header={constants.CONFIRMATION_TITLE}
        icon="pi pi-exclamation-triangle"
        accept={accept} // yes
        reject={reject} // no
        className="no-close-icon" // Custom class to hide the close button
      />
    </>
  );
};
// Adding PropTypes validation
ConfirmationDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired, // showDialog should be a boolean
  onConfirm: PropTypes.func.isRequired, // onConfirm should be a function
  onCancel: PropTypes.func.isRequired, // onCancel should be a function
  status: PropTypes.bool,
  name: PropTypes.string,
};
export default ConfirmationDialog;
