import { Outlet } from "react-router-dom";
import SchedulingProfileNav from "./SchedulingProfileNav";

function SchedulingProfile() {
  return (
    <div className="d-flex flex-column flex-grow-1 overflow-auto p-3 h-100">
      <SchedulingProfileNav />
      <Outlet />
    </div>
  );
}

export default SchedulingProfile;
