import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import {
  fetchTimeEntries,
  setActiveSPProfileNavLink,
} from "../../../redux/actions/spendManagementActions";
import "../../../assets/css/TimeEntries.scss";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import constants from "../../../helpers/en";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";

function TimeEntries() {
  const dispatch = useDispatch();

  // Redux state selectors
  const timeEntries = useSelector((state) => state.timeEntries.timeEntries);
  const isDataLoading = useSelector((state) => state.timeEntries.loading);
  const facilities = useSelector((state) => state.facilityList.data);

  // Local state
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const confirmationNum = sessionStorage.getItem("SpconfirmationNumber");
  const id = sessionStorage.getItem("SpId");

  useEffect(() => {
    dispatch(setActiveSPProfileNavLink("time-entries"));
  }, [dispatch]);

  useEffect(() => {
    if (confirmationNum && id) {
      dispatch(fetchTimeEntries(constants.LIMIT, 0, "", confirmationNum, id));
    }
  }, [dispatch, confirmationNum, id]);

  // Initialize filters
  const initializeFilters = useCallback(() => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      timesheetApproved: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      practitionerType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      specialty: { value: null, matchMode: FilterMatchMode.CONTAINS },
      dateWorked: { value: null, matchMode: FilterMatchMode.CONTAINS },
      ShiftStartEndTime: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Beeper: { value: null, matchMode: FilterMatchMode.CONTAINS },
      clientName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityName: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityCity: { value: null, matchMode: FilterMatchMode.CONTAINS },
      confirmationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facilityState: { value: null, matchMode: FilterMatchMode.CONTAINS },
      consultantName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
  }, []);

  useEffect(() => {
    initializeFilters();
  }, [initializeFilters, facilities]);

  // Handlers
  const handleSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const handleFilterChange = (e) => {
    setFilters(e.filters);
  };

  const handlePageChange = (e) => {
    setFirst(e.first);
  };

  // Render header (if required)

  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      setFilters((prevFilters) => ({
        ...prevFilters,
        global: { value, matchMode: FilterMatchMode.CONTAINS },
      }));
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };

  const renderHeader = () => {
    const timesheetCount = timeEntries?.count;
    return (
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="timedsheet-count">
          <h4 className="profile-headings">
            Timesheet{timesheetCount > 0 ? ` (${timesheetCount})` : ""}
          </h4>
        </div>
        <div
          className="d-flex justify-content-end align-items-end"
          style={{ width: "60%" }}
        >
          <IconField iconPosition="left" style={{ width: "40%" }}>
            <InputIcon className="pi pi-search" />
            <InputText
              className="search-input-box"
              type="search"
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </IconField>
        </div>
      </div>
    );
  };

  return (
    <div className="sc-shifts-timeEntries flex-grow-1 overflow-auto">
      <DataTable
        className="table-responsive px-3"
        value={
          Array.isArray(timeEntries?.timeEntries) ? timeEntries.timeEntries : []
        }
        loading={isDataLoading}
        stripedRows
        header={renderHeader()}
        filters={filters}
        filterDisplay="row"
        paginator
        rows={20}
        first={first}
        sortField={sortField}
        sortOrder={sortOrder}
        onSort={handleSort}
        onPage={handlePageChange}
        onFilter={handleFilterChange}
        dataKey="facility_id"
        selectionMode="single"
        stateStorage="local"
        stateKey="dt-state-shifts"
        emptyMessage={
          <div className="d-flex justify-content-center">
            No data available.
          </div>
        }
      >
        <Column
          className="timesheet_class"
          header="Timesheeet Approved"
          field="timesheetApproved"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="timesheet-text">
              {rowData.timesheetApproved ? rowData.timesheetApproved : "-"}
            </span>
          )}
        />
        <Column
          className="First_class"
          header="First Name"
          field="firstName"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="firstName-text">
              {rowData.firstName ? rowData.firstName : "-"}
            </span>
          )}
        />

        <Column
          className="Practitioner_class"
          header="Practitioner Type"
          field="practitionerType"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="practitionerType-text">
              {rowData.practitionerType ? rowData.practitionerType : "-"}
            </span>
          )}
        />
        <Column
          className="Specialty_class"
          header="Specialty"
          field="specialty"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="specialty-text">
              {rowData.specialty ? rowData.specialty : "-"}
            </span>
          )}
        />
        <Column
          className="Date_class"
          header="Date Worked"
          field="dateWorked"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="dateWorked-text">
              {rowData.dateWorked ? rowData.dateWorked : "-"}
            </span>
          )}
        />
        <Column
          className="Start_class"
          header="Shift Start/End Time"
          field="ShiftStartEndTime"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="Start-text">
              {rowData.ShiftStartEndTime ? rowData.ShiftStartEndTime : "-"}
            </span>
          )}
        />

        <Column
          className="Beeper_class"
          header="Beeper"
          field="Beeper"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="Beeper-text">
              {rowData.Beeper ? rowData.Beeper : "-"}
            </span>
          )}
        />
        <Column
          className="Client_class"
          header="Client Name"
          field="clientName"
          filter
          filterPlaceholder="Search"
          body={(rowData) => (
            <span className="Client-text">
              {rowData.clientName ? rowData.clientName : "-"}
            </span>
          )}
        />
        <Column
          className="facilityName_clss"
          header="Facility Name"
          field="facilityName"
          filter
          body={(rowData) => (
            <span className="facilityName-text">
              {rowData.facilityName ? rowData.facilityName : "-"}
            </span>
          )}
          filterField="facilityName"
          filterPlaceholder="Search"
        />

        <Column
          className="facility_state"
          header="Facility State"
          field="facilityState"
          filter
          body={(rowData) => (
            <span className="facilityState-text">
              {rowData.facilityState ? rowData.facilityState : "-"}
            </span>
          )}
          filterField="facilityState"
          filterPlaceholder="Search"
        />
        <Column
          className="Facility_clss"
          header="Facility City"
          field="facilityCity"
          filter
          body={(rowData) => (
            <span className="facilityCity-text">
              {rowData.facilityCity ? rowData.facilityCity : "-"}
            </span>
          )}
          filterField="facilityCity"
          filterPlaceholder="Search"
        />
        <Column
          className="Confirmation_class"
          header="Confirmation Number"
          field="confirmationNumber"
          filter
          body={(rowData) => (
            <span className="confirmationNumber-text">
              {rowData.confirmationNumber ? rowData.confirmationNumber : "-"}
            </span>
          )}
          filterPlaceholder="Search"
        />
        <Column
          className="consultant_Name_clss"
          header="Consultant Name"
          field="consultantName"
          filter
          body={(rowData) => (
            <span className="consultantName-text">
              {rowData.consultantName ? rowData.consultantName : "-"}
            </span>
          )}
          filterField="consultantName"
          filterPlaceholder="Search"
        />
      </DataTable>
    </div>
  );
}

export default TimeEntries;
