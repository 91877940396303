import {
  SPEND_TIMESHEET_REQUEST,
  SPEND_TIMESHEET_SUCCESS,
  SPEND_TIMESHEET_FAILURE,
  GET_TIMEENTRIES_REQUEST,
  GET_TIMEENTRIES_SUCCESS,
  GET_TIMEENTRIES_FAILURE,
  FETCH_SPPROFILE_REQUEST,
  FETCH_SPPROFILE_SUCCESS,
  FETCH_SPPROFILE_FAILURE,
  SET_ACTIVE_SPPROFILE_NAVLINK,
  GET_SPEND_DOCUMENT_REQUEST,
  GET_SPEND_DOCUMENT_SUCCESS,
  GET_SPEND_DOCUMENT_FAILURE,
  VIEW_SPEND_DOCUMENT_REQUEST,
  VIEW_SPEND_DOCUMENT_SUCCESS,
  VIEW_SPEND_DOCUMENT_FAILURE,
  SET_ACTIVE_IV_SPPROFILE_NAVLINK,
  SET_ACTIVE_SP_MAIN_NAVLINK,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const initialSpendState = {
  loading: false,
  spendManagementTimesheet: [],
  error: "",
};

const initialTimeEntrieState = {
  timeEntries: [], // Array to hold shift data
  loading: false, // Indicates loading state
  error: null, // Error message if request fails
};
const initialSpendDocumentState = {
  spendDocuments: [], // Array to hold fetched documents
  loading: false, // Indicates loading state
  error: null, // Holds error message if request fails
};
const initialViewSpendDocumentState = {
  viewSpendDocument: [], // Array to hold fetched documents
  loading: false, // Indicates loading state
  error: null, // Holds error message if request fails
};

export const spendManagementReducer = (state = initialSpendState, action) => {
  switch (action.type) {
    case SPEND_TIMESHEET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SPEND_TIMESHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        spendManagementTimesheet: action.payload,
      };
    case SPEND_TIMESHEET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const timeEntiresReducer = (state = initialTimeEntrieState, action) => {
  switch (action.type) {
    case GET_TIMEENTRIES_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_TIMEENTRIES_SUCCESS:
      return { ...state, loading: false, timeEntries: action.payload };
    case GET_TIMEENTRIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SpProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPPROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SPPROFILE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_SPPROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const initialStateNav = {
  activeNavLink: "",
};

export const spMainNavReducer = (state = initialStateNav, action) => {
  switch (action.type) {
    case SET_ACTIVE_SP_MAIN_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    default:
      return state;
  }
};

export const spProfileNavReducer = (state = initialStateNav, action) => {
  switch (action.type) {
    case SET_ACTIVE_SPPROFILE_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    default:
      return state;
  }
};

export const spIvProfileNavReducer = (state = initialStateNav, action) => {
  switch (action.type) {
    case SET_ACTIVE_IV_SPPROFILE_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    default:
      return state;
  }
};

export const documentSpendReducer = (
  state = initialSpendDocumentState,
  action
) => {
  switch (action.type) {
    case GET_SPEND_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SPEND_DOCUMENT_SUCCESS:
      return { ...state, loading: false, spendDocuments: action.payload };
    case GET_SPEND_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export const viewSpendDocumentReducer = (
  state = initialViewSpendDocumentState,
  action
) => {
  switch (action.type) {
    case VIEW_SPEND_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEW_SPEND_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        viewSpendDocument: action.payload,
      };
    case VIEW_SPEND_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
