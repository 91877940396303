import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/colorConstant.scss";
import "./assets/css/common.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const callBack = `${window.location.protocol}//${window.location.host}/home`;
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_Auth0Doman}
      clientId={process.env.REACT_APP_Auth0ClientId}
      authorizationParams={{
        redirect_uri: callBack, //${window.location.host}
      }}
      useRefreshTokens={true} // Enable token refreshing
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
