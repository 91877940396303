// reducers/authReducer.js
import { SET_TOKEN, SET_USER, CLEAR_AUTH,LOGIN_FAILURE,SET_CHECK_STATUS, LOGOUT_USER } from '../actions/actionTypes';

const initialState = {
  token: null,
  userList: null,
};

// Initial state
const initialActiveState = {
  checkStatus: true, // Assuming the user is authenticated by default
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        userList: action.payload,
      };
    case CLEAR_AUTH:
      return {
        ...state,
        token: null,
        userList: null,
      };
      case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

// Reducer to manage the state of authentication
export const activeSatauReducer = (state = initialActiveState, action) => {
  switch (action.type) {
    case SET_CHECK_STATUS:
      return {
        ...state,
        checkStatus: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        checkStatus: false,
      };
    default:
      return state;
  }
};
 