import {
  GET_SP_INV_DOCUMENT_FAILURE,
  GET_SP_INV_DOCUMENT_REQUEST,
  GET_SP_INV_DOCUMENT_SUCCESS,
  GET_SP_INV_PROFILE_FAILURE,
  GET_SP_INV_PROFILE_REQUEST,
  GET_SP_INV_PROFILE_SUCCESS,
  SPEND_INVOICE_FAILURE,
  SPEND_INVOICE_REQUEST,
  SPEND_INVOICE_SUCCESS,
  INVOICE_TIMEENTRIES_REQUEST,
  INVOICE_TIMEENTRIES_SUCCESS,
  INVOICE_TIMEENTRIES_FAILURE,
  INVOICE_EXPENSES_REQUEST,
  INVOICE_EXPENSES_SUCCESS,
  INVOICE_EXPENSES_FAILURE,
} from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const initialSpendState = {
  loading: false,
  spendMtgInvoiceTimesheet: [],
  error: "",
};

const initialTimeEntrieState = {
  invTimeEntries: [],
  loading: false,
  error: null,
};

const initialExpensesState = {
  invExpenses: [],
  loading: false,
  error: null,
};

export const spendMtgInvoiceReducer = (state = initialSpendState, action) => {
  switch (action.type) {
    case SPEND_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SPEND_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        spendMtgInvoiceTimesheet: action.payload,
      };
    case SPEND_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SpIvProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SP_INV_PROFILE_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SP_INV_PROFILE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_SP_INV_PROFILE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const SpIvDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SP_INV_DOCUMENT_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_SP_INV_DOCUMENT_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case GET_SP_INV_DOCUMENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const InvTimeEntiresReducer = (
  state = initialTimeEntrieState,
  action
) => {
  switch (action.type) {
    case INVOICE_TIMEENTRIES_REQUEST:
      return { ...state, loading: true, error: null };
    case INVOICE_TIMEENTRIES_SUCCESS:
      return { ...state, loading: false, invTimeEntries: action.payload };
    case INVOICE_TIMEENTRIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const InvExpensesReducer = (state = initialExpensesState, action) => {
  switch (action.type) {
    case INVOICE_EXPENSES_REQUEST:
      return { ...state, loading: true, error: null };
    case INVOICE_EXPENSES_SUCCESS:
      return { ...state, loading: false, invExpenses: action.payload };
    case INVOICE_EXPENSES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
