import React, { useEffect } from "react";
import {
  setActiveSPProfileIvNavLink,
  viewSpendPDFDocument,
} from "../../../redux/actions/spendManagementActions";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/SpInvProfile.scss";
import CustomSpinner from "../../../components/Commons/CustomSpinner";
import {
  fetchSpInvDocuments,
  fetchSpInvProfile,
} from "../../../redux/actions/spendMtgInvoiceAction";
import constants from "../../../helpers/en";

function InvProfile() {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.spIvProfile?.data);
  const documentData = useSelector((state) => state.spIvDocument?.data || []);

  const id = sessionStorage?.getItem("Id");
  const invoiceNo = sessionStorage.getItem("invoiceNo");
  const invoiceDate = sessionStorage.getItem("invoiceDate");
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  };

  const formattedDate = id ? formatDate(invoiceDate) : "";

  useEffect(() => {
    dispatch(setActiveSPProfileIvNavLink(""));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSpInvProfile(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSpInvDocuments(id));
  }, [dispatch]);

  const handleEmailClick = (email) => {
    // Dynamically generate the mailto link
    window.location.href = `mailto:${email}`;
  };

  const viewConfirmationLink = (event) => {
    event.preventDefault();
    dispatch(
      viewSpendPDFDocument(documentData[0]?.Id, documentData[0]?.fileType)
    );
  };
  const ConfirmationLink = () => {
    const link = getProfileValue("confirmationLink");
    if (link) {
      return (
        <a
          href={link}
          onClick={viewConfirmationLink}
          className={
            documentData === constants.NO_RECORD_FOUND_MESSAGE
              ? "confirmationUnLink"
              : "confirmationLink"
          }
        >
          {documentData === constants.NO_RECORD_FOUND_MESSAGE
            ? "-"
            : constants.LINK}
        </a>
      );
    } else {
      return <span>{getProfileValue("invoicecopy")}</span>;
    }
  };

  const getProfileValue = (key) => profileData?.[0]?.[key] || "-";

  if (!profileData || profileData.length === 0) {
    return (
      <div className="sc-profile-container flex-grow-1 overflow-auto">
        <CustomSpinner />
      </div>
    );
  }

  return (
    <div className="sp-inv-profile-container flex-grow-1 overflow-auto">
      <div className="sp-iv-pr-inner-container">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <p className="heading_name bold-barlow">{invoiceNo}</p>
            <p className="assign_Num">Invoice Date: {formattedDate}</p>
          </div>
          <div className="d-flex flex-column">
            <p className="heading_name">
              For Invoice Support Email: ar@hayeslocums.com
            </p>
            <p className="assign_Num">
              For Invoice Disputes Email:{" "}
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  handleEmailClick("ainvoiceinquiries@hayeslocums.com");
                }}
              >
                ainvoiceinquiries@hayeslocums.com
              </a>
            </p>
          </div>
        </div>
        <div className="card-container d-flex">
          <div className="pr-ivcard-container">
            <div className="row g-0 pr-row">
              <div className="col-6">
                <p className="bold-barlow">Provider</p>
              </div>
              <div className="col-6">{getProfileValue("providerName")}</div>
              <div className="col-6">
                <p className="bold-barlow">Invoice Date</p>
              </div>
              <div className="col-6">{getProfileValue("invoiceDate")}</div>
              <div className="col-6">
                <p className="bold-barlow">Payment Terms</p>
              </div>
              <div className="col-6">{getProfileValue("paymentTerms")}</div>
              <div className="col-6">
                <p className="bold-barlow">Invoice Copy</p>
              </div>
              <div className="col-6">
                <a href="#">{ConfirmationLink()}</a>
              </div>
              <div className="col-6">
                <p className="bold-barlow">Client</p>
              </div>
              <div className="col-6">{getProfileValue("clientName")}</div>
              <div className="col-6">
                <p className="bold-barlow">AR Specialist</p>
              </div>
              <div className="col-6">{getProfileValue("arSpecialist")}</div>
            </div>
          </div>
          <div className="pr-ivcard-container">
            <div className="row g-0">
              <div className="col-6">
                <p className="bold-barlow">Specialty</p>
              </div>
              <div className="col-6">{getProfileValue("specialty")}</div>
              <div className="col-6">
                <p className="bold-barlow">Aging Date</p>
              </div>
              <div className="col-6">{getProfileValue("agingDate")}</div>
              <div className="col-6">
                <p className="bold-barlow">Amount</p>
              </div>
              <div className="col-6">{getProfileValue("amount")}</div>
              <div className="col-6">
                <p className="bold-barlow">Facility</p>
              </div>
              <div className="col-6">{getProfileValue("facilityName")}</div>
              <div className="col-6">
                <p className="bold-barlow">Confirmation Number</p>
              </div>
              <div className="col-6">
                {getProfileValue("confirmationNumber")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvProfile;
