import axios from "axios";
import {
  GET_SP_INV_DOCUMENT_FAILURE,
  GET_SP_INV_DOCUMENT_REQUEST,
  GET_SP_INV_DOCUMENT_SUCCESS,
  GET_SP_INV_PROFILE_FAILURE,
  GET_SP_INV_PROFILE_REQUEST,
  GET_SP_INV_PROFILE_SUCCESS,
  SPEND_INVOICE_FAILURE,
  SPEND_INVOICE_REQUEST,
  SPEND_INVOICE_SUCCESS,
  INVOICE_TIMEENTRIES_REQUEST,
  INVOICE_TIMEENTRIES_SUCCESS,
  INVOICE_TIMEENTRIES_FAILURE,
  INVOICE_EXPENSES_REQUEST,
  INVOICE_EXPENSES_SUCCESS,
  INVOICE_EXPENSES_FAILURE,
} from "./actionTypes";
import { headersRequest } from "../../helpers/commonUtills";

export const getSpendMtgInvoice = (
  limit = 100,
  offset = 0,
  accountNumber = "",
  worksitwAccountNumber = "",
  confirmationNumber = ""
) => {
  return (dispatch) => {
    dispatch({ type: SPEND_INVOICE_REQUEST });

    const url = `${process.env.REACT_APP_API_URL}/spendManagementInvoices?limit=${limit}&offset=${offset}&accountNumber=${accountNumber}&worksitwAccountNumber=${worksitwAccountNumber}&confirmationNumber=${confirmationNumber}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({
          type: SPEND_INVOICE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: SPEND_INVOICE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchSpInvProfile = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SP_INV_PROFILE_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/spendManagementInvoices/profile?Id=${id}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_SP_INV_PROFILE_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: GET_SP_INV_PROFILE_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

export const invoiceTimeEntries = (
  limit = 1000,
  offset = 0,
  confirmationNumber = "",
  Id = ""
) => {
  return (dispatch) => {
    dispatch({ type: INVOICE_TIMEENTRIES_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/spendManagementInvoices/timeEntries?limit=${limit}&offset=${offset}&confirmationNumber=${confirmationNumber}&Id=${Id}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: INVOICE_TIMEENTRIES_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: INVOICE_TIMEENTRIES_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

export const invoiceExpense = (
  limit = 1000,
  offset = 0,
  confirmationNumber = "",
  Id = ""
) => {
  return (dispatch) => {
    dispatch({ type: INVOICE_EXPENSES_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/spendManagementInvoices/expenses?limit=${limit}&offset=${offset}&confirmationNumber=${confirmationNumber}&Id=${Id}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: INVOICE_EXPENSES_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: INVOICE_EXPENSES_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};

export const fetchSpInvDocuments = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_SP_INV_DOCUMENT_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/spendManagementInvoices/document?Id=${id}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: GET_SP_INV_DOCUMENT_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch documents";
        const errorCode = error.response?.status || "Unknown Error Code";
        console.error(`API Error (${errorCode}): ${errorMessage}`);
        console.log("error", error.message);
        dispatch({
          type: GET_SP_INV_DOCUMENT_FAILURE,
          payload: error.response?.data?.message || "Failed to fetch profile",
        });
      });
  };
};
