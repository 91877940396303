import React, { useRef } from "react";
import "primereact/resources/themes/saga-green/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "../../../../assets/css/AddReport.scss";
import "../../../../assets/css/DashboardPages.scss";
import { ReactComponent as ReportDelete } from "../../../../assets/images/icons/AddReportDelete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import constants from "../../../../helpers/en";
import { Formik, Field, Form, ErrorMessage } from "formik";
import CustomTooltip from "../../../../components/Commons/CustomTooltip";
import { useDispatch } from "react-redux";
import { editDashboardReports } from "../../../../redux/actions/dashboardActions";
import CustomToast from "../../../../components/Commons/CustomToast";
import DOMPurify from "dompurify";
import { editReportValidationSchema } from "../../../../helpers/commonUtills";

// Moved out component definitions
const reportNameTemplate = (props) => {
  return (
    <>
      <Field name="report_name" as={InputText} placeholder="Add Report Name" />
      <ErrorMessage name="report_name" component="div" className="error-text" />
    </>
  );
};

const reportURLTemplate = (props) => {
  return (
    <>
      <div className="column-reportname-url-field">
        <Field name="iframe_url" as={InputText} placeholder="Add URL" />
        <ErrorMessage
          name="iframe_url"
          component="div"
          className="error-text"
        />
      </div>
      <button className="submit-btn" type="submit">
        {constants.BUTTON_SUBMIT}
      </button>
    </>
  );
};

const actionTemplate = (props) => {
  return (
    <CustomTooltip content={"Clear Rows"}>
      <ReportDelete className="addreport-icons" onClick={props.handleReset} />
    </CustomTooltip>
  );
};

function LandingPageDashboardEditReport() {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const navigateBack = () => {
    navigate(-1);
  };
  const initialData = [
    {
      dashboard_id: data?.dashboard_id,
      report_name: data?.report_name,
      iframe_url: data?.iframe_url,
    },
  ];
  const initialValues = {
    dashboard_id: initialData[0]?.dashboard_id,
    report_name: initialData[0]?.report_name,
    iframe_url: initialData[0]?.iframe_url,
  };
  
  const handleReset = (setFieldValue) => {
    setFieldValue("report_name", "");
    setFieldValue("iframe_url", "");
  };

  return (
    <div className="px-4 py-3 flex-grow-1 overflow-auto dashboard-pages">
      <CustomToast ref={toastRef} position="top-right" />
      <Formik
        initialValues={initialValues}
        validationSchema={editReportValidationSchema}
        onSubmit={(values) => {
          const dashboardReportPayload = {
            dashboard_id: data?.dashboard_id,
            report_name: DOMPurify.sanitize(values?.report_name),
            iframe_url: DOMPurify.sanitize(values?.iframe_url),
            facility_account_no: data?.facility_account_no,
            worksite_account_no: data?.worksite_account_no,
            page_type: data?.page_type,
            is_active: data?.is_active,
          };
          dispatch(
            editDashboardReports(dashboardReportPayload, toastRef, navigateBack)
          );
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form>
            <DataTable
              className="table-responsive"
              value={initialData}
              stateStorage="session"
              stateKey="dt-state-demo-local"
            >
              <Column
                className="column-reportname"
                header="Report Name"
                body={reportNameTemplate}
                style={{ width: "18rem" }}
              />
              <Column
                className="column-reportname-url"
                header="URL"
                body={reportURLTemplate}
                style={{ width: "55rem" }}
              />
              <Column
                className="column-actions"
                headerClassName="column-actions-header"
                header="Action"
                body={(props) => actionTemplate({ ...props, handleReset })}
              />
            </DataTable>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LandingPageDashboardEditReport;
