import React from "react";
import { Link } from "react-router-dom";
import { clearSelections } from "../redux/actions/dashboardListActions";
import { getRolePermissions } from "../utils/roles";
import { jwtDecode } from "jwt-decode";
import { setPermissions } from "../redux/actions/rollActions";
import constants from "./en";
import { getDashboardReportsList } from "../redux/actions/dashboardActions";
import {
  setToken,
  setUser,
  clearAuth,
  loginFailure,
} from "../redux/actions/authActions";
import avtar from "../assets/images/icons/avtar.svg";
export const initializeData = (dispatch) => {
  dispatch(clearSelections());
  dispatch(getDashboardReportsList());
};

export const handleLogoClick = (navigate, setActiveMenu) => {
  navigate("");
  localStorage.setItem("activeMenu", "");
};

  export const fetchAuthToken = async (dispatch, user, getAccessTokenSilently) => {
  try {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_Auth0Audience,
      scope: process.env.REACT_APP_Auth0Scope,
      detailedResponse: true,
      useRefreshTokens: true,
    });
    const decodedToken = jwtDecode(token?.access_token);
    const tokenData = decodedToken["custom-data"]?.[0] || {};
    const roleAndPermission = getRolePermissions(tokenData);
      localStorage.setItem("sessionTime", JSON.stringify({ startTime: Date.now() }));
    dispatch(setPermissions(roleAndPermission));
    dispatch(setToken(token));
    dispatch(setUser(user));
  } catch (error) {
    dispatch(loginFailure(error || "Login failed"));
    console.error("Error fetching access token:", error);
  }
};
export const handleReset = (logout) => {
  logout({ returnTo: window.location.origin });
};

export const handleLogout = (logout, navigate, dispatch) => {
  dispatch(clearAuth());
  localStorage.clear();
  sessionStorage.clear();
  logout({ returnTo: window.location.origin });
  navigate("/");
};

export const renderProfileSection = (isMobile, userList, handleLogout) => (
  <div className="d-flex align-items-center ms-auto profile-section">
    {/* Profile Image */}
    <button
      className="dropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      style={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
      }}
    >
       
    <img src={userList?.picture || avtar} alt="Profile" className="profile-img" />
     
    </button>

    {/* Welcome Text */}
    <span className="welcm-name">
        {userList?.nickname ? `${constants.WELCOME} ${userList?.nickname}` : ''}
    </span>

    {/* Dropdown for non-mobile devices */}
    {!isMobile && userList?.nickname && (
      <button
        className="btn custom-dropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="pi pi-chevron-down"></i>
      </button>
    )}

    {/* Dropdown Menu */}
    <ul className="dropdown-menu dropdown-menu-end">
      <li>
        <Link className="dropdown-item" to="profile">
          {constants.MY_PROFILE}
        </Link>
      </li>
      <li>
        <Link
          className="dropdown-item"
          to="#"
          onClick={handleLogout} // Ensure handleLogout is passed correctly
        >
          {constants.LOGOUT}
        </Link>
      </li>
    </ul>
  </div>
);

export const renderAdminMenu = (activeMenu, handleMenuClick) => (
    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
    <ul className="navbar-nav">
      <li className={activeMenu === "" ? "active" : "inactive"}>
        <Link
          to=""
          className="nav-link"
          aria-current={activeMenu === "" ? "page" : undefined}
          onClick={() => handleMenuClick("")} // Move onClick to Link
        >
          {constants.FACILITY}
        </Link>
      </li>
        {/* <li className={activeMenu === "dashboard" ? "active" : "inactive"}>
        <Link
          to="dashboard"
          className="nav-link"
          onClick={() => handleMenuClick("dashboard")} // Move onClick to Link
        >
          {constants.DASHBOARD}
        </Link>
        </li> */}
        <li className={activeMenu === "schedule-management" ? "active" : "inactive"}>
        <Link
          to="schedule-management"
          className="nav-link"
          onClick={() => handleMenuClick("schedule-management")} // Move onClick to Link
        >
          {constants.SCHEDULING}
        </Link>
      </li>
      <li className={activeMenu === "spend-management" ? "active" : "inactive"}>
        <Link
          to="spend-management"
          className="nav-link"
          onClick={() => handleMenuClick("spend-management")} // Move onClick to Link
        >
          {constants.SPEND_MANAGEMENT}
        </Link>
      </li>
    </ul>
  </div>
);

  
  export const renderFacilityAdminMenu = (rolePermission, activeMenu, handleMenuClick) => (
    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
    <ul className="navbar-nav">
      <li className={activeMenu === "" ? "active" : "inactive"}>
        <Link
          to=""
          className="nav-link"
          aria-current={activeMenu === "" ? "page" : undefined}
          onClick={() => handleMenuClick("")} // Move onClick to Link
        >
          {constants.HOME}
        </Link>
      </li>
      {/* <li className={activeMenu === "dashboard" ? "active" : "inactive"}>
        <Link
          to="dashboard"
          className="nav-link"
          onClick={() => handleMenuClick("dashboard")} // Move onClick to Link
        >
          {constants.DASHBOARD}
        </Link>
        </li> */}
      {rolePermission?.permissions?.scheduling && (
          <li className={activeMenu === "schedule-management" ? "active" : "inactive"}>
          <Link
            to="schedule-management"
            className="nav-link"
            onClick={() => handleMenuClick("schedule-management")} // Move onClick to Link
          >
            {constants.SCHEDULING}
          </Link>
        </li>
      )}
      {rolePermission?.permissions?.spend_management_menu && (
      
        <li className={activeMenu === "spend-management" ? "active" : "inactive"}>
          <Link
            to="spend-management"
            className="nav-link"
            onClick={() => handleMenuClick("spend-management")} // Move onClick to Link
          >
            {constants.SPEND_MANAGEMENT}
          </Link>
        </li>
      )}
    </ul>
  </div>
);

  export const renderFacilityUserMenu = (rolePermission, activeMenu, handleMenuClick) => (
    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
    <ul className="navbar-nav">
      <li className={activeMenu === "" ? "active" : "inactive"}>
        <Link
          to=""
          className="nav-link"
          aria-current={activeMenu === "" ? "page" : undefined}
          onClick={() => handleMenuClick("")} // Move onClick to Link
        >
          {constants.HOME}
        </Link>
      </li>
      {/* <li className={activeMenu === "dashboard" ? "active" : "inactive"}>
          <Link
            to="dashboard"
            className="nav-link"
            onClick={() => handleMenuClick("dashboard")} // Move onClick to Link
          >
            {constants.DASHBOARD}
          </Link>
        </li> */}
      {rolePermission?.permissions?.scheduling && (
          <li className={activeMenu === "schedule-management" ? "active" : "inactive"}>
          <Link
            to="schedule-management"
            className="nav-link"
            onClick={() => handleMenuClick("schedule-management")} // Move onClick to Link
          >
            {constants.SCHEDULING}
          </Link>
        </li>
      )}
      {rolePermission?.permissions?.spend_management_menu && (
     
        <li className={activeMenu === "spend-management" ? "active" : "inactive"}>
          <Link
            to="spend-management"
            className="nav-link"
            onClick={() => handleMenuClick("spend-management")} // Move onClick to Link
          >
            {constants.SPEND_MANAGEMENT}
          </Link>
        </li>
      )}
    </ul>
  </div>
);
