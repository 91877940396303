// src/redux/reducers/roleReducer.js
import { SET_PERMISSIONS } from "../actions/actionTypes";

const initialState = {
   permissions: [],
 };

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
        return {
            loading: false,
            permissions: action.payload,
            error: "",
          };
       
    default:
      return state;
  }
};

export default roleReducer;
