import React, { memo, useEffect, useRef, useState } from "react";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/AddNewUser.scss";
import "../../../assets/css/InputSwitch.scss";
import RegularButton from "../../../components/Commons/RegularButton";
import { Dropdown } from "primereact/dropdown";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Personadata1, USER_TYPES } from "../../../helpers/enums";
import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types"; // Import PropTypes
import { useDispatch, useSelector } from "react-redux";
import {
  editFacilityUser,
  fetchFacilityUser,
} from "../../../redux/actions/facilityActions";
import { MultiSelect } from "primereact/multiselect";
import CustomToast from "../../../components/Commons/CustomToast";
import * as Yup from "yup";
import constants from "../../../helpers/en";
import CustomSpinner from "../../../components/Commons/CustomSpinner";

const EditFacilityUser = memo((props) => {
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const closeEditFacilityUser = props.closeEditFacilityUser;
  const id =
    rolePermission?.role === USER_TYPES.SUPER_ADMIN
      ? props?.id
      : props?.user?.facility_account_no;
  const originalArray = props.user?.personaData;
  const capitalizedPropArray = originalArray.map(
    (value) => value.charAt(0).toUpperCase() + value.slice(1)
  );

  const propUserDataId = props.user?.salesforce_id;
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [candidates, setCandidates] = useState(props.user?.candidates);
  const [scheduling, setScheduling] = useState(props.user?.scheduling);
  const [spendManagement, setSpendManagement] = useState(
    props.user?.spend_management_dashboard
  );

  const [locumsAnalytics, setLocumsAnalytics] = useState(
    props.user?.locums_analytics
  );
  const [scheduledReport, setScheduledReport] = useState(
    props.user?.scheduled_report
  );

  const [landingPage, setLandingPage] = useState(props.user?.landing_page);
  const [scheduleManagement, setScheduleManagement] = useState(
    props.user?.schedule_management
  );
  const [spendManagementMenu, setSpendManagementMenu] = useState(
    props.user?.spend_management_menu
  );
  const [selectedPersonas, setSelectedPersonas] =
    useState(capitalizedPropArray);
  const [result, setResult] = useState({});
  const clientPropsData = props?.user;
  const initialValues = {
    userType: selectedPersonas,
    addUser: selectedUser,
    fullName: selectedUser?.fullName,
    title: selectedUser?.title,
    phoneNumber: selectedUser?.phone,
    email: selectedUser?.email,
    accountName: props.user?.account_name,
  };
  //redux selectors
  const { data } = useSelector((state) => state.getFacilityUser);
  const personaKeys = Object.keys(Personadata1[0]?.personaData);
  const capitalizedArray = personaKeys.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1);
  });

  useEffect(() => {
    dispatch(fetchFacilityUser(props.user?.facility_account_no));
  }, [dispatch]);

  useEffect(() => {
    const resultObject = selectedPersonas.reduce((acc, persona) => {
      acc[persona.toLowerCase()] = true;
      return acc;
    }, {});
    setResult(resultObject);
  }, [selectedPersonas]);

  useEffect(() => {
    // Find the object with the same salesforceId
    const foundObject = data.find(
      (item) => item.salesforceId === propUserDataId
    );
    // Update the state with the found object
    setSelectedUser(foundObject || null);
    if (foundObject) {
      setLoading(false);
    }
  }, [data, dispatch]);

  const handlePersonaSelection = (selected) => {
    setSelectedPersonas(selected);

    const resultObject = selectedPersonas.reduce((acc, persona) => {
      acc[persona.toLowerCase()] = true;
      return acc;
    }, {});
    setResult(resultObject);
  };

  const validationSchema = Yup.object({
    userType: Yup.array()
      .min(1, constants.FIELD_REQUIRED)
      .required(constants.FIELD_REQUIRED),
    addUser: Yup.mixed().required(constants.FIELD_REQUIRED),
  });

  const handleUserChange = (optionValue, setFieldValue) => {
    setFieldValue("fullName", optionValue?.fullName || "");
    setFieldValue("title", optionValue?.title || "");
    setFieldValue("phoneNumber", optionValue?.address?.phoneNumber || "");
    setFieldValue("email", optionValue?.address?.email || "");
    setFieldValue("accountName", clientPropsData?.account_name || "");
  };

  return (
    <div className=" modal-overlay d-flex ">
      <CustomToast ref={toastRef} position="top-right" />
      <div className="d-flex w-100 justify-content-center ">
        {loading ? (
          <CustomSpinner />
        ) : (
          <div className="modal-container">
            <div className="d-flex justify-content-between mx-3 align-items-center">
              <h2 className="popup-head-text">
                {constants.EDIT_FACILITY_USER}
              </h2>
              <div>
                <Close
                  className="popup-close-btn"
                  onClick={props.closeEditFacilityUser}
                />
              </div>
            </div>
            <div className="ar-main-container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values) => {
                  const userData = [
                    {
                      user_id: clientPropsData?.user_id,
                      facility_account_no: clientPropsData?.facility_account_no,
                      full_name: selectedUser?.fullName,
                      title: selectedUser?.title || "",
                      is_active: true,
                      email_verified: false,
                      email: selectedUser?.email || "",
                      account_name: clientPropsData?.account_name || "",
                      role: "Facility User",
                      phone: selectedUser?.phone || "",
                      tableau_subscription: false,
                      salesforce_id: selectedUser?.salesforceId,
                    },
                  ];

                  const AccessData = [
                    {
                      user_id: clientPropsData?.user_id,
                      candidates: candidates,
                      scheduling: scheduling,
                      spend_management_dashboard: spendManagement,
                      locums_analytics: locumsAnalytics,
                      scheduled_report: scheduledReport,
                      landing_page: landingPage,
                      schedule_management: scheduleManagement,
                      spend_management_menu: spendManagementMenu,
                    },
                  ];

                  result.user_id = clientPropsData?.user_id;
                  const userTypeData = result;

                  dispatch(
                    editFacilityUser(
                      userData,
                      AccessData,
                      userTypeData,
                      toastRef,
                      closeEditFacilityUser,
                      id
                    )
                  );
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className=" form-container d-flex flex-wrap">
                      <div className="ar-input-card">
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 bold-barlow ar-label"
                            htmlFor="parent-account"
                            name="parent-account"
                          >
                            Parent account
                          </label>
                          <input
                            className="form-control col-8 ar-input"
                            name="parent-account"
                            value={props.user?.account_name}
                            disabled
                            id="parent-account"
                          />
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 star-after bold-barlow ar-label"
                            htmlFor="userType"
                            name="userType"
                          >
                            Select User Type
                          </label>
                          <div className=" col-8 worksite-dropdown">
                            <MultiSelect
                              id="userType"
                              className="w-100"
                              value={selectedPersonas}
                              onChange={(e) => handlePersonaSelection(e.value)}
                              options={capitalizedArray}
                              placeholder="Select user persona"
                            />
                            <ErrorMessage
                              name="userType"
                              component="div"
                              className="error-text"
                            />
                          </div>
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 bold-barlow ar-label"
                            htmlFor="client-portal-user"
                            name="client-portal-user"
                          >
                            Client Portal User
                          </label>
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 star-after bold-barlow ar-label"
                            htmlFor="addUser"
                            name="addUser"
                          >
                            Add user
                          </label>
                          <div className=" col-8 worksite-dropdown">
                            <Dropdown
                              className="w-100"
                              id="addUser"
                              name="addUser"
                              value={values.addUser}
                              options={Array.isArray(data) ? data : []}
                              onChange={(e) => {
                                setSelectedUser(e.value);
                                setFieldValue("addUser", e.value);
                                handleUserChange(e.value, setFieldValue);
                              }}
                              optionLabel="fullName"
                              placeholder="Select a user"
                              filter
                              emptyFilterMessage="No data available"
                              emptyMessage="No data available"
                              filterBy="fullName"
                              filterPlaceholder="Search"
                              showClear
                            />
                            <ErrorMessage
                              name="addUser"
                              component="div"
                              className="error-text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ar-input-card">
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 bold-barlow ar-label"
                            htmlFor="userDetails"
                            name="userDetails"
                          >
                            User details
                          </label>
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 ar-label"
                            htmlFor="fullName"
                            name="fullName"
                          >
                            Full Name
                          </label>
                          <Field
                            readOnly
                            disabled
                            className="form-control col-8 ar-input"
                            name="fullName"
                            placeholder="John Doe"
                            id="fullName"
                          />
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 ar-label"
                            htmlFor="title"
                            name="title"
                          >
                            Title
                          </label>
                          <Field
                            readOnly
                            disabled
                            className="form-control col-8 ar-input"
                            name="title"
                            placeholder="(eg., HR, Hiring Manager, Medical Director)"
                            id="title"
                          />
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 ar-label"
                            htmlFor="phoneNumber"
                            name="phoneNumber"
                          >
                            Phone Number
                          </label>
                          <Field
                            readOnly
                            disabled
                            className="form-control col-8 ar-input"
                            name="phoneNumber"
                            placeholder="(123)987-6543"
                            id="phoneNumber"
                          />
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 ar-label"
                            htmlFor="email"
                            name="email"
                          >
                            Email
                          </label>
                          <Field
                            readOnly
                            disabled
                            className="form-control col-8 ar-input"
                            name="email"
                            placeholder="abc@xyz.com"
                            id="email"
                          />
                        </div>
                        <div className="row g-0 my-1">
                          <label
                            className="col-4 ar-label"
                            htmlFor="accountName"
                            name="accountName"
                          >
                            Account Name
                          </label>
                          <Field
                            readOnly
                            disabled
                            className="form-control col-8 ar-input"
                            name="accountName"
                            placeholder="Account Name"
                            id="accountName"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ar-dpcontainer">
                      <p className="bold-barlow ar-permission-label">
                        Dashboard Permissions : Salesforce Embedded/Read-Only &
                        Tableau Access
                      </p>
                      <div className="ar-row">
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Candidates</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={candidates}
                              onChange={(e) => setCandidates(e.value)}
                            />
                          </div>
                        </div>
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Scheduling</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={scheduling}
                              onChange={(e) => setScheduling(e.value)}
                            />
                          </div>
                        </div>
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Spend Management</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={spendManagement}
                              onChange={(e) => setSpendManagement(e.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="ar-row">
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Locums Analytics</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={locumsAnalytics}
                              onChange={(e) => setLocumsAnalytics(e.value)}
                            />
                          </div>
                        </div>
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Scheduled Report</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={scheduledReport}
                              onChange={(e) => setScheduledReport(e.value)}
                            />
                          </div>
                        </div>
                        <div className="row g-0 my-1 ar-row-column">
                          <div className="col-6 ar-dp-label">
                            <p>Landing Page</p>
                          </div>
                          <div className="col-6 ar-switch-container">
                            <InputSwitch
                              className=" switch"
                              checked={landingPage}
                              onChange={(e) => setLandingPage(e.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <p className="bold-barlow ar-permission-label">
                        Manu Permissions : Salesforce Embedded/Read-Only &
                        Tableau Access
                      </p>

                      <div className="ar-row">
                        <div className="ar-row-column">
                          <div className="row g-0 my-1">
                            <div className="col-6 ar-dp-label">
                              <p>Schedule management</p>
                            </div>
                            <div className="col-6 ar-switch-container">
                              <InputSwitch
                                className=" switch"
                                checked={scheduleManagement}
                                onChange={(e) => setScheduleManagement(e.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ar-row-column">
                          <div className="row g-0 my-1">
                            <div className="col-6 ar-dp-label">
                              <p>Spend Management</p>
                            </div>
                            <div className="col-6 ar-switch-container">
                              <InputSwitch
                                className=" switch"
                                checked={spendManagementMenu}
                                onChange={(e) =>
                                  setSpendManagementMenu(e.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex p-2 justify-content-end">
                      <RegularButton
                        className="me-2"
                        color={"#ffffff"}
                        backgroundColor={"#8e94a1"}
                        btnname={"Reset"}
                        // onClick={() => resetForm()}
                      />
                      <RegularButton
                        color={"#424c63"}
                        backgroundColor={"#00e676"}
                        type={"submit"}
                        // onClick={successNavigate}
                        btnname={"Update"}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
// PropTypes for the props passed to the component
EditFacilityUser.propTypes = {
  closeEditFacilityUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    account_name: PropTypes.string,
    facility_account_no: PropTypes.number,
    spend_management_dashboard: PropTypes.bool,
    locums_analytics: PropTypes.bool,
    scheduled_report: PropTypes.bool,
    landing_page: PropTypes.bool,
    schedule_management: PropTypes.bool,
    spend_management_menu: PropTypes.bool,
    scheduling: PropTypes.bool,
    candidates: PropTypes.bool,
    salesforce_id: PropTypes.string,
    id: PropTypes.number,
    personaData: PropTypes.object,
  }),
  id: PropTypes.number,
};
export default EditFacilityUser;
