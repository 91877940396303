import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import RegularButton from "../../../components/Commons/RegularButton";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import {
  getAdminSFList,
  getFacilitySFList,
  getFacilityDBList,
  editFacility,
} from "../../../redux/actions/facilityActions";
import constants from "../../../helpers/en";
import { ReactComponent as Close } from "../../../assets/images/icons/popupClose.svg";
import "../../../assets/css/EditFacilityPopup.scss";

function EditFacilityPopup(props) {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const { admins } = useSelector((state) => state.admin);

  const [isSubscribed, setIsSubscribed] = useState(
    props.hospital?.tableau_subscription_ud
  );
  const mapDataToState = (props) => ({
    fullName: props.hospital?.full_name_ud,
    title: props.hospital?.title_ud,
    phone: props.hospital?.phone_ud,
    email: props.hospital?.email_ud,
    accountName: props.hospital?.account_name_fd,
    specialty: props.hospital?.specialities_fd,
  });

  const [selectedUser, setSelectedUser] = useState(mapDataToState(props));

  const closePopup = props.closeEditFacility;

  useEffect(() => {
    // Fetch facility list on mount
    dispatch(getFacilityDBList());
  }, [dispatch]);

  useEffect(() => {
    const hospitalId = props.hospital?.facility_account_no_fd;
    dispatch(getAdminSFList(hospitalId));
  }, [props.hospital]);
  const resetDropdownValue = () => {
    dispatch(getFacilitySFList());
    dispatch(getAdminSFList());
  };
  const handleAdminChange = (selectedOption, setFieldValue) => {
    setFieldValue("fullName", selectedOption?.fullName || "");
    setFieldValue("title", selectedOption?.title || "");
    setFieldValue("phone", selectedOption?.phone || "");
    setFieldValue("email", selectedOption?.email || "");
  };

  return (
    <div className=" modal-overlay">
      <Toast ref={toast} />
      <div className="d-flex w-100 justify-content-center">
        <div className="modal-container">
          <div className="d-flex justify-content-between mx-3 align-items-center">
            <h2 className="popup-head-text">{constants.EDIT_FACILITY}</h2>
            <div>
              <Close
                className="popup-close-btn"
                onClick={() => {
                  closePopup();
                  resetDropdownValue();
                }}
              />
            </div>
          </div>

          <div className="rounded-3 form-container p-3">
            <Formik
              initialValues={{
                admin: "",
                id: "",
                website: "",
                hospitalType: "",
                fullName: selectedUser?.fullName,
                title: selectedUser?.title,
                phone: selectedUser?.phone,
                account_Name: selectedUser?.accountName,
                hospitalId: "",
              }}
              onSubmit={(values) => {
                const adminPayload = [
                  {
                    user_id: props.hospital?.user_id_ud,
                    facility_account_no: props.hospital?.facility_account_no_ud,
                    full_name: selectedUser?.fullName,
                    title: selectedUser?.title,
                    is_active: props.hospital?.is_active_ud,
                    // email_verified: false,
                    email_verified: true,
                    email: selectedUser?.email,
                    account_name: props.hospital?.account_name_fd,
                    role: "Facility Admin",
                    phone: selectedUser?.phone,
                    tableau_subscription: isSubscribed,
                  },
                ];
                dispatch(editFacility(adminPayload, toast, closePopup));
              }}
            >
              {({ values, setFieldValue, resetForm }) => (
                <Form>
                  <div className="row w-50 g-0 md-2">
                    <label
                      className="col-4 star-after bold "
                      htmlFor="hospital"
                      name="hospital"
                    >
                      {constants.ACCOUNT_NAME}
                    </label>
                    <div className="col-8 col-md-6 mb-3 worksite-dropdown">
                      <Field
                        disabled
                        className="account-name-input"
                        name="hospital"
                        value={props.hospital?.account_name_fd}
                        placeholder="Account Name"
                        id="hospital"
                      />
                    </div>
                  </div>

                  <p className="main-text bold">
                    {constants.FACILITY_INFORMATION}
                  </p>

                  <div className="row g-0 px-2 px-md-7">
                    <div className="col-12 col-md-6 ">
                      <div className="row ">
                        <label className="col-4 " htmlFor="name">
                          {constants.ACCOUNT_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50 mb-4 col-8"
                          name="name"
                          value={props.hospital?.account_name_fd}
                          placeholder="Full legal name of the hospita or healthcare facility"
                          id="name"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="accountStatus">
                          {constants.ACCOUNT_STATUS}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="accountStatus"
                          value={props.hospital?.account_status_fd}
                          placeholder="Website"
                          id="accountStatus"
                        />
                      </div>

                      <div className="row">
                        <label className="col-4" htmlFor="street">
                          Address
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="street"
                          value={props.hospital?.street_fd}
                          placeholder="Street"
                          id="street"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label" htmlFor="city"></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="city"
                          value={props.hospital?.city_fd}
                          placeholder="City"
                          id="city"
                        />
                      </div>
                      <div className="row">
                        <div
                          className="col-4 empty-label"
                          htmlFor="state"
                        ></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="state"
                          value={props.hospital?.state_fd}
                          placeholder="State"
                          id="state"
                        />
                      </div>
                      <div className="row">
                        <div
                          className="col-4 empty-label"
                          htmlFor="zipCode"
                        ></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="zipCode"
                          value={props.hospital?.zip_code_fd}
                          placeholder="Zip code"
                          id="zipCode"
                        />
                      </div>
                      <div className="row">
                        <div
                          className="col-4 empty-label"
                          htmlFor="country"
                        ></div>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="country"
                          value={props.hospital?.country_fd}
                          placeholder="Country"
                          id="country"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="definitiveId">
                          {constants.DEFINITIVE_ID}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="definitiveId"
                          value={props.hospital?.definitive_id_fd}
                          placeholder="(123)456-7890"
                          id="definitiveId"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <label className="col-4" htmlFor="accountNumber">
                          {constants.ACCOUNT_NUMBER}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="accountNumber"
                          value={props.hospital?.facility_account_no_fd}
                          placeholder="Account Number"
                          id="accountNumber"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="website">
                          {constants.WEBSITE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="website"
                          value={props.hospital?.website_fd}
                          placeholder="Website"
                          id="website"
                        />
                      </div>

                      <div className="row">
                        <label className="col-4" htmlFor="website">
                          {constants.TYPE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-4 col-8"
                          name="hospitalType"
                          value={props.hospital?.specialities_fd}
                          placeholder="Type"
                          id="Type"
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="dotted-line mx-5" />

                  <div className="row w-50 g-0 md-2">
                    <label
                      className="col-4 bold "
                      htmlFor="custom-subscription-switch"
                    >
                      {constants.TABLEAU_SUBSCRIPTION}
                    </label>
                    <div className="col-12 col-md-6 mb-3 d-flex align-items-center worksite-dropdown">
                      <div
                        className={`custom-switch ${
                          isSubscribed ? "active" : ""
                        }`}
                        onClick={() => setIsSubscribed(!isSubscribed)}
                        onKeyDown={(e) => {
                          // Toggle when the user presses Enter or Space key
                          if (e.key === "Enter" || e.key === " ") {
                            setIsSubscribed(!isSubscribed);
                          }
                        }}
                        tabIndex={0} // Makes the <div> focusable and part of the tab order
                        role="switch" // Optional: You can add role="switch" for better screen reader support
                        aria-checked={isSubscribed} // Accessibility: Tells screen readers if the switch is on or off
                      >
                        <div className="custom-toggle"></div>
                      </div>

                      <div className="ms-1">
                        {isSubscribed ? "TRUE" : "FALSE"}
                      </div>
                    </div>
                  </div>

                  <p className="main-text bold">{constants.FACILITY_ADMIN}</p>
                  <div className="row g-0">
                    <div className="col-12 col-md-6">
                      <div className="row md-5">
                        <label
                          className="col-4 star-after bold"
                          htmlFor="select-admin"
                          name="select-admin"
                        >
                          {constants.SELECT_ADMIN}
                        </label>
                        <div className="col-12 col-md-6 mb-3 d-flex flex-column worksite-dropdown">
                          <Dropdown
                            className="add-facility-dropdown"
                            id="admin"
                            value={values.admin}
                            options={Array.isArray(admins) ? admins : []}
                            onChange={(e) => {
                              setSelectedUser(e.value);
                              setFieldValue("admin", e.value);
                              handleAdminChange(e.value, setFieldValue);
                            }}
                            optionLabel="fullName"
                            placeholder="Add required person for the role of admin"
                            filter
                            filterBy="fullName"
                            emptyFilterMessage="No data available"
                            emptyMessage="No data available"
                          />
                          <ErrorMessage
                            name="admin"
                            component="div"
                            className="error-text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <label className="col-4" htmlFor="fullName">
                          {constants.FULL_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-6"
                          name="fullName"
                          value={
                            values.fullName || props.hospital?.full_name_ud
                          }
                          placeholder="Your Name"
                          id="fullName"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="title">
                          {constants.TITLE}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="title"
                          value={values.title || props.hospital?.title_ud}
                          placeholder="(e.g. HR, Hiring Manager, Medical Director)"
                          id="title"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="phone">
                          {constants.PHONE_NUMBER}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="phone"
                          value={values.phone || props.hospital?.phone_ud}
                          placeholder="(123)456-7890"
                          id="phone"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="email">
                          {constants.EMAIL}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="email"
                          value={values.email || props.hospital?.email_ud}
                          placeholder="example@exe.com"
                          id="email"
                        />
                      </div>
                      <div className="row">
                        <label className="col-4" htmlFor="account_Name">
                          {constants.ACCOUNT_NAME}
                        </label>
                        <Field
                          disabled
                          className="form-control w-50  mb-1 col-8"
                          name="account_Name"
                          value={
                            values.account_Name ||
                            props.hospital?.account_name_fd
                          }
                          placeholder=" Account Name"
                          id="account_Name"
                        />
                      </div>
                      <div className="row">
                        <div className="col-4 empty-label"></div>
                        <div className="d-flex w-50 col-8 p-0 py-2 justify-content-end">
                          <RegularButton
                            color={"#424c63"}
                            backgroundColor={"#00e676"}
                            type={"submit"}
                            btnname={constants.BUTTON_SUBMIT}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
EditFacilityPopup.propTypes = {
  hospital: PropTypes.shape({
    tableau_subscription_ud: PropTypes.bool,
    user_id_ud: PropTypes.string.isRequired,
    facility_account_no_ud: PropTypes.string.isRequired,
    account_name_fd: PropTypes.string,
    specialities_fd: PropTypes.string,
    account_status_fd: PropTypes.string,
    street_fd: PropTypes.string,
    city_fd: PropTypes.string,
    state_fd: PropTypes.string,
    zip_code_fd: PropTypes.string,
    country_fd: PropTypes.string,
    definitive_id_fd: PropTypes.string,
    website_fd: PropTypes.string,
    type_fd: PropTypes.string,
    full_name_ud: PropTypes.string,
    role_ud: PropTypes.string,
    phone_ud: PropTypes.string,
    email_ud: PropTypes.string,
    facility_account_no_fd: PropTypes.string, // This line is already added
    title_ud: PropTypes.string, // Add this line
    is_active_ud: PropTypes.bool, // Add this line
  }).isRequired,
  closeEditFacility: PropTypes.func.isRequired, // This is a function
};

export default EditFacilityPopup;
