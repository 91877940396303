import React, { useEffect } from "react";
import "../../assets/css/MyProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMainNavLink } from "../../redux/actions/facilityActions";

const MyProfile = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveMainNavLink("profile"));
  }, []);
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );
  const userData = rolePermission?.userInfo;
  const getProfileValue = (key) => userData?.[key] || "-";

  const data = [
    { id: 1, label: "Full Name", value: getProfileValue("full_name") },
    { id: 2, label: "Title", value: getProfileValue("title") },
    { id: 3, label: "Phone Number", value: getProfileValue("phone") },
    { id: 4, label: "Email", value: getProfileValue("email") },
    { id: 5, label: "Account Name", value: getProfileValue("account_name") },
  ];
  return (
    <div className="d-flex flex-column h-100 flex-grow-1 overflow-auto">
      <div className="myprofile-container flex-grow-1 overflow-auto">
        <p className="head-text">PROFILE</p>
        <div className="myprofile-main">
          <h5 className="profile-headings bold-barlow">Basic Information</h5>
          <div className="row g-0 mt-2 w-50">
            {data.map((item) => (
              <React.Fragment key={item.id || item.label}>
                <div className="col-5 my-2">
                  <p className=" bold-barlow">{item.label}</p>
                </div>
                <div className="col-7 my-2">
                  <p>{item.value}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
