import axios from "axios";
import { headersRequest } from "../../helpers/commonUtills";
import {
  facilityDataRequest,
  facilityDataSuccess,
  facilityDataFailure,
  fetchDbListRequest,
  fetchDBListSuccess,
  fetchDBListFailure,
  fetchAdminsRequest,
  fetchAdminsSuccess,
  fetchAdminsFailure,
  postDataRequest,
  postDataSuccess,
  postDataFailure,
  editDataRequest,
  editDataSuccess,
  editDataFailure,
  fetchFacilityUserDBRequest,
  fetchFacilityUserDBSuccess,
  fetchFacilityUserDBFailure,
} from "./listActions.js";
import constants from "../../helpers/en.js";
import {
  EDIT_FACILITY_USER_FAILURE,
  EDIT_FACILITY_USER_REQUEST,
  EDIT_FACILITY_USER_SUCCESS,
  FETCH_ADMINS_FAILURE,
  FETCH_DATA_FAILURE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_FACILITY_USER_REQUEST,
  FETCH_FACILITY_USER_SUCCESS,
  POST_FACILITY_USER_FAILURE,
  POST_FACILITY_USER_REQUEST,
  POST_FACILITY_USER_SUCCESS,
  SET_ACTIVE_MAIN_NAVLINK,
} from "./actionTypes.js";

export const getFacilitySFList = (facilityPrompt = "") => {
  return (dispatch) => {
    dispatch(facilityDataRequest());

    // Construct the URL dynamically based on the search query
    const url = `${process.env.REACT_APP_API_URL}/facilities?facilityPrompt=${facilityPrompt}&limit=20`;
    axios
      .get(url, {
        headers: headersRequest,
      })
      .then((response) => {
        dispatch(facilityDataSuccess(response.data));
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch(facilityDataFailure(error.message));
      });
  };
};

export const getFacilityDBList = (facilityPrompt = "") => {
  return (dispatch) => {
    dispatch(fetchDbListRequest());
    // Construct the URL dynamically based on the search query
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/facilityDetails`;
    axios
      .get(url, {
        headers: headersRequest,
      })
      .then((response) => {
        dispatch(fetchDBListSuccess(response.data));
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch(fetchDBListFailure(error.message));
      });
  };
};

export const getAdminSFList = (facilityId) => {
  return (dispatch) => {
    dispatch(fetchAdminsRequest());
    const url = `${process.env.REACT_APP_API_URL}/users?facilityId=${facilityId}&offset=0&limit=${constants.LIMIT}`;
    axios
      .get(url, {
        headers: headersRequest,
      })
      .then((response) => {
        dispatch(fetchAdminsSuccess(response.data));
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch(
          fetchAdminsFailure(error.response?.data || "Something went wrong")
        );
      });
  };
};

// Async action  postTwoRequests
export const addFacility =
  (facilityPayload, adminPayload, toast, closePopup) => async (dispatch) => {
    dispatch(postDataRequest());
    try {
      // First API call
      //need to check with mulesoft team for outgoing email. till the time for testing purpose sending is_email_verified flag true.
      const facilityResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_FACILITY_API_URL}/onboardFacilities`,
        facilityPayload,
        { headers: headersRequest }
      );

      if (facilityResponse?.status === 200) {
        const adminResponse = await axios.post(
          `${process.env.REACT_APP_EMAIL_VERIFICATION}/user`,
          adminPayload,
          { headers: headersRequest }
        );
        if (adminResponse?.status === 201) {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: constants.FACILITY_SUCCESSFULLY_MESSAGE,
            life: 3000,
          });

          dispatch(postDataSuccess(adminResponse.data));
          dispatch(getFacilityDBList());
          setTimeout(() => {
            closePopup();
          }, 1000);
        } else {
          throw new Error("Admin creation failed");
        }
      } else {
        throw new Error("Facility creation failed");
      }
    } catch (error) {
      // Handle errors with unique codes
      if (error.response) {
        const { status, data } = error.response;
        switch (status) {
          case 400:
            toast.current?.show({
              severity: "warn",
              summary: "Validation Error",
              detail: data.message || "Invalid request data",
              life: 3000,
            });
            break;
          case 401:
            toast.current?.show({
              severity: "error",
              summary: "Unauthorized",
              detail: constants.ERROR_401_MESSAGE,
              life: 3000,
            });
            break;
          case 403:
            toast.current?.show({
              severity: "error",
              summary: "Forbidden",
              detail: constants.ERROR_403_MESSAGE,
              life: 3000,
            });
            break;
          case 404:
            toast.current?.show({
              severity: "error",
              summary: "Not Found",
              detail: constants.ERROR_404_MESSAGE,
              life: 3000,
            });
            break;
          case 500:
            toast.current?.show({
              severity: "error",
              summary: "Service Unavailable",
              detail: constants.ERROR_500_MESSAGE,
              life: 3000,
            });
            break;
          case 503:
            toast.current?.show({
              severity: "error",
              summary: "Service Unavailable",
              detail: constants.ERROR_503_MESSAGE,
              life: 3000,
            });
            break;
          default:
            toast.current?.show({
              severity: "error",
              summary: "Error",
              detail: data?.message || "An unexpected error occurred.",
              life: 3000,
            });
        }
      } else {
        // Handle network or unknown errors
        toast.current?.show({
          severity: "error",
          summary: "Network Error",
          detail: error.message || "Unable to connect to the server.",
          life: 3000,
        });
      }

      dispatch(postDataFailure(error.message || "Error occurred"));
    }
  };

/// Async action  editRequests
export const editFacility =
  (adminPayload, toast, closePopup) => async (dispatch) => {
    dispatch(editDataRequest());
    try {
      const adminResponse = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/userDetails`,
        //need to check with mulesoft team for outgoing email. till the time for testing purpose sending is_email_verified flag true
        adminPayload,
        { headers: headersRequest }
      );

      if (adminResponse?.status === 200) {
        if (toast.current) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: constants.UPDATE_FACILITY_SUCCESSFULLY_MESSAGE,
            life: 3000,
          });
        }

        dispatch(editDataSuccess(adminResponse.data));
        dispatch(getFacilityDBList());
        setTimeout(() => {
          closePopup();
        }, 1000);
      } else {
        throw new Error("Request failed");
      }
    } catch (error) {
      if (toast.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "ERROR: duplicate key value violates unique constraint",
          life: 3000,
        });
      }
      dispatch(editDataFailure(error.message));
    }
  };

//worksite api
export const getSFWorksiteList = (
  worksitePrompt = "",
  accountNumber = "",
  offset = 0,
  limit = 10000
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST });

    const url = `${process.env.REACT_APP_API_URL}/worksite?worksitePrompt=${worksitePrompt}&accountNumber=${accountNumber}&offset=${offset}&limit=${limit}`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: FETCH_DATA_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        console.error("API Error:", error.message);
        dispatch({ type: FETCH_DATA_FAILURE, payload: error.message });
      });
  };
};

export const setActiveMainNavLink = (link) => ({
  type: SET_ACTIVE_MAIN_NAVLINK,
  payload: link,
});

export const fetchFacilityUser = (id) => (dispatch) => {
  dispatch({ type: FETCH_FACILITY_USER_REQUEST });
  if (id !== undefined && id !== null) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users?facilityId=${id}&offset=0&limit=${constants.LIMIT}`,
        { headers: headersRequest }
      )
      .then((response) => {
        dispatch({ type: FETCH_FACILITY_USER_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ADMINS_FAILURE, payload: error.message });
      });
  }
};

export const postFacilityUser =
  (userData, AccessData, userTypeData, toastRef, closeAddFacilityUser) =>
  (dispatch) => {
    dispatch({ type: POST_FACILITY_USER_REQUEST });

    axios
      .post(`${process.env.REACT_APP_EMAIL_VERIFICATION}/user`, userData, {
        headers: headersRequest,
      })
      .then((userResponse) => {
        const userId = userResponse.data?.user_id;
        if (userId) {
          if (AccessData.length > 0) {
            AccessData[0].user_id = userId;
          }
          if (userTypeData.length > 0) {
            userTypeData[0].user_id = userId;
          }

          return Promise.all([
            axios.post(
              `${process.env.REACT_APP_BACKEND_API_URL}/access`,
              AccessData,
              {
                headers: headersRequest,
              }
            ),
            axios.post(
              `${process.env.REACT_APP_BACKEND_API_URL}/userPersonas`,
              userTypeData,
              {
                headers: headersRequest,
              }
            ),
          ])
            .then(([accessResponse, userTypeResponse]) => {
              toastRef.current?.showSuccess("User added successfully");
              setTimeout(() => {
                closeAddFacilityUser();
              }, 1000);

              dispatch({
                type: POST_FACILITY_USER_SUCCESS,
                payload: {
                  userId,
                  accessData: accessResponse.data,
                  userTypeData: userTypeResponse.data,
                },
              });
            })
            .catch((error) => {
              toastRef.current?.showError(error.message);

              dispatch({
                type: POST_FACILITY_USER_FAILURE,
                payload: `Failed to post additional data: ${error.message}`,
              });
            });
        } else {
          dispatch({
            type: POST_FACILITY_USER_FAILURE,
            payload: "Invalid userId received",
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toastRef.current?.showError("User already exist");
        } else {
          toastRef.current?.showError(error.message);
        }
        dispatch({
          type: POST_FACILITY_USER_FAILURE,
          payload: `Failed to post user data: ${error.message}`,
        });
      });
  };

export const editFacilityUser =
  (userData, AccessData, userTypeData, toastRef, closeEditFacilityUser, id) =>
  (dispatch) => {
    dispatch({ type: EDIT_FACILITY_USER_REQUEST });

    axios
      .patch(`${process.env.REACT_APP_BACKEND_API_URL}/userDetails`, userData, {
        headers: headersRequest,
      })
      .then(() => {
        return Promise.all([
          axios.patch(
            `${process.env.REACT_APP_BACKEND_API_URL}/access`,
            AccessData,
            {
              headers: headersRequest,
            }
          ),
          axios.patch(
            `${process.env.REACT_APP_BACKEND_API_URL}/userPersonas`,
            userTypeData,
            {
              headers: headersRequest,
            }
          ),
        ])
          .then(([accessResponse, userTypeResponse]) => {
            toastRef.current?.showSuccess("User updated successfully");
            setTimeout(() => {
              closeEditFacilityUser();
              dispatch(fetchFacilityUserDBById(id));
            }, 1000);

            dispatch({
              type: EDIT_FACILITY_USER_SUCCESS,
              payload: {
                accessData: accessResponse.data,
                userTypeData: userTypeResponse.data,
              },
            });
          })
          .catch((error) => {
            toastRef.current?.showError(error.message);

            dispatch({
              type: EDIT_FACILITY_USER_FAILURE,
              payload: `Failed to edit additional data: ${error.message}`,
            });
          });
      })
      .catch((error) => {
        // if (error.response.status === 500) {
        //   toastRef.current?.showError("User already exist");
        // } else {
        toastRef.current?.showError(error.message);
        // }
        dispatch({
          type: EDIT_FACILITY_USER_FAILURE,
          payload: `Failed to edit user data: ${error.message}`,
        });
      });
  };
export const fetchFacilityUserDBById = (id) => {
  if (id) {
    return (dispatch) => {
      dispatch(fetchFacilityUserDBRequest());
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_API_URL}/userDetails?facility_account_no=${id}`,
          { headers: headersRequest }
        )
        .then((response) => {
          dispatch(fetchFacilityUserDBSuccess(response.data));
        })
        .catch((error) => {
          dispatch(fetchFacilityUserDBFailure(error.message));
        });
    };
  }
};

export const activateUser = (status, userId, id) => async (dispatch) => {
  const newStatus = !status;
  const UserPayload = [
    {
      user_id: userId,
      is_active: newStatus,
    },
  ];
  try {
    const adminResponse = await axios.patch(
      `${process.env.REACT_APP_BACKEND_API_URL}/userDetails`,
      UserPayload,
      { headers: headersRequest }
    );
    if (adminResponse?.status === 200) {
      dispatch(getFacilityDBList());
      dispatch(fetchFacilityUserDBById(id));
    } else {
      throw new Error("Request failed");
    }
  } catch (error) {}
};
