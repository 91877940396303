import React, { useEffect } from "react";
import "../../../assets/css/ScProfile.scss";
import ProfileCards from "../../../components/Commons/ProfileCards";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveSCProfileNavLink,
  getProfile,
  fetchDocuments,
  viewPDFDocument,
} from "../../../redux/actions/schedulingActions";
import CustomSpinner from "../../../components/Commons/CustomSpinner";
import constants from "../../../helpers/en";
import { removeDecimal } from "../../../helpers/commonUtills";

const ScProfile = () => {
  const dispatch = useDispatch();

  // Redux Selectors
  const profileList = useSelector((state) => state.profile.profile);
  const { documents } = useSelector((state) => state.document);

  // Fetch profile data
  useEffect(() => {
    const confirmationNumber = sessionStorage.getItem("confirmationNumber");
    if (confirmationNumber) {
      dispatch(getProfile(confirmationNumber));
    }
  }, [dispatch]);

  // Set Active Navigation Link
  useEffect(() => {
    dispatch(setActiveSCProfileNavLink(""));
  }, [dispatch]);
  useEffect(() => {
    const confirmationNumber = sessionStorage.getItem("confirmationNumber");
    if (confirmationNumber) {
      dispatch(fetchDocuments(confirmationNumber));
    }
  }, [dispatch]);
  const renderRates = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: getProfileValue("rates"), // Ensure getProfileValue("rates") returns a valid HTML string
        }}
      ></div>
    );
  };

  const viewConfirmationLink = (event) => {
    event.preventDefault();
    dispatch(viewPDFDocument(documents[0]?.Id, documents[0]?.fileType));
  };
  const ConfirmationLink = () => {
    const link = getProfileValue("confirmationLink");
    if (link) {
      return (
        <a
          href={link}
          onClick={viewConfirmationLink}
          className={
            documents === constants.NO_RECORD_FOUND_MESSAGE
              ? "confirmationUnLink"
              : "confirmationLink"
          }
        >
          {documents === constants.NO_RECORD_FOUND_MESSAGE
            ? "-"
            : constants.VIEW}
        </a>
      );
    } else {
      return <span>{constants.NO_FILE_AVAILABLE}</span>;
    }
  };
  // Utility function to safely get profile data
  const getProfileValue = (key) => profileList?.[0]?.[key] || "-";

  if (!profileList || profileList.length === 0) {
    return (
      <div className="sc-profile-container flex-grow-1 overflow-auto">
        <CustomSpinner />
      </div>
    );
  }

  // Profile Card Data
  const card1 = [
    { label: "Facility Name", value: getProfileValue("facilityName") },
    {
      label: "Facility City & State",
      value: getProfileValue("facilityCityAndState"),
    },
    { label: "Client Name", value: getProfileValue("clientNamea") },
    { label: "Provider Name", value: getProfileValue("providerName") },
    { label: "Practitioner Type", value: getProfileValue("practitionerType") },
    { label: "Specialty", value: getProfileValue("specialty") },
    { label: "Primary Contact", value: getProfileValue("primaryContact") },
    { label: "Client Consultant", value: getProfileValue("clientConsultant") },
    { label: "Consultant Phone", value: getProfileValue("consultantPhone") },
    { label: "Consultant Email", value: getProfileValue("consultantEmail") },
  ];

  const card2 = [
    { label: "Provider Phone", value: getProfileValue("providerPhone") },
    { label: "Provider Email", value: getProfileValue("providerEmail") },
  ];

  const card3 = [
    { label: "Start Date", value: getProfileValue("startDate") },
    { label: "End Date", value: getProfileValue("endDate") },
    {
      label: "Scheduled Days",
      value: removeDecimal(getProfileValue("scheduledDays")),
    },
    {
      label: "Worked Days",
      value: removeDecimal(getProfileValue("workedDays")),
    },
    { label: "Privileges", value: getProfileValue("privileges") },
    { label: "License", value: getProfileValue("license") },
  ];

  const card4 = [
    {
      label: "Confirmation Link",
      value: ConfirmationLink(),
      tag: "a",
    },
    { label: "Rates", value: renderRates() },
    {
      label: "Executed Agreement",
      value: getProfileValue("executedAgreement"),
      tag: "a",
    },
  ];

  const confirmationNumber =
    sessionStorage.getItem("confirmationNumber") || "-";

  // Loading fallback
  if (!profileList || profileList.length === 0) {
    return <p>Loading profile data...</p>;
  }

  return (
    <div className="sc-profile-container flex-grow-1 overflow-auto">
      <div className="sc-pr-inner-container">
        <p className="heading_name bold-barlow">
          {getProfileValue("facilityName")}
        </p>
        <p className="assign_Num">Assignment Number: {confirmationNumber}</p>
        <div className="mt-2 d-flex w-100 flex-wrap">
          <div className="sc-card-container">
            <ProfileCards data={card1} />
          </div>
          <div className="sc-card-container">
            <ProfileCards data={card2} />
            <ProfileCards data={card3} />
          </div>
          <div className="sc-card-container">
            <ProfileCards data={card4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScProfile;
