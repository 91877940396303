import axios from "axios";
import { headersRequest } from "../../helpers/commonUtills";

import {
  GET_REPORT_DETAILS,
  SET_REPORT_DETAILS,
  REPORT_DETAILS_FAILURE,
  SET_ADMIN_FACILITY,
  SET_ADMIN_FACILITY_ID,
  SET_ADMIN_CHILD_FACILITY,
  CLEAR_ALL_ADMIN_FACILITIES,
} from "./actionTypes";
import constants from "../../helpers/en";
import {
  dashboardReportListFailure,
  dashboardReportListRequest,
  dashboardReportListSuccess,
} from "./dashboardListActions";
// Helper function for error logging and dispatching
const handleApiError = (
  dispatch,
  error,
  failureActionType,
  defaultErrorMessage
) => {
  const errorMessage = error.response?.data?.message || defaultErrorMessage;
  const errorCode = error.response?.status || "Unknown Error Code";
  console.error(`API Error (${errorCode}): ${errorMessage}`);
  console.log("Error Details:", error.message);
  dispatch({
    type: failureActionType,
    payload: errorMessage,
  });
};

// Action to fetch reports reportDetail
export const reportDetail = (
  pageType,
  facilityAccountID = "",
  dashboardID = ""
) => {
  return (dispatch) => {
    dispatch({ type: GET_REPORT_DETAILS });
    const url = `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=candidates&facility_account_no1=9091&dashboard_id1=65`;
    axios
      .get(url, { headers: headersRequest })
      .then((response) => {
        dispatch({ type: SET_REPORT_DETAILS, payload: response.data });
      })
      .catch((error) => {
        handleApiError(
          dispatch,
          error,
          REPORT_DETAILS_FAILURE,
          "Failed to fetch report details"
        );
      });
  };
};

// Action to fetch report details
export const fetchReportsName = (
  pageType,
  selectedClientId = null,
  selectedAdminFacility = "",
  toastRef,
  userType
) => {
  return (dispatch) => {
    if (selectedClientId !== "" && selectedClientId !== undefined) {
      dispatch(dashboardReportListRequest());
      if (
        selectedAdminFacility !== null &&
        selectedAdminFacility !== "" &&
        selectedAdminFacility !== undefined
      ) {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}&worksite_account_no=${selectedAdminFacility}&userType=${userType}`,
            { headers: headersRequest }
          )
          .then((response) => {
            dispatch(dashboardReportListSuccess(response.data));
            if (response.data === "No records found") {
              toastRef.current?.showWarn(constants.NO_RECORDS_FOUND);
            }
          })
          .catch((error) => {
            console.error("API Error:", error.message);
            dispatch(dashboardReportListFailure(error.message));
          });
      } else {
        if (
          selectedClientId !== "" &&
          selectedClientId !== null &&
          selectedClientId !== undefined &&
          pageType !== undefined &&
          pageType !== ""
        ) {
          axios
            .get(
              `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}&userType=${userType}`,
              { headers: headersRequest }
            )
            .then((response) => {
              dispatch(dashboardReportListSuccess(response.data));
              if (response.data === "No records found") {
                toastRef.current?.showWarn(constants.NO_RECORDS_FOUND);
              }
            })
            .catch((error) => {
              console.error("API Error:", error.message);
              dispatch(dashboardReportListFailure(error.message));
            });
        }
      }
    }
  };
};

export const setAdminFacility = (data) => ({
  type: SET_ADMIN_FACILITY,
  payload: data,
});
export const setAdminFacilityID = (id) => ({
  type: SET_ADMIN_FACILITY_ID,
  payload: id,
});
export const setAdminChildFacility = (data) => ({
  type: SET_ADMIN_CHILD_FACILITY,
  payload: data,
});
export const clearAllAdminFacilities = () => ({
  type: CLEAR_ALL_ADMIN_FACILITIES,
});
