import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import constants from "../../helpers/en";
import { getSFWorksiteList } from "../../redux/actions/facilityActions";
import { Dropdown } from "primereact/dropdown";
import "../../assets/css/PrimeDropdown.scss";
import PropTypes from "prop-types";
import {
  // setAdminChildFacility,
  setAdminFacility,
  setAdminFacilityID,
} from "../../redux/actions/facilityAdminAction";
import DropdownSpinner from "./DrpodownSpinner";

const FacilityDropdown = memo(({ selectedClientId }) => {
  const dispatch = useDispatch();

  //redux selector
  const selectedAdminFacility = useSelector(
    (state) => state.adminFacilityData.adminFacility
  );

  const { worksiteData, loading: worksiteLoading } = useSelector(
    (state) => state.worksite
  );

  //local states

  const getWorksite = () => {
    let accountNo = selectedClientId;
    if (accountNo) {
      dispatch(getSFWorksiteList("", accountNo, 0, constants.LIMIT));
    }
  };

  useEffect(() => {
    getWorksite();
  }, [selectedClientId, dispatch]);

  const onFacilityChange = (e) => {
    dispatch(setAdminFacility(e.value));
    dispatch(setAdminFacilityID(e.value?.accountNumber));
  };

  let options = [];

  if (!worksiteLoading) {
    if (Array.isArray(worksiteData?.result)) {
      options = worksiteData?.result;
    } else {
      options = [];
    }
  }
  return (
    <div className="fc-dropdown-container">
      <div className="d-flex align-items-center fc-dropdown">
        <label
          htmlFor="select-facility"
          className="bold-barlow "
          name="select-worksite"
        >
          {constants.SELECT_FACILITY}
        </label>
        <Dropdown
          className=""
          id="select-facility"
          value={selectedAdminFacility}
          options={options}
          onChange={onFacilityChange}
          placeholder="Select Facility"
          optionLabel="name"
          filter
          filterBy="name"
          showClear
          emptyFilterMessage="No data available"
          emptyMessage={
            worksiteLoading ? <DropdownSpinner /> : "No data available"
          }
        />
      </div>
    </div>
  );
});
// PropTypes validation
FacilityDropdown.propTypes = {
  selectedClientId: PropTypes.object.isRequired, // reportsData must be an object and is required
};
export default FacilityDropdown;
