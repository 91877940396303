import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/TableauContainer.scss";
import TableauViewer from "../TableauViewer";
import { ReactComponent as Arrow } from "../../assets/images/icons/BackArrow.svg";

const TablaeuPage = () => {
  const location = useLocation();
  const Iframe = location.state?.iframe;
  const navigate = useNavigate();

  return (
    <div className="d-flex  flex-grow-1 overflow-auto h-100 ">
      <div className="tableau-container">
        <button
          className="tableau-back-btn"
          title="Back"
          onClick={() => navigate("..")}
        >
          <Arrow className="icon" />
        </button>
        <div className="tableau-div">
          {Iframe ? (
            <TableauViewer link={Iframe} />
          ) : (
            <div className="ds-empty-message">
              <h1>Dashboard not available</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TablaeuPage;
