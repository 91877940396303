import {
  CLEAR_DASHBOARD_REPORTS_FAILURE,
  CLEAR_DS_REPORT_DATA,
  CLEAR_SELECTIONS,
  EDIT_DASHBOARD_REPORTS_FAILURE,
  EDIT_DASHBOARD_REPORTS_REQUEST,
  EDIT_DASHBOARD_REPORTS_SUCCESS,
  FETCH_DASHBOARD_REPORTS_FAILURE,
  FETCH_DASHBOARD_REPORTS_REQUEST,
  FETCH_DASHBOARD_REPORTS_SUCCESS,
  POST_DASHBOARD_REPORTS_FAILURE,
  POST_DASHBOARD_REPORTS_REQUEST,
  POST_DASHBOARD_REPORTS_SUCCESS,
  SET_ACTIVE_DASH_NAVLINK,
  SET_DS_REPORT_DATA,
  SET_SELECTED_CHILD_FACILITY,
  SET_SELECTED_CLIENT,
  SET_SELECTED_CLIENT_ID,
  SET_SELECTED_FACILITY,
  SET_SELECTED_FACILITY_ID,
} from "../actions/actionTypes";

// Initial state
const initialState = {
  reportLoading: false,
  reportData: [],
  error: "",
};

// Reducer function
export const getDashboardReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_REPORTS_REQUEST:
      return {
        ...state,
        reportLoading: true,
      };
    case FETCH_DASHBOARD_REPORTS_SUCCESS:
      return {
        reportLoading: false,
        reportData: action.payload,
        error: "",
      };
    case FETCH_DASHBOARD_REPORTS_FAILURE:
      return {
        reportLoading: false,
        reportData: [],
        error: action.payload,
      };
    case CLEAR_DASHBOARD_REPORTS_FAILURE:
      return {
        ...state,
        reportData: null,
      };
    default:
      return state;
  }
};

export const AddDashboardReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DASHBOARD_REPORTS_REQUEST:
      return { ...state, loading: true, error: null };
    case POST_DASHBOARD_REPORTS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case POST_DASHBOARD_REPORTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const EditDashboardReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_DASHBOARD_REPORTS_REQUEST:
      return { ...state, loading: true, error: null };
    case EDIT_DASHBOARD_REPORTS_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case EDIT_DASHBOARD_REPORTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
const initialStateSelect = {
  selectedClient: null,
  selectedFacility: null,
  selectedChildFacility: null,
  selectedClientId: null,
  selectedFacilityId: null,
};

export const selectionReducer = (state = initialStateSelect, action) => {
  switch (action.type) {
    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
        selectedFacility: null,
        selectedChildFacility: null,
        selectedFacilityId: null,
      };
    case SET_SELECTED_FACILITY:
      return {
        ...state,
        selectedFacility: action.payload,
        selectedChildFacility: null,
      };
    case SET_SELECTED_CHILD_FACILITY:
      return {
        ...state,
        selectedChildFacility: action.payload,
      };
    case SET_SELECTED_CLIENT_ID:
      return {
        ...state,
        selectedClientId: action.payload,
      };
    case SET_SELECTED_FACILITY_ID:
      return {
        ...state,
        selectedFacilityId: action.payload,
      };
    case CLEAR_SELECTIONS:
      return {
        ...state,
        selectedClient: null,
        selectedFacility: null,
        selectedChildFacility: null,
        selectedClientId: null,
        selectedFacilityId: null,
      };
    default:
      return state;
  }
};

const initialStateNav = {
  activeNavLink: "", // Default to no active NavLink
};

export const dashboardNavReducer = (state = initialStateNav, action) => {
  switch (action.type) {
    case SET_ACTIVE_DASH_NAVLINK:
      return {
        ...state,
        activeNavLink: action.payload,
      };
    default:
      return state;
  }
};

export const initialDsReportState = {
  report: null,
};

export const dsReportReducer = (state = initialDsReportState, action) => {
  switch (action.type) {
    case SET_DS_REPORT_DATA:
      return {
        ...state,
        report: action.payload,
      };
    case CLEAR_DS_REPORT_DATA:
      return {
        ...state,
        report: null,
      };
    default:
      return state;
  }
};
