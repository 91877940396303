import axios from "axios";
import { SET_TOKEN, SET_USER, CLEAR_AUTH, LOGIN_FAILURE,SET_CHECK_STATUS,
  LOGOUT_USER } from "./actionTypes";
import { headersRequest } from "../../helpers/commonUtills";

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
  isAuthenticated: true,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
  isAuthenticated: true,

});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
  isAuthenticated: false,
});

export const clearAuth = () => ({
  type: CLEAR_AUTH,
});

export const setCheckStatus = (status) => ({
  type: SET_CHECK_STATUS,
  payload: status,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
})
 
export const checkAuthStatus = (userId) => async (dispatch) => {
  try {
    const activeStatus = await axios.get(
      `${process.env.REACT_APP_BACKEND_API_URL}/userDetails?user_id=${userId}`,
       { headers: headersRequest }
    );
       dispatch(setCheckStatus(activeStatus.data)); // Dispatch the status to the store
  } catch (error) {
    console.error('Error checking authentication status:', error);

  }
};
