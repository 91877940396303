import axios from "axios";
// Create an Axios instance with a base URL and default headers
import docIcon from "../assets/images/icons/docIcon.svg"; // Replace with actual image paths
import pdfIcon from "../assets/images/icons/pdfIcon.svg"; // Replace with actual image paths
import excelIcon from "../assets/images/icons/excelIcon.svg"; // Replace with actual image paths
import constants from "./en";
import * as Yup from "yup";

export const headersRequest = {
  "Content-Type": "application/json", // Setting the header
  client_id: process.env.REACT_APP_BACKEND_API_CLIENTID,
  client_secret: process.env.REACT_APP_BACKEND_API_CLIENT_SECRET,
};
export const isSessionExpired = () => {
  const session = JSON.parse(localStorage.getItem("sessionTime"));
  if (!session) return true; // No session, consider expired

  const currentTime = new Date().getTime();
  const sessionStartTime = session.startTime;
  const sessionDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  return currentTime - sessionStartTime > sessionDuration;
};
export const getFileIcon = (extension) => {
  if (extension === "pdf") {
    return pdfIcon; // Return the PDF icon
  } else if (extension === "doc" || extension === "docx") {
    return docIcon; // Return the DOC icon
  } else if (extension === "xls" || extension === "xlsx ") {
    return excelIcon; // Return the Excel icon
  } else if (
    extension === "jpg" ||
    extension === "JPG" ||
    extension === "JPEG"
  ) {
    return docIcon; // Return the JPG icon
  } else if (extension === "png" || extension === "PNG") {
    return docIcon; // Return the PNG icon
  } else {
    return null; // No icon for unsupported file types
  }
};

export const cleanBase64 = (base64Data) => {
  if (typeof base64Data !== "string") {
    console.error("The provided base64 data is not a string.");
    return "";
  }
  return base64Data.replace(/[^A-Za-z0-9+/=]/g, "");
};

// Function to convert base64 to PDF
export const convertBase64ToPDF = (base64Data) => {
  try {
    // Ensure base64Data is a string
    if (typeof base64Data !== "string") {
      console.error("base64Data is not a valid string:", base64Data);
      return;
    }

    // Clean the base64 data
    const cleanedBase64Data = cleanBase64(base64Data);

    // Check if the cleaned base64 string is in the correct format
    if (!/^[A-Za-z0-9+/=]+$/.test(cleanedBase64Data)) {
      throw new Error("Invalid base64 format");
    }

    // Decode the base64 string
    const byteCharacters = atob(cleanedBase64Data);
    const byteArrays = [];

    // Create a byte array from the decoded base64 string
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create a Blob from the byte array
    const blob = new Blob(byteArrays, { type: "application/pdf" });

    // Create a URL for the Blob
    URL.createObjectURL(blob);

    // Set the URL for the iframe to display the PDF
    // setPdfUrl(url);
  } catch (error) {
    console.error("Error decoding base64:", error.message);
  }
};

export const getFacilitySFList = async (facilityPrompt = "") => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/facilities?facilityPrompt=${facilityPrompt}&limit=20`;
    const response = await axios.get(url, {
      headers: headersRequest,
    });
    return response.data; // Return the data from the API response
  } catch (error) {
    console.error("Error fetching data with headers:", error);
    throw error; // Propagate the error
  }
};

export const getAdminSFList = async (facilityId = "") => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/users?facilityId=${facilityId}&offset=0&limit=${constants.LIMIT}`;
    const response = await axios.get(url, {
      headers: headersRequest,
    });
    return response.data; // Return the data from the API response
  } catch (error) {
    console.error("Error fetching data with headers:", error);
    throw error; // Propagate the error
  }
};

export const addReportValidationSchema = Yup.object().shape({
  rows: Yup.array().of(
    Yup.object().shape({
      report_name: Yup.mixed().required(constants.EMPTY_ERROR_MESSAGE),
      iframe_url: Yup.string()
        .required(constants.EMPTY_ERROR_MESSAGE)
        .matches(
          /^https:\/\/[^\s/$.?#].[^\s]*$/,
          "Must be a valid https:// URL"
        ),
    })
  ),
});

export const editReportValidationSchema = Yup.object({
  report_name: Yup.mixed().required(constants.EMPTY_ERROR_MESSAGE),
  iframe_url: Yup.string()
    .required(constants.EMPTY_ERROR_MESSAGE)
    .matches(/^https:\/\/[^\s/$.?#].[^\s]*$/, "Must be a valid https:// URL"),
});

export const removeDecimal = (number) => {
  if (number === null || isNaN(number)) {
    return "-"; // Invalid input as a string
  } else {
    return Math.trunc(number).toString(); // Always return a string
  }
};


