import { USER_TYPES } from "../helpers/enums";
import {
  renderAdminMenu,
  renderFacilityAdminMenu,
  renderFacilityUserMenu,
} from "../helpers/headerMenu";
export const renderMenu = (rolePermission, activeMenu, handleMenuClick) => {
  if (rolePermission.role === USER_TYPES.SUPER_ADMIN) {
    return renderAdminMenu(activeMenu, handleMenuClick);
  } else if (rolePermission.role === USER_TYPES.FACILITY_ADMIN) {
    return renderFacilityAdminMenu(rolePermission, activeMenu, handleMenuClick);
  } else if (rolePermission.role === USER_TYPES.FACILITY_USER) {
    return renderFacilityUserMenu(rolePermission, activeMenu, handleMenuClick);
  }
};

export const getRolePermissions = (permissionsMapping) => {
  const permissions = {
    schedule_management: permissionsMapping?.schedule_management || null,
    candidates: permissionsMapping?.candidates || null,
    landing_page: permissionsMapping?.landing_page || null,
    scheduled_report: permissionsMapping?.scheduled_report || null,
    scheduling: permissionsMapping?.scheduling || null,
    spend_management_dashboard:
      permissionsMapping?.spend_management_dashboard || null,
    spend_management_menu: permissionsMapping?.spend_management_menu || null,
    tableau_subscription: permissionsMapping?.tableau_subscription || null,
    locums_analytics: permissionsMapping?.locums_analytics || null,
  };
  const userInfo = {
    facility_account_no: permissionsMapping?.facility_account_no || null,
    first_name: permissionsMapping?.first_name || null,
    full_name: permissionsMapping?.full_name || null,
    is_active: permissionsMapping?.is_active || null,
    last_name: permissionsMapping?.last_name || null,
    user_id: permissionsMapping?.user_id || null,
    email: permissionsMapping?.email || null,
    account_name: permissionsMapping?.account_name || null,
    title: permissionsMapping?.title || null,
    phone: permissionsMapping?.phone || null,
  };

  const role = permissionsMapping.role || null;
  if (permissionsMapping.role === "Super Admin" || permissionsMapping.role === "Facility Admin" || permissionsMapping.role === "Facility User") {
    return {
      role,
      permissions,
      userInfo,
    };
  } else {
    return {
      role: [],
      permissions: [],
      userInfo: [],
    };
  }
 
};
