import axios from "axios";
import {
  dashboardReportListFailure,
  dashboardReportListRequest,
  dashboardReportListSuccess,
  editDashboardReportFailure,
  editDashboardReportRequest,
  postDashboardReportFailure,
  postDashboardReportRequest,
} from "./dashboardListActions";
import constants from "../../helpers/en.js";
import { headersRequest } from "../../helpers/commonUtills";
import {
  CLEAR_DS_REPORT_DATA,
  SET_ACTIVE_DASH_NAVLINK,
  SET_DS_REPORT_DATA,
} from "./actionTypes.js";

//GET DASHBOARD REPORTS
export const getDashboardReportsList = (
  pageType,
  selectedClientId = null,
  selectedFacilityId = "",
  toastRef,
  userType
) => {
  return (dispatch) => {
    if (
      selectedClientId !== "" &&
      selectedClientId !== null &&
      selectedClientId !== undefined &&
      pageType !== undefined &&
      pageType !== ""
    ) {
      dispatch(dashboardReportListRequest());
      if (
        selectedFacilityId !== null &&
        selectedFacilityId !== "" &&
        selectedFacilityId !== undefined
      ) {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}&worksite_account_no=${selectedFacilityId}&userType=${userType}`,
            { headers: headersRequest }
          )
          .then((response) => {
            dispatch(dashboardReportListSuccess(response.data));
            if (response.data === "No records found") {
              toastRef.current?.showWarn(constants.NO_RECORDS_FOUND);
            }
          })
          .catch((error) => {
            console.error("API Error:", error.message);
            dispatch(dashboardReportListFailure(error.message));
          });
      } else {
        axios
          .get(
            `${process.env.REACT_APP_BACKEND_API_URL}/dashboard?page_type=${pageType}&facility_account_no=${selectedClientId}&userType=${userType}`,
            { headers: headersRequest }
          )
          .then((response) => {
            dispatch(dashboardReportListSuccess(response.data));
            if (response.data === "No records found") {
              toastRef.current?.showWarn(constants.NO_RECORDS_FOUND);
            }
          })
          .catch((error) => {
            if (error.status !== 400) {
              console.error("API Error:", error.message);
            }
            dispatch(dashboardReportListFailure(error.message));
          });
      }
    }
  };
};

//ADD DASHBOARD REPORTS
export const addDashboardReports =
  (dashboardReportPayload, toastRef, navigateBack) => async (dispatch) => {
    dispatch(postDashboardReportRequest());
    try {
      const dashboardResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_API_URL}/dashboard`,
        dashboardReportPayload,
        { headers: headersRequest }
      );

      if (dashboardResponse?.status === 201) {
        toastRef.current?.showSuccess(constants.REPORT_SUBMITTED_SUCCESSFULLY);
        setTimeout(() => {
          navigateBack();
        }, 1000);
      } else {
        toastRef.current?.showError(dashboardResponse?.status);

        throw new Error(constants.REPORT_SUBMISSION_FAILED);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        toastRef.current?.showError(constants.CLIENT_FIELD_IS_REQUIRED);
      } else if (error.response?.status === 500) {
        toastRef.current?.showError(error.response.data?.description[0]);
      } else {
        toastRef.current?.showError(error.response.data?.description[0]);
      }
      // Handle errors with unique codes

      dispatch(postDashboardReportFailure(error.message || "Error occurred"));
    }
  };

//EDIT DASHBOARD REPORTS
export const editDashboardReports =
  (dashboardReportPayload, toastRef, navigateBack) => async (dispatch) => {
    dispatch(editDashboardReportRequest());
    try {
      const dashboardResponse = await axios.patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/dashboard`,
        dashboardReportPayload,
        { headers: headersRequest }
      );

      if (dashboardResponse?.status === 200) {
        toastRef.current?.showSuccess(constants.REPORT_UPDATED_SUCCESSFULLY);
        setTimeout(() => {
          navigateBack();
        }, 2000);
      } else {
        toastRef.current?.showError(dashboardResponse?.status);
        throw new Error(constants.REPORT_UPDATION_FAILED);
      }
    } catch (error) {
      // Handle errors with unique codes
      if (error.response) {
        toastRef.current?.showError("error");
      }
      dispatch(editDashboardReportFailure(error.message || "Error occurred"));
    }
  };

export const activateReport = (statusType, dsId, refreshReports) => {
  const newStatus = !statusType;
  const UserPayload = {
    dashboard_id: dsId,
    is_active: newStatus,
  };
  return (dispatch) => {
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_API_URL}/dashboard`,
        UserPayload,
        { headers: headersRequest }
      )
      .then((response) => {
        if (response?.status === 200) {
          refreshReports();
        } else {
          throw new Error("Request failed");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const setActiveDashNavLink = (link) => ({
  type: SET_ACTIVE_DASH_NAVLINK,
  payload: link,
});

//FACILITY ADMIN DASHBOARD REPORT
export const setDsReportData = (data) => ({
  type: SET_DS_REPORT_DATA,
  payload: data,
});

// Action to clear object data
export const clearDsReportData = () => ({
  type: CLEAR_DS_REPORT_DATA,
});
