import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import axios from "axios";
import { headersRequest } from "../helpers/commonUtills";

const TableauViewer = ({ link }) => {
  const [token, setToken] = useState(null);
  const bodyPayload = {
    sub: "integration.tableau@hayeslocums.com",
    scp: [
      "tableau:views:embed",
      "tableau:datasources:*",
      "tableau:metrics:*",
      "tableau:workbooks:*",
      "tableau:groups:*",
      "tableau:projects:*",
      "tableau:users:*",
      "tableau:tasks:*",
      "tableau:content:*",
      "tableau:views:*",
    ],
  };

  const fetchToken = async () => {
    try {
      const url = `${process.env.REACT_APP_AUTH_TABLEAU_URL}/tableauJwtToken`;
      const response = await axios.post(url, bodyPayload, {
        headers: headersRequest,
      });
      
      setToken(response?.data?.token);
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  useEffect(() => {
    fetchToken();

    const interval = setInterval(() => {
      fetchToken();
    }, 7 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <script
          type="module"
          src="https://us-east-1.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js"
        ></script>
      </Helmet>

      {/* Tableau Visualization */}
      <tableau-viz
        id="tableau-viz"
        src={link}
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
        token={token}
        toolbar="left"
      ></tableau-viz>
    </div>
  );
};
// PropTypes validation
TableauViewer.propTypes = {
  link: PropTypes.string.isRequired,
};
export default TableauViewer;
