import React from "react";
import InvoiceNavbar from "./InvoiceNavbar";
import { Outlet } from "react-router-dom";

const SpInvoiceProfile = () => {
  return (
    <div className="d-flex flex-column flex-grow-1 overflow-auto p-3 h-100">
      <InvoiceNavbar />
      <Outlet />
    </div>
  );
};

export default SpInvoiceProfile;
