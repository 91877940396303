import React, { useState, useEffect, useRef } from "react";
import { FilterMatchMode } from "primereact/api";
import "primereact/resources/primereact.css";
import "../../../assets/css/primeReactTheme.scss";
import "../../../assets/css/TableauContainer.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import AddButton from "../../../components/Commons/AddButton";
import "../../../assets/css/FacilityPageTable.scss";
import { ReactComponent as Gotoo } from "../../../assets/images/icons/Gotoo.svg";
import { ReactComponent as Edit } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as AddUser } from "../../../assets/images/icons/AddUser.svg";
import { ReactComponent as ViewWorkSite } from "../../../assets/images/icons/ViewWorkSite.svg";
import { HiOutlinePencil } from "react-icons/hi2";
import AddFaciltyPopup from "./AddFaciltyPopup";
import FacilityInfoPopup from "./FacilityInfoPopup";
import CustomTooltip from "../../../components/Commons/CustomTooltip";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getFacilityDBList,
  activateUser,
  fetchFacilityUserDBById,
} from "../../../redux/actions/facilityActions";
import EditFacilityPopup from "./EditFacilityPopup";
import { indexTemplate } from "../../../utils";
import constants from "../../../helpers/en";
import Worksite from "../../SuperAdmin/Facility/WorkSitesList";
import { USER_TYPES } from "../../../helpers/enums";
import AddFacilityUser from "./AddFacilityUser";
import { fetchReportsName } from "../../../redux/actions/facilityAdminAction";
import CustomToast from "../../../components/Commons/CustomToast";
import TableauViewer from "../../../components/TableauViewer";
import EditFacilityUser from "./EditFacilityUser";
import ConfirmationDialog from "../../../components/Commons/ConfirmationDialog ";
import FacilityDropdown from "../../../components/Commons/FacilityDropdown";
import ViewFacilityUser from "./ViewFacilityUser";

const FacilityPageTable = () => {
  const toastRef = useRef(null);
  const [openAddFacilityPopup, setOpenAddFacilityPopup] = useState(false);
  const [openFacilityInfoPopup, setOpenFacilityInfoPopup] = useState(false);
  const [openAddFacilityUserPopup, setOpenAddFacilityUserPopup] =
    useState(false);
  const [openEditFacilityPopup, setOpenEditFacilityPopup] = useState(false);
  const [openEditFacilityUserPopup, setOpenEditFacilityUserPopup] =
    useState(false);
  const [openViewFacilityUserPopup, setOpenViewFacilityUserPopup] =
    useState(false);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedFacilityAccountNo, setSelectedFacilityAccountNo] =
    useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolePermission = useSelector(
    (state) => state.rolePermission.permissions
  );
  const { data: facilityUserData, loading: facilityUserLoading } = useSelector(
    (state) => state.facilityUserDB
  );

  const { data, loading } = useSelector((state) => state.facilityList);
  const { reportData } = useSelector((state) => state.dashboardReportList);
  const selectedAdminFacility = useSelector(
    (state) => state.adminFacilityData.adminFacility
  ); // for facility admin

  const [link, setLink] = useState("");
  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_USER) return;
    setLink(reportData[0]?.iframe_url);
  }, [reportData]);

  const selectedClientId = rolePermission?.userInfo?.facility_account_no;
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [statusType, setStatusType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [id, setId] = useState(rolePermission?.userInfo?.facility_account_no);

  const onPage = (event) => {
    setFirst(event.first);
  };

  useEffect(() => {
    setSortField(null);
    setSortOrder(null);
  }, [data]);

  useEffect(() => {
    dispatch(getFacilityDBList());
  }, [dispatch]);
  // Updated filter fields to exactly match data structure of facilitiData

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      account_name_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
      facility_account_no_fd: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      full_name_ud: { value: null, matchMode: FilterMatchMode.CONTAINS },
      account_status_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
      create_date_fd: { value: null, matchMode: FilterMatchMode.CONTAINS },
    }));
  }, [data]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    account_name_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    facility_account_no_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    full_name_ud: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    account_status_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    create_date_fd: {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
  });

  const openForm = () => {
    setOpenAddFacilityPopup(true);
  };
  const closeForm = () => {
    setOpenAddFacilityPopup(false);
  };
  const openFacilityInfo = () => {
    setOpenFacilityInfoPopup(true);
  };
  const closeFacilityInfo = () => {
    setOpenFacilityInfoPopup(false);
  };
  const openAddFacilityUser = () => {
    setOpenAddFacilityUserPopup(true);
  };

  const closeAddFacilityUser = () => {
    setOpenAddFacilityUserPopup(false);
  };
  const openEditFacility = () => {
    setOpenEditFacilityPopup(true);
  };
  const closeEditFacility = () => {
    setOpenEditFacilityPopup(false);
  };
  const openEditFacilityUser = () => {
    setOpenEditFacilityUserPopup(true);
  };
  const closeEditFacilityUser = () => {
    setOpenEditFacilityUserPopup(false);
  };
  const openViewFacilityUser = () => {
    setOpenViewFacilityUserPopup(true);
  };
  const closeViewFacilityUser = () => {
    setOpenViewFacilityUserPopup(false);
  };

  const showFacilityInfoPopup = (hospital) => {
    setSelectedHospital(hospital);
    openFacilityInfo();
  };

  const showAddFacilityUserPopup = (hospital) => {
    setSelectedHospital(hospital);
    openAddFacilityUser();
  };
  const showEditFacilityPopup = (hospital) => {
    setSelectedHospital(hospital);
    openEditFacility();
  };

  const showEditFacilityUser = (user) => {
    setSelectedUser(user);
    openEditFacilityUser();
  };

  const showViewFacilityUser = (user) => {
    setSelectedUser(user);
    openViewFacilityUser();
  };

  const navigateToWorksite = (rowData) => {
    const accountNo = rowData?.facility_account_no_fd;
    const parentName = rowData.account_name_fd;
    setSelectedFacilityAccountNo(accountNo, parentName);
    navigate("worksite-list", { state: { accountNo, parentName } });
  };

  const fetchUsers = (fcId) => {
    dispatch(fetchFacilityUserDBById(fcId));
  };

  useEffect(() => {
    setId(selectedClientId);
  }, [rolePermission]);

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_ADMIN) return;
    fetchUsers(id);
  }, [id]);

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_ADMIN) return;
    const id2 = selectedAdminFacility?.accountNumber;
    if (id2 !== undefined && id2 !== null) {
      setId(id2);
    } else {
      setId(selectedClientId);
    }
  }, [selectedAdminFacility]);

  useEffect(() => {
    if (rolePermission.role !== USER_TYPES.FACILITY_USER) return;
    const selectedAdminFacility = null;
    const pageType = "landing_page";
    const userType = "Facility User";
    dispatch(
      fetchReportsName(
        pageType,
        selectedClientId,
        selectedAdminFacility,
        toastRef,
        userType
      )
    );
  }, [dispatch, selectedClientId, rolePermission]);
  const [showDialog, setShowDialog] = useState(false);
  //Start Active And Inactive
  const showConfirmDialog = (status, userId) => {
    setStatusType(status);
    setUserId(userId);
    setShowDialog(true);
  };
  const handleConfirm = (statusType, userId) => {
    dispatch(activateUser(statusType, userId, id));
    setShowDialog(false); // Close the dialog after confirmation
  };
  const handleCancel = () => {
    setShowDialog(false); // Close the dialog after rejection
  };
  // Define the dynamic class for each row's status
  const getStatusClass = (status) => {
    return status ? "active-status" : "inactive-status"; // Return class based on status
  };

  //end
  const AddBodyTemplate = (rowData) => (
    <div className="action-buttons-container">
      <CustomTooltip content="View Work Site">
        <ViewWorkSite
          className="action-icons"
          onClick={() => navigateToWorksite(rowData)}
        />
      </CustomTooltip>
      <CustomTooltip content="Add User">
        <AddUser
          className="action-icons"
          onClick={() => showAddFacilityUserPopup(rowData)}
        />
      </CustomTooltip>
    </div>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="action-buttons-container">
        <CustomTooltip content={"Edit"}>
          <Edit
            className="action-icons"
            onClick={() => showEditFacilityPopup(rowData)}
          />
        </CustomTooltip>

        <CustomTooltip content={"More Info"}>
          <Gotoo
            className="action-icons"
            onClick={() => showFacilityInfoPopup(rowData)}
          />
        </CustomTooltip>
      </div>
    );
  };

  const actionFacilityAdminBodyTemplate = (rowData) => {
    return (
      <div className="action-buttons-container">
        <CustomTooltip content={"Edit"}>
          <Edit
            className="action-icons"
            onClick={() => showEditFacilityUser(rowData)}
          />
        </CustomTooltip>
        <CustomTooltip content={"More Info"}>
          <Gotoo
            className="action-icons"
            onClick={() => showViewFacilityUser(rowData)}
          />
        </CustomTooltip>
      </div>
    );
  };

  const onGlobalFilterChange = (event) => {
    try {
      const value = event.target.value;
      let _filters = { ...filters };
      _filters["global"] = {
        value: value,
        matchMode: FilterMatchMode.CONTAINS,
      };
      setFilters(_filters);
    } catch (error) {
      console.error("Error in onGlobalFilterChange:", error);
    }
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";
    return (
      <IconField iconPosition="left">
        <InputIcon className="pi pi-search" />
        <InputText
          type="search"
          value={value || ""}
          onChange={(event) => onGlobalFilterChange(event)}
          placeholder="Search"
        />
      </IconField>
    );
  };
  const header = renderHeader();

  const capitalizeAndJoin = (rowData) => {
    return rowData.personaData
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(", ");
  };

  const renderLocationValue = (rowData) => {
    return rowData.account_name;
  };

  //FACILITY ADMIN
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  return (
    <>
      {rolePermission.role === USER_TYPES.SUPER_ADMIN && (
        <div className="px-4 py-3 flex-grow-1 overflow-auto dashboard-table ">
          <ConfirmationDialog
            showDialog={showDialog}
            onConfirm={(status) => handleConfirm(statusType, userId)} // Pass status and id dynamically
            onCancel={handleCancel}
            status={statusType}
            name={"Facility"}
          />
          <div className="d-flex justify-content-between facility-head-div">
            <h2 className="page-heading">{constants.FACILITY_DETAILS}</h2>
            <div>
              <AddButton
                name={constants.ADD_FACILITY_BUTTON}
                onClick={openForm}
              />
            </div>
          </div>
          <DataTable
            className="table-responsive"
            value={loading ? [] : data}
            loading={loading}
            stripedRows
            header={header}
            filters={filters}
            filterDisplay="row"
            paginator
            rows={20}
            first={first}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder);
            }}
            onPage={onPage}
            onFilter={(e) => setFilters(e.filters)}
            dataKey="facililty_id_fd"
            selectionMode="single"
            stateStorage="local"
            stateKey="dt-state-demo-local"
            emptyMessage={
              <div className="d-flex justify-content-center ">
                No data available.
              </div>
            }
          >
            <Column
              className="f_no_column"
              body={indexTemplate}
              header="F.No."
              headerClassName="f_no_column_header"
            ></Column>
            <Column
              className="account_name_column"
              header="Account Name"
              field="account_name_fd"
              body={(rowData) => (
                <span className="ellipsis-text">{rowData.account_name_fd}</span>
              )}
              sortable
              filter
              filterField="account_name_fd"
              filterPlaceholder="Search"
            ></Column>
            <Column
              className="account_number_column"
              header="Account Number"
              field="facility_account_no_fd"
              body={(rowData) => (
                <span className="ellipsis-text-accuntNo">
                  {rowData.facility_account_no_fd}
                </span>
              )}
              sortable
              filter
              filterField="facility_account_no_fd"
              filterPlaceholder="Search"
            ></Column>
            <Column
              className="facility_admin_column"
              header="Facility Admin"
              field="full_name_ud"
              body={(rowData) => (
                <span className="ellipsis-text-admin">
                  {rowData.full_name_ud}
                </span>
              )}
              sortable
              filter
              filterField="full_name_ud"
              filterPlaceholder="Search"
            ></Column>
            <Column
              className="status_column"
              field="account_status_fd"
              header="Account Status"
              body={(rowData) => (
                <span className="ellipsis-text-status">
                  {rowData.account_status_fd}
                </span>
              )}
              sortable
              filter
              style={{ width: "60rem" }}
              filterField="account_status_fd"
              filterPlaceholder="Search"
            ></Column>
            <Column
              className="create_date_column"
              field="create_date_fd"
              header="Create Date"
              body={(rowData) => (
                <span className="ellipsis-text-date">
                  {rowData.create_date_fd}
                </span>
              )}
              sortable
              filter
              filterField="create_date_fd"
              filterPlaceholder="Search"
            ></Column>
            <Column
              header="Status"
              headerClassName="center-header"
              bodyStyle={{ textAlign: "center" }}
              field="account_status_fd"
              bodyClassName="center-text-column"
              style={{ width: "50rem" }}
              body={(rowData) => (
                <button
                  className={`ellipsis-text-status ${getStatusClass(
                    rowData.is_active_ud
                  )}`}
                  tabIndex={0}
                  onClick={() =>
                    showConfirmDialog(rowData.is_active_ud, rowData.user_id_ud)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault(); // Prevent scrolling for the space key
                      showConfirmDialog(
                        rowData.is_active_ud,
                        rowData.user_id_ud
                      );
                    }
                  }}
                >
                  {rowData.is_active_ud ? "Active" : "Inactive"}
                  <i className={` ${getStatusClass(rowData.is_active_ud)}`}>
                    <HiOutlinePencil />
                  </i>
                </button>
              )}
            />
            <Column
              className="worksite_column"
              header="Work Site"
              headerClassName="center-header"
              style={{ width: "50rem" }}
              body={AddBodyTemplate}
            ></Column>
            <Column
              className="action_column"
              header="Action"
              style={{ width: "50rem" }}
              body={actionBodyTemplate}
            ></Column>
          </DataTable>

          {openAddFacilityPopup && <AddFaciltyPopup closeForm={closeForm} />}
          {openFacilityInfoPopup && (
            <FacilityInfoPopup
              hospital={selectedHospital}
              closeFacilityInfo={closeFacilityInfo}
            />
          )}
          {openAddFacilityUserPopup && (
            <AddFacilityUser
              hospital={selectedHospital}
              closeAddFacilityUser={closeAddFacilityUser}
            />
          )}
          {openEditFacilityPopup && (
            <EditFacilityPopup
              hospital={selectedHospital}
              closeEditFacility={closeEditFacility}
            />
          )}
          {selectedFacilityAccountNo && (
            <Worksite facilityAccountNum={selectedFacilityAccountNo} />
          )}
        </div>
      )}
      {rolePermission.role === USER_TYPES.FACILITY_ADMIN && (
        <div className="px-4 py-3 flex-grow-1 overflow-auto facility-admin-table ">
          <ConfirmationDialog
            showDialog={showDialog}
            onConfirm={(status) => handleConfirm(statusType, userId)}
            onCancel={handleCancel}
            status={statusType}
            name={"User"}
          />
          <div className="d-flex justify-content-between align-items-center facility-head-div">
            <div className="d-flex align-items-center w-50 col-md-3">
              <h2 className="page-heading fa-header">
                {constants.FACILITY_USERS}
              </h2>
              <FacilityDropdown selectedClientId={selectedClientId} />
            </div>
            <div className="w-50 d-flex justify-content-end">
              <div className="d-flex align-items-center fa-gloabal-search me-2">
                <IconField iconPosition="left" style={{ width: "100%" }}>
                  <InputIcon className="pi pi-search" />
                  <InputText
                    className="search-input-box"
                    type="search"
                    value={globalFilterValue}
                    onChange={(e) => setGlobalFilterValue(e.target.value)}
                    placeholder="Search"
                  />
                </IconField>
              </div>
              <AddButton
                name={constants.ADD_FACILITY_USERS}
                onClick={openAddFacilityUser}
              />
            </div>
          </div>
          <DataTable
            className="table-responsive "
            value={Array.isArray(facilityUserData) ? facilityUserData : []}
            loading={facilityUserLoading}
            globalFilterFields={["full_name"]}
            globalFilter={globalFilterValue}
            emptyMessage={
              <div className="d-flex justify-content-center ">
                No user found.
              </div>
            }
          >
            <Column
              body={indexTemplate}
              header="Sr.No."
              headerClassName="center-header"
              bodyStyle={{ textAlign: "center" }}
            />
            <Column field="full_name" header="Name" sortable />

            <Column body={capitalizeAndJoin} header="User Type" />

            <Column body={renderLocationValue} header="Location" />

            <Column
              field="is_active"
              header="Status"
              headerClassName="center-header"
              sortable
              bodyStyle={{ textAlign: "center" }}
              style={{ width: "8rem" }}
              body={(rowData) => (
                <span
                  className={`ellipsis-text-status ${getStatusClass(
                    rowData.is_active
                  )}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    showConfirmDialog(rowData.is_active, rowData.user_id);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault(); // Prevent scrolling for the space key
                      showConfirmDialog(rowData.is_active, rowData.user_id);
                    }
                  }}
                >
                  {rowData.is_active ? "Active" : "Inactive"}
                  <i className={` ${getStatusClass(rowData.is_active)}`}>
                    <HiOutlinePencil />
                  </i>
                </span>
              )}
            />
            <Column
              headerClassName="center-header"
              bodyStyle={{ textAlign: "center" }}
              body={actionFacilityAdminBodyTemplate}
              header="Action"
            />
          </DataTable>
          {openAddFacilityUserPopup && (
            <AddFacilityUser
              hospital={selectedHospital}
              closeAddFacilityUser={closeAddFacilityUser}
            />
          )}
          {openEditFacilityUserPopup && (
            <EditFacilityUser
              user={selectedUser}
              closeEditFacilityUser={closeEditFacilityUser}
            />
          )}
          {openViewFacilityUserPopup && (
            <ViewFacilityUser
              user={selectedUser}
              closeViewFacilityUser={closeViewFacilityUser}
            />
          )}
        </div>
      )}
      {rolePermission.role === USER_TYPES.FACILITY_USER && (
        <div className="d-flex  flex-grow-1 overflow-auto h-100 ">
          <CustomToast ref={toastRef} position="top-right" />
          <div className="tableau-container">
            <div className="tableau-div">
              {link ? (
                <TableauViewer link={link} />
              ) : (
                <div className="ds-empty-message">
                  <h1>Dashboard not available</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FacilityPageTable;
